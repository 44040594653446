<template>
  <VueCtkDateTimePicker
    v-model="model"
    :disabled="disabled"
    :format="format"
    :max-date="maxDate"
    :min-date="minDate"
    formatted="YYYY-MM-DD HH:mm"
    no-button-now
    no-clear-button
    no-header
    no-keyboard
    no-label
    placeholder="วันที่-เวลา"
  ></VueCtkDateTimePicker>
</template>

<script>

import dayjs from 'dayjs';

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    minDate: {
      type: String,
      default: '-',
    },
    maxDate: {
      type: String,
      default: '-',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    }
  },
  data() {
    return {
      model: null,
    }
  },
  watch: {
    model(val) {
      const propDate = dayjs(this.value)
      const modelDate = dayjs(val)
      const isChanged = !propDate.isSame(val)
      const validateMaxDate = (!this.maxDate || this.maxDate === '-' || modelDate.isBefore(this.maxDate) || modelDate.isSame(this.maxDate))
      const validateMinDate = (!this.minDate || this.minDate === '-' || modelDate.isAfter(this.minDate) || modelDate.isSame(this.minDate))
      if (isChanged && validateMaxDate && validateMinDate) {
        this.$emit('input', val)
      }
    },
    value(val) {
      this.model = val
    }
  },
  created() {
    if (this.value) {
      this.model = this.value
    }
  },
}
</script>