import Vue from 'vue'
import PageHeader from '../components/layouts/page-header.vue'
import DateTimeRange from '../components/input/date-time-range-picker.vue'
import DateTimePicker from '../components/input/date-time-picker.vue'
import MonthPicker from '../components/input/month-picker.vue'
import DayPicker from '../components/input/day-picker.vue'
import PaginationInput from '../components/commons/pagination.vue'
import Multiselect from 'vue-multiselect'
import LimitSelect from '@components/commons/limit-select.vue'
import BankIcon from '@components/commons/bank-icon.vue'

Vue.component('PageHeader', PageHeader)
Vue.component('DateTimeRange', DateTimeRange)
Vue.component('DateTimePicker', DateTimePicker)
Vue.component('MonthPicker', MonthPicker)
Vue.component('DayPicker', DayPicker)
Vue.component('PaginationInput', PaginationInput)
Vue.component('Multiselect', Multiselect)
Vue.component('LimitSelect', LimitSelect)
Vue.component('BankIcon', BankIcon)
