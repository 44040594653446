// auth related routes
const externalRoutes = [
  {
    path: '/',
    redirect: '/dashboard',
    meta: {},
  },
  {
    path: '/login',
    name: 'LOGIN',
    component: () => lazyLoadView(import('../views/auth/login')),
    meta: {},
  },
  {
    path: '/verify-login',
    name: 'VERIFY_LOGIN',
    component: () => lazyLoadView(import('../views/auth/verify-login')),
    meta: {},
  },
  {
    path: '/forget-password',
    name: 'FORGOT_PASSWORD',
    component: () => lazyLoadView(import('../views/auth/forget-password')),
    meta: {},
  },
]

// error pages
const errorPagesRoutes = [
  {
    path: '/404',
    name: '404',
    component: require('../views/errors/404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/500',
    name: '500',
    component: require('../views/errors/500').default,
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// internal pages
const internalRoutes = [
  {
    path: '/account',
    name: 'VIEW_PROFILE',
    component: () => lazyLoadView(import('../views/main/account')),
    meta: { authRequired: true },
  },
  {
    path: '/dashboard',
    name: 'VIEW_DASHBOARD',
    component: () => lazyLoadView(import('../views/main/dashboard')),
    meta: { authRequired: true },
  },
  {
    path: '/credits',
    name: 'VIEW_CREDITS',
    meta: { authRequired: true },
    redirect: '/credits/all',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      // {
      //   path: 'master',
      //   name: 'VIEW_CREDIT_MASTER',
      //   meta: { authRequired: true },
      //   component: () =>
      //     lazyLoadView(import('../views/main/credits/master-transactions')),
      // },
      {
        path: 'agent',
        name: 'VIEW_CREDIT_MASTER',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/credits/agent-transactions')),
      },
      {
        path: 'master-agent',
        name: 'VIEW_CREDIT_AGENT',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(
            import('../views/main/credits/master-agent-transaction')
          ),
      },
      {
        path: 'all',
        name: 'VIEW_CREDIT_ALL',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/credits/all-transactions')),
      },
      {
        path: 'agent-player',
        name: 'VIEW_AGENT_PLAYER_TRANSACTION',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(
            import('../views/main/credits/agent-player-transaction')
          ),
      },
      {
        path: 'agent-player/direct',
        name: 'VIEW_PLAYER_DIRECT_TRANSACTION',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(
            import('../views/main/credits/agent-player-transaction')
          ),
      },
      {
        path: 'game-bots',
        name: 'VIEW_CREDIT_BOT',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/credits/bot-transactions')),
      },
      {
        path: 'user/:id',
        name: 'VIEW_CREDIT_USER',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(
            import('../views/main/credits/player-credit-transaction')
          ),
      },
    ],
  },
  {
    path: '/summary',
    name: 'VIEW_SUMMARY',
    component: () => lazyLoadView(import('../views/main/summary/index')),
    meta: { authRequired: true },
  },
  {
    path: '/games/history',
    name: 'VIEW_GAME_HISTORY',
    component: () => lazyLoadView(import('../views/main/games/history')),
    meta: { authRequired: true },
  },
  {
    path: '/games',
    name: 'VIEW_GAME',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'playing',
        name: 'VIEW_GAME_PLAYING',
        component: () =>
          lazyLoadView(import('../views/main/games/playing.vue')),
        meta: { authRequired: true },
      },
      {
        path: 'settings',
        name: 'VIEW_GAME_SETTINGS',
        component: () =>
          lazyLoadView(import('../views/main/games/settings.vue')),
        meta: { authRequired: true },
      },
      {
        path: 'settings/:id',
        name: 'VIEW_GAME_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/games/settings', name: 'VIEW_GAME_SETTINGS' },
            { href: '/games/settings/:id', name: 'VIEW_GAME_DETAIL' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/games/detail')),
      },
      {
        path: 'create',
        name: 'CREATE_GAME',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/games/settings', name: 'VIEW_GAME_SETTINGS' },
            { href: '/games/create', name: 'CREATE_GAME' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/games/detail')),
      },
      {
        path: 'provider-games/:id',
        name: 'VIEW_GAME_PROVIDER_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/games/settings', name: 'VIEW_GAME_SETTINGS' },
            {
              href: '/games/provider-games/:id',
              name: 'VIEW_GAME_PROVIDER_DETAIL',
            },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/games/provider-game-detail')),
      },
    ],
  },
  {
    path: '/agents',
    name: 'AGENTS',
    meta: { authRequired: true },
    redirect: '/agents/list',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_AGENTS',
        meta: { authRequired: true },
        component: () => lazyLoadView(import('../views/main/agent/list.vue')),
      },
      {
        path: 'create',
        name: 'CREATE_AGENT',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/agents/list', name: 'VIEW_AGENTS' },
            { href: '/agents/create', name: 'CREATE_AGENT' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/agent/detail.vue')),
      },
      {
        path: 'detail/:id',
        name: 'VIEW_AGENT_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/agents/list', name: 'VIEW_AGENTS' },
            { href: '/agents/detail/:id', name: 'VIEW_AGENT_DETAIL' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/agent/detail.vue')),
      },
    ],
  },
  {
    path: '/commissions',
    name: 'VIEW_AGENT_COMMISSION',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/commissions/index.vue')),
  },
  {
    path: '/players',
    name: 'PLAYERS',
    meta: { authRequired: true },
    redirect: '/players/list',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'free-credit',
        name: 'VIEW_FREE_CREDIT_LIST',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/players/free-credit.vue')),
      },
      {
        path: 'list',
        name: 'VIEW_PLAYERS',
        meta: { authRequired: true },
        component: () => lazyLoadView(import('../views/main/players/list.vue')),
      },
      {
        path: 'detail/:id',
        redirect: 'detail/:id/info',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/players/list', name: 'VIEW_PLAYERS' },
            { href: '/players/detail/:id', name: 'VIEW_PLAYER_DETAIL' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/players/index.vue')),
        children: [
          {
            path: 'info',
            name: 'VIEW_PLAYER_DETAIL',
            meta: {
              authRequired: true,
              breadCrumbs: [
                { href: '/players/list', name: 'VIEW_PLAYERS' },
                { href: '/players/detail/:id', name: 'VIEW_PLAYER_DETAIL' },
              ],
            },
            component: () =>
              lazyLoadView(import('../views/main/players/detail.vue')),
          },
          {
            path: 'bet-limit',
            name: 'VIEW_PLAYER_BET_DETAIL',
            meta: {
              authRequired: true,
              breadCrumbs: [
                { href: '/players/list', name: 'VIEW_PLAYERS' },
                {
                  href: '/players/detail/:id/bet-limit',
                  name: 'VIEW_PLAYER_DETAIL',
                },
              ],
            },
            component: () =>
              lazyLoadView(import('../views/main/players/bet-limit.vue')),
          },
        ],
      },
      {
        path: 'create',
        name: 'CREATE_PLAYER',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/players/list', name: 'VIEW_PLAYERS' },
            { href: '/players/create', name: 'CREATE_PLAYER' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/players/create')),
      },
    ],
  },
  {
    path: '/affiliate',
    name: 'AFFILIATE',
    redirect: '/affiliate/list',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_AFFILIATE',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/affiliate/index.vue')),
      },
    ],
  },
  {
    path: '/deposit',
    name: 'VIEW_TRANSACTION_DEPOSIT',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/transaction/deposit.vue')),
  },
  {
    path: '/withdraw',
    name: 'VIEW_TRANSACTION_WITHDRAW',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/transaction/withdraw.vue')),
  },
  {
    path: '/transactions',
    name: 'VIEW_PLAYER_TRANSACTION',
    redirect: '/transactions/requests',
    meta: { authRequired: true },
    component: () => import('../views/main/transaction/index'),
    children: [
      {
        path: 'requests',
        name: 'VIEW_TRANSACTION_REQUEST',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/transaction/requests.vue')),
      },
      {
        path: 'history',
        name: 'VIEW_TRANSACTION_HISTORY',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/transaction/history.vue')),
      },
      {
        path: 'unmatch',
        name: 'VIEW_TRANSACTION_UNKNOWN_LOGS',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/transaction/unmatch-logs.vue')),
      },
      {
        path: 'player/:id',
        name: 'VIEW_PLAYER_TRANSACTION_HISTORY',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/players/list', name: 'VIEW_PLAYERS' },
            {
              href: '/transactions/player/:id',
              name: 'VIEW_PLAYER_TRANSACTION_HISTORY',
            },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/transaction/player-transactions')),
      },
    ],
  },
  {
    path: '/assistants',
    name: 'ASSISTANTS',
    redirect: '/assistants/list',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_ASSISTANTS',
        meta: { authRequired: true },
        component: () => lazyLoadView(import('../views/main/assistants/list')),
      },
      {
        path: 'detail/:id',
        name: 'VIEW_ASSISTANT_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/assistants/list', name: 'VIEW_ASSISTANTS' },
            { href: '/assistants/detail/:id', name: 'VIEW_ASSISTANT_DETAIL' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/assistants/detail')),
      },
      {
        path: 'create',
        name: 'CREATE_ASSISTANT',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/assistants/list', name: 'VIEW_ASSISTANTS' },
            { href: '/assistants/create', name: 'CREATE_ASSISTANT' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/assistants/detail')),
      },
    ],
  },
  {
    path: '/bank-accounts',
    name: 'BANK_ACCOUNTS',
    redirect: '/bank-accounts/list',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_BANK_ACCOUNTS',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/bank-account/list')),
      },
      {
        path: 'detail/:id',
        name: 'VIEW_BANK_ACCOUNT_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/bank-accounts/list', name: 'VIEW_BANK_ACCOUNTS' },
            {
              href: '/bank-accounts/detail/:id',
              name: 'VIEW_BANK_ACCOUNT_DETAIL',
            },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/bank-account/detail')),
      },
      {
        path: 'create',
        name: 'CREATE_BANK_ACCOUNT',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/bank-accounts/list', name: 'VIEW_BANK_ACCOUNTS' },
            { href: '/bank-accounts/create', name: 'CREATE_BANK_ACCOUNT' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/bank-account/detail')),
      },
    ],
  },
  {
    path: '/bank-truewallets',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/bank-account/list-truewallet')),
  },
  {
    path: '/bank-mobile-app/list',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/bank-account/list-mobile-app')),
  },
  {
    path: '/bank-mobile-app/:id',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/bank-account/detail-mobile-app')),
  },
  {
    path: '/bank-truewallet-history',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('../views/main/bank-account/transactions-truewallet')
      ),
  },
  {
    path: '/bank-truewallet/:id',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/bank-account/detail-truewallet')),
  },
  {
    path: '/bank-account-history',
    name: 'VIEW_BANK_ACCOUNT_HISTORY',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/bank-account/transactions')),
  },
  {
    path: '/bank-raw-transactions',
    name: 'VIEW_RAW_TXN',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/bank-account/raw-txn-transactions')),
  },
  {
    path: '/member-transaction',
    meta: { authRequired: true },
    redirect: '/member-transaction/all',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        name: 'VIEW_DEPOSIT_WITHDRAW_MEMBERS_DEPOSIT',
        path: 'deposit',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/member-transactions/list')),
      },
      {
        name: 'VIEW_DEPOSIT_WITHDRAW_MEMBERS_WITHDRAW',
        path: 'withdraw',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/member-transactions/list')),
      },
      {
        name: 'VIEW_DEPOSIT_WITHDRAW_MEMBERS_ALL',
        path: 'all',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/member-transactions/list')),
      },
    ],
  },
  {
    path: '/promotions',
    name: 'PROMOTIONS',
    redirect: '/promotions/list',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_PROMOTIONS',
        meta: { authRequired: true },
        component: () => lazyLoadView(import('../views/main/promotions/list')),
      },
      {
        path: 'detail/:id',
        name: 'VIEW_PROMOTION_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/promotions/list', name: 'VIEW_PROMOTIONS' },
            { href: '/promotions/detail/:id', name: 'VIEW_PROMOTION_DETAIL' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/promotions/detail')),
      },
      {
        path: 'create',
        name: 'CREATE_PROMOTION',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/promotions/list', name: 'VIEW_PROMOTIONS' },
            { href: '/promotions/create', name: 'CREATE_PROMOTION' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/promotions/detail')),
      },
      // {
      //   path: 'free-credits',
      //   name: 'VIEW_FREE_CREDIT',
      //   meta: {
      //     authRequired: true,
      //   },
      //   component: () =>
      //     lazyLoadView(import('../views/main/promotions/free-credits.vue')),
      // },
      {
        path: 'deposit',
        name: 'VIEW_PROMOTION_DEPOSIT',
        meta: {
          authRequired: true,
        },
        component: () =>
          lazyLoadView(import('../views/main/promotions/deposit')),
      },
      {
        path: 'checkin',
        name: 'VIEW_PROMOTION_CHECKIN',
        meta: {
          authRequired: true,
        },
        component: () =>
          lazyLoadView(import('../views/main/promotions/check-in')),
      },
    ],
  },
  {
    path: '/promotion-code',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '/',
        name: 'VIEW_PROMOTION_CODE',
        meta: { authRequired: true },
        component: () => lazyLoadView(import('../views/main/promotion-code/index')),
      },
      {
        path: 'create',
        name: 'CREATE_PROMOTION_CODE',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/promotion-code/', name: 'VIEW_PROMOTION_CODE' },
            { href: '/promotion-code/create', name: 'CREATE_PROMOTION_CODE' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/promotion-code/create')),
      },
      {
        path: ':id',
        name: 'EDIT_PROMOTION_CODE',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/promotion-code', name: 'VIEW_PROMOTION_CODE' },
            { href: '/promotion-code/:id', name: 'EDIT_PROMOTION_CODE' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/promotion-code/edit')),
      },
    ]
  },
  {
    path: '/cashback',
    name: 'VIEW_CASHBACK',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('../views/main/cashback/index.vue')),
  },
  {
    path: '/rewards',
    name: 'REWARDS',
    redirect: '/rewards/list',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_REWARDS',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/rewards/index.vue')),
      },
      {
        path: 'detail/:id',
        name: 'VIEW_REWARD_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/rewards/list', name: 'VIEW_REWARDS' },
            {
              href: '/rewards/detail/:id',
              name: 'VIEW_REWARD_DETAIL',
            },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/rewards/box-detail')),
      },
      {
        path: 'create',
        name: 'CREATE_REWARD',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/rewards/list', name: 'VIEW_REWARDS' },
            { href: '/rewards/create', name: 'CREATE_REWARD' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/rewards/box-detail')),
      },
      {
        path: 'refill-promotions/create',
        name: '',
        meta: {
          authRequired: true,
          breadCrumbs: [{ href: '/rewards/list', name: 'VIEW_REWARDS' }],
        },
        component: () =>
          lazyLoadView(
            import('../views/main/rewards/refill-promotions-create')
          ),
      },
      {
        path: 'refill-promotions/detail/:id',
        name: '',
        meta: {
          authRequired: true,
          breadCrumbs: [{ href: '/rewards/list', name: 'VIEW_REWARDS' }],
        },
        component: () =>
          lazyLoadView(
            import('../views/main/rewards/refill-promotions-create')
          ),
      },
    ],
  },
  {
    path: '/coupon',
    name: 'VIEW_COUPONS',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('../views/main/rewards/coupon.vue')),
  },
  {
    path: '/bots',
    name: 'VIEW_BOT_SETTINGS',
    component: () => lazyLoadView(import('../views/main/settings/bots.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/withdraw-settings',
    name: 'VIEW_WITHDRAW_SETTINGS',
    component: () =>
      lazyLoadView(import('../views/main/settings/withdraw.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/deposit-settings',
    name: 'VIEW_DEPOSIT_SETTINGS',
    component: () => lazyLoadView(import('../views/main/settings/deposit.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/notification-settings',
    name: 'VIEW_NOTIFICATION_SETTINGS',
    component: () =>
      lazyLoadView(import('../views/main/settings/notification.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/announce',
    name: 'ANNOUNCE',
    meta: { authRequired: true },
    redirect: '/announce/list',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_ANNOUNCES',
        meta: { authRequired: true },
        component: () => lazyLoadView(import('../views/main/announce/list')),
      },
      {
        path: 'detail/:id',
        name: 'VIEW_ANNOUNCE_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/announce/list', name: 'VIEW_ANNOUNCES' },
            { href: '/announce/detail/:id', name: 'VIEW_ANNOUNCE_DETAIL' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/announce/detail')),
      },
      {
        path: 'create',
        name: 'CREATE_ANNOUNCE',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/announce/list', name: 'VIEW_ANNOUNCES' },
            { href: '/announce/create', name: 'CREATE_ANNOUNCE' },
          ],
        },
        component: () => lazyLoadView(import('../views/main/announce/detail')),
      },
    ],
  },
  {
    path: '/contacts',
    name: 'VIEW_CONTACTS',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('../views/main/settings/contacts')),
  },
  {
    path: '/user-access',
    name: 'VIEW_USER_ACCESS',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('../views/main/access/list')),
  },
  {
    path: '/roles',
    name: 'VIEW_ROLES',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('../views/main/settings/roles.vue')),
  },
  {
    path: '/brand-settings',
    name: 'brands',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('../views/main/settings/brand.vue')),
  },
  {
    path: '/permissions',
    name: 'VIEW_PERMISSIONS',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/settings/permissions.vue')),
  },
  {
    path: '/blacklists',
    name: 'VIEW_BLACKLISTS',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('../views/main/settings/blacklists')),
  },
  {
    path: '/whitelists',
    name: 'VIEW_WHITELISTS',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('../views/main/settings/whitelists')),
  },
  {
    path: '/cms',
    name: 'CMS',
    meta: { authRequired: true },
    redirect: '/cms/list',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_CMS',
        meta: { authRequired: true },
        component: () => lazyLoadView(import('../views/main/settings/cms')),
      },
      {
        path: 'detail/:id',
        name: '',
        component: () =>
          lazyLoadView(import('../views/main/settings/cms-detail')),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/cms', name: 'VIEW_CMS' },
            {
              href: '/cms/detail/:id',
              name: 'VIEW_CMS',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/tracking-link',
    name: 'TRACKING_LINK',
    meta: { authRequired: true },
    redirect: '/tracking-link/list',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_TRACKING_LINK',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/tracking-link/list.vue')),
      },
      {
        path: 'detail/:id',
        name: 'VIEW_TRACKING_LINK_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/tracking-link/list', name: 'VIEW_TRACKING_LINK' },
            {
              href: '/tracking-link/detail/:id',
              name: 'VIEW_TRACKING_LINK_DETAIL',
            },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/tracking-link/detail.vue')),
      },
      {
        path: 'create',
        name: 'CREATE_TRACKING_LINK',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/tracking-link/list', name: 'VIEW_TRACKING_LINK' },
            { href: '/tracking-link/create', name: 'CREATE_TRACKING_LINK' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/tracking-link/form.vue')),
      },
      {
        path: ':id',
        name: 'EDIT_TRACKING_LINK',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/tracking-link/list', name: 'VIEW_TRACKING_LINK' },
            { href: '/tracking-link/:id', name: 'EDIT_TRACKING_LINK' },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/tracking-link/form.vue')),
      },
    ],
  },
  {
    path: '/line-notifications',
    name: 'VIEW_LINE_NOTIFICATIONS',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/settings/line-notifications')),
  },
  {
    path: '/agent-setting',
    name: 'AGENT_SETTING',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('../views/main/settings/agent-setting')),
  },
  {
    path: '/lotto',
    name: 'MANAGE_LOTTO',
    meta: { authRequired: true },
    redirect: '/lotto/list',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'list',
        name: 'VIEW_LOTTO',
        meta: { authRequired: true },
        component: () =>
          lazyLoadView(import('../views/main/lotto/lotto-list')),
      },
      {
        path: ':id/results',
        name: 'LOTTO_RESULT',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/lotto/list', name: 'VIEW_LOTTO' },
            {
              href: ':id/results',
              name: 'LOTTO_RESULT',
            },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/lotto/lotto-result.vue')),
      },
      {
        path: ':id/round',
        name: 'LOTTO_SETTING_ROUND',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/lotto/list', name: 'VIEW_LOTTO' },
            {
              href: ':id/round',
              name: 'LOTTO_SETTING_ROUND',
            },
          ],
        },
        component: () =>
            lazyLoadView(import('../views/main/lotto/lotto-round.vue')),
      },
      {
        path: ':id/config',
        name: 'LOTTO_CONFIG',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/lotto/list', name: 'VIEW_LOTTO' },
            {
              href: ':id/config',
              name: 'LOTTO_CONFIG',
            },
          ],
        },
        component: () => lazyLoadView(import('../views/main/lotto/lotto-config')),
      },
      {
        path: 'user-bet',
        name: 'LOTTO_USER_BET_LIST',
        meta: {
          authRequired: true,
        },
        component: () =>
          lazyLoadView(import('../views/main/lotto/lotto-user-bet-list.vue')),
      },
      {
        path: 'user-bet/:id',
        name: 'LOTTO_USER_BET_DETAILS_LIST',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/lotto/user-bet', name: 'LOTTO_USER_BET_LIST' },
            {
              name: 'LOTTO_USER_BET_DETAILS_LIST',
            },
          ],
        },
        component: () =>
          lazyLoadView(
            import('../views/main/lotto/lotto-user-bet-detail')
          ),
      },
      {
        path: 'lotto-summary',
        name: 'LOTTO_GET_SUMMARY',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/lotto/list', name: 'VIEW_LOTTO' },
            {
              href: 'lotto-summary',
              name: 'LOTTO_GET_SUMMARY',
            },
          ],
        },
        component: () =>
          lazyLoadView(import('../views/main/lotto/lotto-summary')),
      },
    ],
  },
]
const allRoutes = [...externalRoutes, ...internalRoutes, ...errorPagesRoutes]

export { allRoutes }

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@components/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    // error: require('@views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
