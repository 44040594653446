import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}`
const baseUserURL = `${process.env.VUE_APP_API_URL}/user`

export default {
  async fetchGames() {
    try {
      let games = await axios.get(`${baseURL}/game`)
      return games
    } catch (e) {
      throw e
    }
  },
  async fetchGamePlaying(agentId, startDateTime, endDateTime) {
    try {
      let games = await axios.get(`${baseURL}/game/playing`, { params: {agentId, startDateTime, endDateTime}})
      return games
    } catch (e) {
      throw e
    }
  },
  async fetchGamesDefaultProviderKey() {
    try {
      let key =  await axios.get(`${baseURL}/game/default-provider-key`)
      return key
    } catch (e) {
      throw e
    }
  },
  fetchGameById(id) {
    try {
      return axios.get(`${baseURL}/game/${id}`)
    } catch (e) {
      throw e
    }
  },
  fetchGameTransactions(id) {
    try {
      return axios.get(`${baseURL}/game/${id}/transaction`)
    } catch (e) {
      throw e
    }
  },
  updateGame(id, formData) {
    try {
      return axios.put(`${baseURL}/game/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchGameScoreBySlug(slug, date, limit, page) {
    try {
      return axios.get(
        `${baseURL}/admins/games/${slug}/score?date=${date}&limit=${limit}&page=${page}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchGameScoreBySlugWithAgent(slug, date, limit, page, agentUsername) {
    try {
      return axios.get(
        `${baseURL}/admins/games/${slug}/score?date=${date}&limit=${limit}&page=${page}&agentUsername=${agentUsername}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchGameScoreBySlugWithRoomId(slug, date, limit, page, roomId) {
    try {
      return axios.get(
        `${baseURL}/admins/games/${slug}/score?date=${date}&limit=${limit}&page=${page}&roomId=${roomId}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchGameReports() {
    try {
      return axios.get(`${baseURL}/admins/games/report/mini-games`)
    } catch (e) {
      throw e
    }
  },
  createGame(data) {
    // gameName, gameDescription, gameType, maximumPlayer, miniumPlayer, maximumBet,  minimumBet, rule
    try {
      return axios.post(`${baseURL}/admins/games`, data)
    } catch (e) {
      throw e
    }
  },
  
  updateGameStatus(gameId) {
    try {
      return axios.put(`${baseURL}/admins/games/${gameId}/status`)
    } catch (e) {
      throw e
    }
  },
  fetchGameAgentCommission(date) {
    try {
      return axios.get(`${baseURL}/admins/games/report/agents?date=${date}`)
    } catch (e) {
      throw e
    }
  },
  fetchGameAgentCommissionByAgentId(agentId, date) {
    try {
      return axios.get(
        `${baseURL}/admins/games/report/agents?agentId=${agentId}&date=${date}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchBotCredits(page, limit) {
    try {
      return axios.get(
        `${baseURL}/admins/bots/credits?page=${page}&limit=${limit}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchBotCreditsToday() {
    try {
      return axios.get(`${baseURL}/admins/bots/credits/today`)
    } catch (e) {
      throw e
    }
  },
  fetchGameTypes() {
    try {
      return axios.get(`${baseUserURL}/game`)
    } catch (e) {
      throw e
    }
  },
}
