import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}`

export default {
  async fetchLottoGameByGameType(
    limit,
    page,
    search,
    agentId,
    masterId,
    gameType
  ) {
    try {
      return axios.get(`${baseURL}/lotto-game`, {
        params: {
          limit,
          page,
          search,
          agentId,
          masterId,
          gameType
        }
      })
    } catch (e) {
      throw e
    }
  },
  async toggleStatusLottoGameRounds(id) {
    try {
      return axios.patch(`${baseURL}/lotto-game/toggle/${id}`)
    } catch (e) {
      throw e
    }
  },
  async fetchLottoGameConfigById(id) {
    try {
      return axios.get(`${baseURL}/lotto-game/${id}/config`)
    } catch (e) {
      throw e
    }
  },
  async fetchLottoGameById(id) {
    try {
      return axios.get(`${baseURL}/lotto-game/${id}`)
    } catch (e) {
      throw e
    }
  },
  async updateLottoGameConfigById(id, configs) {
    try {
      return axios.put(`${baseURL}/lotto-game/${id}/config`, configs)
    } catch (e) {
      throw e
    }
  },
  async getSummaryResultByGameRoundId(id, payload) {
    try {
      return axios.post(
        `${baseURL}/lotto-game-result/${id}/calculate-result`,
        payload
      )
    } catch (e) {
      throw e
    }
  },
  async updateLottoGameDetailById(id, detail) {
    try {
      return axios.put(`${baseURL}/lotto-game/${id}`, detail)
    } catch (e) {
      throw e
    }
  },
  async fetchLastGameRoundByGameId(id) {
    try {
      return axios.get(`${baseURL}/lotto-game-round/latest-game-round/${id}`)
    } catch (e) {
      throw e
    }
  },
  async cancelAllLottoBetByGameRoundId(id) {
    try {
      return axios.post(`${baseURL}/lotto-game-result/${id}/cancel`)
    } catch (e) {
      throw e
    }
  },
  async submitLottoResult(id, payload) {
    try {
      return axios.post(
        `${baseURL}/lotto-game-result/${id}/submit-result`,
        payload
      )
    } catch (e) {
      throw e
    }
  },
  async submitOpenNewRound(id, payload) {
    try {
      return axios.post(`${baseURL}/lotto-game-round/${id}`, payload)
    } catch (e) {
      throw e
    }
  },
  async updateYeekeeConfig(lottoGameId, payload) {
    try {
      const endpoint = lottoGameId
        ? `${baseURL}/lotto-game/yeekee-config/${lottoGameId}`
        : `${baseURL}/lotto-game/yeekee-config`
      return axios.post(endpoint, payload)
    } catch (e) {
      throw e
    }
  },
  async fetchLottoUserBetByGameId(
    limit,
    page,
    search,
    agentId,
    masterId,
    userId,
    lottoGameRoundId,
    lottoBetStatus,
    gameType,
    gameId
  ) {
    try {
      return axios.get(`${baseURL}/lotto-user-bet`, {
        params: {
          limit,
          page,
          search,
          agentId,
          masterId,
          userId,
          lottoGameRoundId,
          lottoBetStatus,
          gameType,
          gameId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  async fetchLottoUserBetByUserBetId(id) {
    try {
      return axios.get(`${baseURL}/lotto-user-bet/${id}`)
    } catch (e) {
      throw e
    }
  },
  async setPayoutRateByGameId(id, payload) {
    try {
      return axios.post(`${baseURL}/lotto-game/${id}/payout-rate`, payload)
    } catch (e) {
      throw e
    }
  },
  async updateLimitNumber(id, payload) {
    try {
      return axios.post(`${baseURL}/lotto-game/${id}/limit-number`, payload)
    } catch (e) {
      throw e
    }
  },
  async getPayoutRateByLottoGameId(id) {
    try {
      return axios.get(`${baseURL}/lotto-game/${id}/payout-rate`)
    } catch (e) {
      throw e
    }
  },
  async getLimitNumbersByLottoGameId(id) {
    try {
      return axios.get(`${baseURL}/lotto-game/${id}/limit-number`)
    } catch (e) {
      throw e
    }
  },
  async setPayoutRateNumberByGameId(id, payload) {
    try {
      return axios.post(
        `${baseURL}/lotto-game/${id}/round-betting-payout-rate`,
        payload
      )
    } catch (e) {
      throw e
    }
  },
  async fetchLottoSummary(fromDate, toDate, gameType, lottoGameId) {
    try {
      return axios.get(`${baseURL}/lotto-game/summary`, {
        params: {
          fromDate,
          toDate,
          gameType,
          lottoGameId,
        },
      })
    } catch (e) {
      throw e
    }
  },
}
