<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import DefaultLayout from './views/layouts/default.vue'
import UnAuthLayout from './views/layouts/un-auth.vue'

export default {
  page: {
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return `${title || ''}`
    },
  },
  computed: {
    layout() {
      const { authRequired } = this.$route.meta
      return authRequired ? DefaultLayout : UnAuthLayout
    },
  },
}
</script>