import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/announcement`

export default {
  getAnnounces(limit, page, search, agentId, masterId) {
    try {
      return axios.get(
        baseURL,
        {
          params: {
            limit,
            page,
            search,
            agentId,
            masterId
          }
        }
      )
    } catch (e) {
      throw e
    }
  },
  fetchAnnounceById(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  createAnnounce(data) {
    try {
      return axios.post(`${baseURL}`, data)
    } catch (e) {
      throw e
    }
  },
  updateAnnounce(id, data) {
    try {
      return axios.put(`${baseURL}/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  deleteAnnounce(id) {
    try {
      return axios.delete(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
}
