import AttachmentService from '../services/attachment'

const state = {
    isUploading: false
}

const mutations = {
    SET_IS_UPLOADING(isUploading) {
        state.isUploading = isUploading
    }
}

const actions = {
    async upload({ commit, dispatch }, file) {
        try {
            commit('SET_IS_UPLOADING', true)
            const formData = new FormData()
            formData.set('file', file)
            const result = await AttachmentService.upload(formData)
            return result
        } catch (e) {
            dispatch('notifyError', e)
        } finally {
            commit('SET_IS_UPLOADING', false)
        }
    },
}

const getters = {}

export default {
    state,
    mutations,
    actions,
    getters
}