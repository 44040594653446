import Vue from 'vue'
import Vuex from 'vuex'

const globalFiles = require.context('./', false, /\.js$/)

const modules = {}

const extractModuleName = (files) => {
  files.keys().forEach((fileName) => {
    if (fileName === './index.js') {
      return
    }
    const normalizeFileName = fileName.replace('./', '').replace('.js', '')
    modules[normalizeFileName] = files(fileName).default
  })
}
extractModuleName(globalFiles)

Vue.use(Vuex)
export default new Vuex.Store({
  strict: true,
  modules,
})
