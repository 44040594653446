<template>
  <div class="form-group m-0">
    <div class="input-group input-group-merge">
      <b-form-datepicker v-model="value" :dropleft="isDropLeft" reset-button>
      </b-form-datepicker>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    selectedDate: {
      type: String,
      default: '',
    },
    initialValue: {
      type: [String, Date],
      default: dayjs().format('YYYY-MM-DD'),
    },
    isDropLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: '',
      dateConfig: {
        monthSelect: {
          plugins: [],
        },
      },
    }
  },
  watch: {
    value(val) {
      this.$emit('update', val)
    },
    initialValue() {
      // if (value) {
      //     this.value = dayjs(value).toDate()
      // }
    },
  },
  created() {
    if(this.initialValue){
      this.value = this.initialValue
    }
  },
}
</script>
