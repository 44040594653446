/* eslint-disable quotes */
export default {
  routes: {
    PROFILE: 'My account',
    VIEW_PROFILE: 'My account',
    VIEW_DASHBOARD: 'Dashboard',
    VIEW_SUMMARY: 'Summary',
    GAMES: 'Games',
    VIEW_GAME_HISTORY: 'Game history',
    VIEW_GAME_SETTINGS: 'Game settings',
    VIEW_GAME_PLAYING: 'Game playing',
    VIEW_GAME_DETAIL: 'Game detail',
    CREATE_GAME: 'Create a new Game',
    VIEW_GAME_PROVIDER_DETAIL: 'Provider game detail',
    AGENTS: 'Agents',
    VIEW_AGENTS: 'All agents',
    VIEW_AGENT_DETAIL: 'Agent detail',
    CREATE_AGENT: 'Create a new Agent',
    VIEW_AGENT_COMMISSION: 'Commissions',
    VIEW_CREDITS: 'Credit transaction',
    VIEW_CREDIT_MASTER: "Agents' credit",
    VIEW_CREDIT_AGENT: "Agents' credit",
    VIEW_CREDIT_ALL: "Players' credit",
    VIEW_CREDIT_BOT: 'Bot credit transaction',
    credits_agent: 'Credit transaction - Agent',
    credits_transfer: 'Deposit/ Withdraw credits',
    VIEW_AGENT_PLAYER_TRANSACTION: 'Direct credit transaction (Agent - Player)',
    PLAYERS: 'Players',
    VIEW_PLAYERS: 'All players',
    VIEW_PLAYER_TRANSACTION_HISTORY: 'Player transaction history',
    VIEW_PLAYER_DETAIL: 'Player detail',
    VIEW_PLAYER_BET_DETAIL: 'Bet limit setting',
    CREATE_PLAYER: 'Create a new player',
    TRANSACTIONS: 'Transactions',
    VIEW_PLAYER_TRANSACTION: 'Player transaction',
    VIEW_TRANSACTION_DEPOSIT: 'Deposit transactions',
    VIEW_TRANSACTION_WITHDRAW: 'Withdraw transactions',
    VIEW_TRANSACTION_REQUEST: 'Credit Request Transactions',
    VIEW_TRANSACTION_HISTORY: 'Transactions history',
    VIEW_TRANSACTION_UNKNOWN_LOGS: 'Transactions (Un-Match)',
    VIEW_HIDDEN_TRANSACTION_REQUEST: 'Hidden transactions',
    transactions_deposit: 'Pending deposits',
    transactions_withdraw: 'Pending withdrawals',
    VIEW_BANK_ACCOUNTS: 'Bank accounts',
    VIEW_BANK_MOBILE_APP: 'Mobile bank accounts',
    BANK_TRUEWALLET: 'Truewallet accounts',
    VIEW_DEPOSIT_WITHDRAW_MEMBERS: 'Member Deposit/Withdraw',
    VIEW_TRUEWALLET_ACCOUNT: 'Truewallet accounts',
    VIEW_BANK_TRUEWALLET_ACCOUNT_HISTORY: 'Truewallet history',
    VIEW_DEPOSIT_WITHDRAW_MEMBERS_DEPOSIT: 'Deposit',
    VIEW_DEPOSIT_WITHDRAW_MEMBERS_WITHDRAW: 'Withdraw',
    VIEW_DEPOSIT_WITHDRAW_MEMBERS_ALL: 'All Transaction',
    VIEW_BANK_ACCOUNT_DETAIL: 'Edit bank account',
    CREATE_BANK_ACCOUNT: 'Create a new Bank account',
    VIEW_BANK_ACCOUNT_HISTORY: 'Bank account history',
    SETTINGS: 'Settings',
    ACCESS: 'Access',
    VIEW_BOT_SETTINGS: 'Bot Settings',
    VIEW_WITHDRAW_SETTINGS: 'Withdraw Settings',
    VIEW_DEPOSIT_SETTINGS: 'Deposit Settings',
    VIEW_NOTIFICATION_SETTINGS: 'Notification Settings',
    assistants: 'Assistants',
    VIEW_ASSISTANTS: 'Assistant list',
    VIEW_ASSISTANT_DETAIL: 'Assistant detail',
    CREATE_ASSISTANT: 'Create a new Assistant',
    VIEW_AFFILIATE: 'Affiliate (AF)',
    VIEW_AFFILIATE_SETTINGS: 'Affiliate (AF) Settings',
    PROMOTIONS: 'Promotions',
    VIEW_PROMOTIONS: 'Promotions',
    VIEW_PROMOTION_DETAIL: 'Promotion detail',
    CREATE_PROMOTION: 'Create a new promotion',
    VIEW_PROMOTION_CODE: 'Promotion Code',
    CREATE_PROMOTION_CODE: 'Create Promotion Code',
    EDIT_PROMOTION_CODE: 'Edit Promotion Code',
    VIEW_CASHBACK: 'Cashback',
    EDIT_CASHBACK: 'Cashback detail',
    CREATE_CASHBACK: 'Create a new Cashback',
    VIEW_FREE_CREDIT: 'Free credits',
    VIEW_FREE_CREDIT_LIST: 'Player free credits',
    VIEW_PROMOTION_DEPOSIT: 'Deposit rewards',
    VIEW_PROMOTION_CHECKIN: 'Check-in rewards',
    VIEW_REWARDS: 'Rewards',
    VIEW_REWARD_DETAIL: 'Reward detail',
    CREATE_REWARD: 'New Reward',
    rewards_win_wheel: 'Win-wheel Rewards',
    rewards_card: 'Card Rewards',
    ANNOUNCEMENTS: 'Announcements',
    VIEW_ANNOUNCEMENTS: 'Announcements',
    VIEW_ANNOUNCE: 'Announcements',
    CREATE_ANNOUNCE: 'Create Announcements',
    EDIT_ANNOUNCE: 'Edit Announcement',
    VIEW_ANNOUNCE_DETAIL: 'Announcement detail',
    VIEW_USER_ACCESS: 'Access information',
    VIEW_ROLES: 'Role settings',
    BRANDS: 'Brand settings',
    VIEW_BRANDS: 'Brands',
    CREATE_BRAND: 'Create a new brand',
    VIEW_BRAND_DETAIL: 'Edit Brand',
    VIEW_PERMISSIONS: 'Permissions',
    VIEW_CONTACTS: 'Contacts',
    BANK: 'BANKING',
    VIEW_RAW_TXN: 'Raw Bank Transaction',
    VIEW_BLACKLISTS: 'Blacklist',
    VIEW_WHITELISTS: 'Whitelists',
    VIEW_CMS: 'CMS',
    VIEW_LINE_NOTIFICATIONS: 'LINE Notifications',
    SETTING_CONTACT: 'Agent Contract',
    SETTING_LINE_LOGIN: 'Agent Line Login',
    SETTING_WITHDRAW: 'Agent Withdraw',
    SETTING_CASHBACK: 'Agent Cashback',
    SETTING_AF: 'Agent Affiliate',
    MARKETING_TOOLS: 'Marketing Tools',
    TRACKING_LINK: 'Tracking Links',
    VIEW_TRACKING_LINK: 'Tracking Links',
    VIEW_TRACKING_LINK_DETAIL: 'Traแking Link Detail',
    CREATE_TRACKING_LINK: 'Create Tracking Link',
    EDIT_TRACKING_LINK: 'Edit Tracking Link',
    MANAGE_LOTTO: 'Manage Lotto',
    VIEW_LOTTO: 'Lotto Lists',
    LOTTO_TOOLS: 'Lotto System',
    LOTTO_RESULT: 'Lottery Results',
    LOTTO_SETTING_ROUND: 'Setting Round',
    LOTTO_CONFIG: 'Lotto Configs',
    LOTTO_USER_BET_LIST: 'Bet List',
    LOTTO_USER_BET_DETAILS_LIST: 'Bet Detail',
    LOTTO_GET_SUMMARY: 'Lotto Summary',
  },
  pages: {
    login: {
      forgot_password: 'Forgot your password?',
    },
    forget_password: {
      title: 'Forget password',
      subtitle: 'We will send you a OTP on your phone number (xxx xxxx xx78)',
      otp_code: 'Verification code',
      resend: 'Resend OTP',
      get_otp: 'Get OTP now',
      verify: 'Verify & Proceed',
      back_login: 'Back to Login',
    },
    dashboard: {
      overview: 'Overview',
      today: 'Today',
      total_players: 'Total members',
      total_free_credit: 'Total free credit',
      total_loss_free_credit: 'Total loss free credit',
      total_deposit: 'Total deposit',
      total_withdraw: 'Total withdraw',
      total_revenue: 'Total revenue',
      today_new_register: 'Total new members',
      today_free_credit: 'Today free credit',
      today_loss_free_credit: 'Today loss free credit',
      today_deposit: 'Today deposit',
      today_withdraw: 'Today withdraw',
      today_revenue: 'Today revenue',
      total_first_deposit: 'Total first deposit',
      total_first_daily_deposit: 'Total first daily deposit',
      total_all_deposit_bonus: 'Total all deposit bonus',
      total_schedule_deposit: 'Total schedule deposit',
      total_accumulated_deposit: 'Total accumulated deposit',
      today_first_deposit: 'Today first deposit',
      today_first_daily_deposit: 'Today first daily deposit',
      today_all_deposit_bonus: 'Today all deposit bonus',
      today_schedule_deposit: 'Today schedule deposit',
      today_accumulated_deposit: 'Today accumulated deposit',
      stats: {
        total_credits: 'Total Credits',
        total_agents: 'All Agents',
        total_players: 'All Players',
        agent_overview: 'Agent overview',
        player_overview: 'Player overview',
        new_players: 'New players',
        all_players: 'All players',
        total_top_up: 'Total Top-up',
      },
    },
    mini_games: {
      title: 'Mini games',
      reports: 'Mini game reports',
      total_games: 'All rounds',
      total_bet: 'Total bet amount',
      total_fee: 'Total fee',
    },
    provider_games: {
      title: 'Provider games',
    },
  },
  utils: {
    lang: {
      th: 'Thai',
      en: 'English',
    },
    status: {
      hidden: 'Hidden',
      maintenance: 'Maintenance',
      bet: 'Bet',
      active: 'Active',
      in_active: 'In Active',
      inactive: 'Inactive',
      active_short: 'Active',
      inactive_short: 'Inactive',
      enable: 'Enable',
      disable: 'Disable',
      blocked: 'Blocked',
      pending_credit: 'Pending',
      success_credit: 'Success',
      unsuccessful: 'Unsuccessful',
      rejected: 'Rejected',
      approve: 'Approve',
      reject: 'Reject',
      reject_return: 'Return credit',
      reject_not_return: 'Not return credit',
      verified: 'Verified',
      no_verify: 'Not verified',
      banned: 'Banned',
      deleted: 'Deleted',
      finished: 'Finished',
      waiting: 'Waiting',
      processing: 'Processing',
      canceled: 'Canceled',
      uploaded: 'Uploaded',
      allow_concurrent: 'Allow concurrent create/join room',
      not_allow_concurrent: 'Not allow concurrent create/join room',
      released: 'Released',
      not_released: 'Not release',
    },
    periods: {
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly',
    },
  },
  buttons: {
    login: 'Login',
    logout: 'Logout',
    signup: 'Sign up',
    reset_password: 'Reset password',
    save: 'Save',
    submit: 'Submit',
    resend: 'Resend',
    cancel: 'Cancel',
    confirm: 'Confirm',
    copy: 'Copy',
    copied: 'Copied!',
    add: 'Add new',
    add_master: 'Add new master',
    add_agent: 'Add new agent',
    remove: 'Remove',
    edit: 'Edit',
    back: 'Back',
    access_login: 'Access login',
    ban: 'Ban',
    un_ban: 'Un-Ban',
    operation: 'Operation',
    clear: 'Clear data',
    view: 'View',
    change_status: 'Change Status',
    remove_pin: 'Remove pin',
    detail: 'View Detail',
    set_payout_rate: 'Set payout rate',
    view_lotto_user_bet: 'lotto user bet list',
    view_summary: 'summary',
    transfer: 'Transfer'
  },
  fields: {
    all: 'All',
    allow: 'Allow',
    not_allow: 'Not Allow',
    start: 'Start',
    end: 'End',
    game: 'Game',
    baht: 'Baht',
    channel: 'Channel',
    summary: 'Summary',
    amount: 'Amount',
    current_amount: 'Current amount',
    percentage: 'Percentage',
    click_select: 'Click to select',
    conditions: 'Conditions',
    remark: 'Remark',
    upcoming: 'Upcoming',
    live_preview: 'Live preview',
    reference: 'Reference',
    category: 'Category',
    status: 'Status',
    createdAt: 'Created date',
    updatedAt: 'Last update',
    joined_date: 'Joined date',
    lastLogin: 'Last login',
    description: 'Description',
    result: 'Result',
    profile: 'Profile',
    issued_date: 'Issued date',
    date: 'Date',
    random_percentage: 'Random percentage',
    search: 'Search',
    advance_setting: 'Advance settings',
    allow_concurrent: 'Allow concurrent create/join room',
    game_type: 'Game type',
    type: 'Type',
    order: 'Order',
    title: 'Title',
    yes: 'Yes',
    no: 'No',
    image: 'Image',
    revenue: 'Revenue',
    remain_revenue: 'Remaining revenue',
    person: 'Person',
    credit: 'Credit',
    total_credit: 'Total credit',
    agent_username: 'Agent username',
    agent_prefix: 'Agent prefix',
    user_username: 'Username',
    user_phone_number: 'Phone number',
    source: 'Source',
    greater_number: 'Greater number',
    less_number: 'Less number',
    select_time: 'Select time',
    promotion_title: 'Promotion name',
    deposit_amount: 'Deposit amount',
    bonus: 'Bonus',
    turn_over: 'Turn over',
    turn_required: 'Turn required',
    direct_deposit_with_promotion: 'With Promotion',
    hold_balance: 'Hold balance',
    times: 'Times'
  },
  messages: {
    enter_details: 'Enter your details below.',
    nothing_here: 'Nothing here.',
    password_not_match: 'Password and confirm password do not match.',
    none: 'No message',
    complete_form: 'Complete the information below.',
    invalid_form: 'Missing required fields.',
    example: 'See example',

    success: 'Your request has been completed successfully.',
    error: '{code} - Something bad happened, please try again.',
    404: '404 - The request URL was not found on this server.',
    server_error: '{value} - Internal server error',
    network_error: 'Please check your network connection.',
    try_again: 'Something went wrong, please try again later...',
    confirm: 'Are you sure to continue this?',
  },
  agent: {
    title: 'Agent',
    name: 'Name',
    credit: 'Credit',
    credit_bonus: 'Credit bonus',
    referral: 'Referral',
    current_income: 'Current income',
    total_income: 'Total income',
    credit_players: 'Players credit',
    total_players: 'Total players',
    phone: 'Phone number',
    assistants: 'Total assistants',
    commission: 'Commission (%)',
    af_commission: 'AF commission (%)',
    register_link: 'Registration link',
    register_url: 'Registration URL',
    no_agent: 'No agent',
    income: 'Income',
    net_income: 'Net income',
    has_line_signin: 'Enable Line signin',
    line_client_id: 'Line Channel ID',
    line_client_secret: 'Line Channel Secret',
  },
  user: {
    prefix: 'Prefix',
    username: 'Username',
    firstname: 'First name',
    lastname: 'Last name',
    password: 'Password',
    change_password: 'Change Password',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    email: 'Email',
    registered_date: 'Registered date',
    referral: 'Referral friend',
    link_af: 'Link AF',
    dateOfBirth: 'Date of birth',
    phone: 'Phone number',
    ban_user: 'Ban user',
    cancel_ban_user: 'Cancel ban user',
    bet_report: 'Bet report',
    today_bet: 'Today/s bet',
    bet_history: 'Bet history',
    withdraw_revenue_history: 'Withdraw revenue history',
    has_line_signin: 'Has Line signin',
    note: 'Note',
    createdAt: 'Register Date',
  },
  bank_accounts: {
    allow_bank_code_list: 'Allow seen Bank',
    agent: 'agent',
    limit_all_deposit: 'Limit all deposit',
    limit_all_withdraw: 'Limit all withdraw',
    type: 'Bank account type',
    random_percentage: 'Random percentage',
    credits: 'Credits',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    balance: 'Balance',
    detail: 'Bank account detail',
    bank: 'Bank',
    name: 'Bank name',
    account_no: 'Account number',
    agent_username: 'Agent name',
    account_name: 'Account name',
    transfer: 'Transfer',
    transfer_amount: 'Transfer amount',
    select_bank: 'Select bank',
    select_methods: 'Select transfer channel',
    transfer_channel: 'Select your bank transfer',
    transferred_date: 'Transferred date',
    transferred_time: 'Transferred time',
    debit_credit_flag: 'Flag',
    transaction_code: 'Transaction code',
    withdraw_available: 'Available for withdrawal',
    withdrawal_amount: 'Withdrawal amount',
    limit: 'Transfer limit',
    automatic_status: 'Automate status',
    all_transaction: 'All transaction',
    total_deposit: 'All deposit',
    total_withdraw: 'All withdraw',
    channel: 'Channel',
    channel_detail: 'Channel detail',
    currency: 'Currency',
    pair_success: 'Can pair accounts',
    pair_failed: 'Can/t pair accounts',
    transfer_methods: {
      title: 'Transfer methods',
      bank: 'At bank',
      atm: 'ATM',
      mobile: 'Mobile banking',
      web: 'Web banking',
      counter: 'Counter service',
    },
    types: {
      deposit_withdraw: 'Deposit & Withdraw',
      deposit: 'Deposit only',
      withdraw: 'Withdraw only',
      hold: 'Hold only',
    },
    from_account: 'From account',
    to: 'To Bank'
  },
  credits: {
    credit: 'Credit',
    current: 'Current credits',
    hold_balance: 'Hold Balance',
    total: 'Total credits',
    credit_records: 'Credit record',
    credit_history: 'Credit record history',
    credit_transaction: 'Credit transactions',
    credit_direct_transaction: 'Credit manual transactions',
    transfer: 'Deposit/ Withdraw credits',
    direct_transfer: 'Direct deposit/ Withdraw credits',
    credit_amount: 'Credit amount',
    deposit: 'Deposit credit',
    withdraw: 'Withdraw credit',
    withdraw_request: 'Withdraw Request',
    deposit_amount: 'Deposit amount',
    withdraw_amount: 'Withdraw amount',
    bonus: 'Bonus',
    margin: 'Margin',
    win: 'Win',
    clear: 'Clear',
    bet: 'Bet',
    draw: 'Draw',
    cancel_bet: 'Cancel Bet',
    direct_deposit: 'Direct deposit credit',
    direct_withdraw: 'Direct withdraw credit',
    direct_withdraw_all: 'Direct withdraw all credit',
    transaction_no: 'Transaction number',
    operated_by: 'Operated by',
    receiver: 'Receiver',
    before_amount: 'Before amount',
    remain_credits: 'Remaining credit',
    previous_credits: 'Previous credit',
    transaction_date: 'Transaction date',
    type: 'Type',
    approve_withdraw: 'Pending withdraw approval',
    proof: 'Proof of transfer',
    upload_proof: 'Upload transfer slip',
    has_deposit_amount: 'Has amount?',
    issued_bank: 'Issued bank',
    receiving_bank: 'Receiving bank',
    transaction: {
      all_request: 'All request transactions',
      all_history: 'All history transactions',
      deposit_request: 'Deposit request transactions',
      deposit_history: 'Deposit history transactions',
      withdraw_request: 'Withdraw request transactions',
      withdraw_history: 'Withdraw history transactions',
      auto: 'Auto transactions',
    },
  },
  games: {
    mini_games: 'Mini-game',
    provider_games: 'Game Providers',
    lotto_games: 'Lotto',
    head_tail: 'Head tail',
    rock_paper_scissors: 'Rock paper scissors',
    hi_lo: 'Hi-lo',
    cards_battle: 'Cards battle',
    sa: 'SA',
    sexy: 'Sexy',
    dg: 'Dream Gaming',
    sp: 'Simple Play',
    government: 'Government lotto',
    yeekee: 'Yeekee lotto',
    total_income: 'Total income',
    all_games: 'All games',
    name: 'Game name',
    bet_limit: 'Bet limit',
    player_limit: 'Player limit',
    transaction_fee: 'Transaction fee',
    water_rate: 'Fee rate',
    how_to: 'Rules & How to play',
    rules: 'Rules',
    how: 'How to play',
    new_game: 'New game',
    bet_money: 'Place a bet',
    bet_amount: 'Bet amount',
    win_amount: 'Reward',
    date: 'Play date',
    random: 'Random now',
    set_result: 'Set result',
    playground: 'Playground',
    live_games: 'LIVE games',
    my_games: 'My games',
    competitor: 'Competitor',
    bettor: 'Bettor',
    rival: 'Rival',
    player: 'Player',
    winner: 'Winner',
    guess: 'Guess',
    games_no: 'No. of games',
    win_rate: 'Win rate',
    win_loss_rate: 'Win-Loss rate',
    win_loss_result: 'Win-Loss result',
    your_choice: 'Your choice',
    win: 'Win',
    loss: 'Loss',
    all_win: 'All wins',
    all_loss: 'All losses',
    room_id: 'Room ID',
    transaction_no: 'Transaction no',
    game_result: 'Result',
    result: {
      head: 'Heads',
      tail: 'Tails',
      rock: 'Rock',
      paper: 'Paper',
      scissors: 'Scissors',
      hi: 'High',
      lo: 'Low',
    },
    list: {
      HI_LO: 'High-low',
      ROCK_PAPER_SCISSORS: 'Rock–paper–scissors',
      HEAD_TAIL: 'Heads or tails',
      CARD_BATTLE: 'Battle cards',
      general: 'General',
      slot: 'Slot',
      fishing: 'Fishing',
      casino: 'Casino',
      sports: 'Sports',
      simulated_sports: 'Simulated sports',
    },
  },
  bots: {
    name: 'Bot',
    bot_credit: 'Bot credits (฿)',
    max_loss_credit: 'Maximum loss credits (฿)',
    max_win_credit: 'Maximum win credits (฿)',
    operate_time: 'Operating time',
    join_time: 'Join time',
    join_min: 'Minimum',
    join_max: 'Maximum',
    win_rate: 'Win rate (%)',
    max_room: 'Maximum rooms',
  },
  affiliate: {
    title: 'Affiliate (AF)',
    report: 'Affiliate reports',
    confirm_af: 'Verify for AF 10%',
    withdraw: 'Withdraw revenue',
    revenue: 'Revenue',
    af_link: 'Affiliate link',
    search_members: 'Search member',
    min_deposit: 'Minimum deposit',
    profit_percentage: 'Profit percentage',
    level: 'Level {val}',
    percentage: 'AF percentage',
    min_bet: 'Minimum bet',
    total_af_credit: 'Total AF credits',
    total_bet_credit: 'Total bet',
    your_bet_credit: 'Your total bet',
    member_bet_credit: 'Member total bet',
    tabs: {
      overview: 'Overview',
      members: 'Members',
      credits: 'Credits',
      total_credit: 'Total credits',
    },
    stats: {
      all_click: 'All clicks',
      total_bet: 'Total bet',
      members_link: 'Members via Link',
      current_revenue: 'Current revenue',
    },
  },
  contact: {
    title: 'Contact',
    line_id: 'Line ID',
    line_url: 'Line URL',
    line_link: 'Line Link',
    qr_code: 'Line QrCode Image url',
    telegram_id: 'Telegram ID',
    telegram_url: 'Telegram URL',
    telegram_link: 'Telegram Link',
    telegram_qrcode: 'Telegram QrCode Image url',
    message: 'Contact Message',
    announcement: 'Announcement',
    verify_mode: 'Verify Mode (Register) - OTP',
  },
  roles: {
    title: 'Role',
    name: 'Role name',
    permissions: 'Permissions',
  },
  brands: {
    title: 'Brand',
    name: 'Brand name',
    logo: 'Brand logo',
  },
  rewards: {
    random_rewards: 'Random rewards',
    games: 'Reward random games',
    name: 'Reward name',
    history: 'Reward redemption history',
    coupon: 'Coupon',
    coupon_no: 'No. of Coupon',
    coupon_settings: 'Coupon settings',
    coupon_history: 'Coupon history',
    coupon_redeem_history: 'Redeem history',
    point: 'Reward point',
    image: 'Image',
    range_date: 'Start - End date',
    start_date: 'Start date',
    end_date: 'End date',
    use_coupon: 'No. of coupon/uses',
    use_point: 'Redeem point',
    type: 'Reward type',
    random_percentage: 'Random percentage',
    reward_amount: 'Reward amount',
    collect_value: 'No. of game',
    point_value: 'Total point',
    win: 'Win',
    loss: 'Loss',
    refill: 'Refill',
  },
  cashback: {
    settings: 'Cashback settings',
    history: 'Cashback history',
    cashback_type: 'Cashback type',
    cashback_value: 'Cashback amount',
    interval_unit: 'Period',
    start_date: 'Start date',
    next_date: 'Next date',
    turnover_point: 'Turnover',
    min_return: 'Minimum withdraw/Round',
    max_return: 'Maximum withdraw/Round',
    max_cashback_per_round: 'Maximum cashback/Round',
    min_cashback_per_round: 'Minimum cashback/Round',
    per_people_per_day: 'Cashback People/Day',
    agent_credit: 'Credit agent',
    game_type: 'Game type',
    is_bet_balance: 'Calculate from total bet amount',
    is_loss_balance: 'Calculate from total loss bet amount',
    is_include_promotion: 'Include other promotion(s)',
  },
  promotion: {
    promotion: 'Promotion',
    bonusType: 'Bonus Type',
    name: 'Promotion name',
    condition: 'Condition type',
    min_deposit: 'Minimum deposit',
    free_credit: 'Free credit',
    gain_credit: 'Gain credit',
    max_gain_credit: 'Maximum gain credit',
    history: 'Promotion redemption history',
    types: {
      ALL_REFILL: 'All deposit',
      FIRST_TIME_REFILL: 'First time deposit',
      DAILY_REFILL: 'Daily deposit',
      FREE_CREDIT: 'Free credit',
      TIME_REFILL: 'Time refill',
    },
  },
  promotion_code: {
    name: 'Promotion Name',
    code: 'Code',
    usage: 'Usage',
    count: 'Apply Count',
    one_time: 'One time per user',
    many_time: 'Many time per user',
    max_receive: 'Max receive',
    once_per_user: 'Can be used once per user'
  }
}
