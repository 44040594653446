import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/tracking-link`

export default {
  getTrackingLinks(limit, page, search, agentId, masterId) {
    try {
      return axios.get(
        baseURL,
        {
          params: {
            limit,
            page,
            search,
            agentId,
            masterId
          }
        }
      )
    } catch (e) {
      throw e
    }
  },
  fetchTrackingLinkById(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  fetchTrackingLinkDataById(id) {
    try {
      return axios.get(`${baseURL}/${id}/info`)
    } catch (e) {
      throw e
    }
  },
  createTrackingLink(data) {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if(Array.isArray(value)){
          value.forEach((prop, i) => {
            for (const [arrKey, arrValue] of Object.entries(prop)) {
              formData.append(`${key}[${i}][${arrKey}]`, arrValue);
            }
          })
        } else {
          formData.append(`${key}`, value);
        }
      }
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.post(`${baseURL}`, formData, options)
    } catch (e) {
      throw e
    }
  },
  updateTrackingLink(id, data) {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if(Array.isArray(value)){
          value.forEach((prop, i) => {
            for (const [arrKey, arrValue] of Object.entries(prop)) {
              formData.append(`${key}[${i}][${arrKey}]`, arrValue);
            }
          })
        } else {
          formData.append(`${key}`, value);
        }
      }
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.put(`${baseURL}/${id}`, formData, options)
    } catch (e) {
      throw e
    }
  },
  deleteTrackingLink(id) {
    try {
      return axios.delete(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
}
