import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('date', (value) => {
  if (value) {
    return dayjs(value).format('DD/MM/BBBB')
  }
  return '-'
})
Vue.filter('datetime', (value) => {
  if (value) {
    return dayjs(value).format('DD/MM/BB HH:mm')
  }
  return '-'
})
Vue.filter('datetimeSecond', (value) => {
  if (value) {
    return dayjs(value).format('DD/MM/BB HH:mm:ss')
  }
  return '-'
})
Vue.filter('time', (value) => {
  if (value) {
    return dayjs(value).format('HH:mm')
  }
  return '-'
})
Vue.filter('money', (value) => {
  if (value) {
    const result = Number(value)
    return result.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }
  return '0'
})
Vue.filter('currency', (value) => {
  if (value) {
    return Number(value).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4
    })
  }
  return '0.00'
})
