export const BankCodeEnum = {
    BBL         : '002',
    BBLA        : '002',
    KBANK       : '004',
    KBNK        : '004',
    KTB         : '006',
    KTBA        : '006',
    TMB         : '011',
    TMBA        : '011',
    TTB         : '011',
    TTBA        : '011',
    SCB         : '014',
    SCBA        : '014',
    CITI        : '017',
    SMBC        : '018',
    SCBT        : '020',
    CIMB        : '022',
    CMBT        : '022',
    UOB         : '024',
    UOBT        : '024',
    BAY         : '025',
    BAYA        : '025',
    GSB         : '030',
    GSBA        : '030',
    HSBC        : '031',
    DB          : '032',
    GHB         : '033',
    GHBA        : '033',
    BAAC        : '034',
    MHCB        : '039',
    TBANK       : '065',
    TBNK        : '065',
    ISBT        : '066',
    TISCO       : '067',
    TSCO        : '067',
    KK          : '069',
    KKB 		: '069',
    KKP         : '069',
    KKBA        : '069',
    ICBC        : '070',
    TCRB        : '071',
    LHBANK      : '073',
    LHBA        : '073',
    BCEL        : 'BCEL',
    TRUE_WALLET : 'TRUE',
}

export const SupportedAutoBanking = [
    BankCodeEnum.SCB,
    BankCodeEnum.BAY,
    BankCodeEnum.KBANK,
    BankCodeEnum.KTB,
    BankCodeEnum.GSB,
    BankCodeEnum.TRUE_WALLET
]

export const BankUseTypeEnum = {
    BOTH: 0,
    DEPOSIT: 1,
    WITHDRAW: 2,
    HOLD: 3
}