<template>
  <div id="wrapper">
    <div
      :class="{
        'left-side-menu-condensed': isMenuCondensed,
        'sidebar-enable': isMobileMenuOpened,
      }"
    >
      <TopBar :is-menu-opened="isMobileMenuOpened" @toggle-menu="toggleMenu" />
      <AsideBar :is-condensed="isMenuCondensed" />
      <div class="content-page mt-2">
        <div class="content">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import AsideBar from '../../components/layouts/aside-bar.vue'
import TopBar from '../../components/layouts/top-bar.vue'
import Footer from '../../components/layouts/footer.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    TopBar,
    AsideBar,
    Footer,
  },
  data() {
    return {
      isPageLoaded: false,
      isMenuCondensed: false,
      isMobileMenuOpened: true,

      intervalIdentifier: null,
    }
  },
  computed: {
    ...mapGetters([
      'adminPermissions',
      'isMakingSound',
      'userInfo',
      'loggedIn',
      'adminRole',
      'isOwner',
      'isMaster',
      'isAgent',
    ]),
    ...mapState({
      intervalFetch: (state) => state.credit.intervalFetch,
    }),
  },
  watch: {
    'intervalFetch.enabled'(val) {
      if (val) {
        this.startInterval()
      } else {
        this.stopInterval()
      }
    },
    isMakingSound(val) {
      if (val) {
        this.onPlaySound()
      }
    },
    adminRole(val) {
      if(val){
        this.fetchAllAgents();
      }
    },
  },
  created() {
    this.toggleMenu()
    // Start the interval on init
  },
  mounted() {
    setTimeout(() => {
      this.toggleIntervalFetch()
      this.isPageLoaded = true
    }, 5000)
  },
  methods: {
    ...mapActions([
      'fetchWithdrawRequests',
      'fetchUnMatchHistory',
      'muteSound',
      'toggleIntervalFetch',
      'fetchAgents',
    ]),
    toggleMenu() {
      if (this.$windowWidth <= 1024 && this.$windowWidth > 767) {
        this.isMenuCondensed = !this.isMenuCondensed
        this.isMobileMenuOpened = false
      } else if (this.$windowWidth <= 767) {
        this.isMobileMenuOpened = !this.isMobileMenuOpened
        this.isMenuCondensed = false
      } else {
        this.isMenuCondensed = false
        this.isMobileMenuOpened = false
      }
    },
    fetchAllAgents() {
      // if(!this.isAgent){
      //   this.fetchAgents({
      //     limit: 20, 
      //     page: 1,
      //     search: '', 
      //     agentId: '', 
      //     masterId: ''
      //   })
      // }
    },
    /**
     * fetching user transaction. This will trigger call on every page
     * if status was set to true
     */
    startInterval() {
      this.intervalIdentifier = setInterval(() => {
        this.fetchData()
      }, this.intervalFetch.intervalTime)
    },
    stopInterval() {
      clearInterval(this.intervalIdentifier)
    },
    fetchData() {
      // TODO fetch transactions
      // this.fetchWithdrawRequests({
      //   limit: 50,
      //   offset: 0,
      // })
      // this.fetchUnMatchHistory({
      //   limit: 50,
      //   offset: 0,
      //   agentId:
      //     this.isOwner || this.isMaster
      //       ? null
      //       : this.userInfo.agentId,
      // })
    },
    onPlaySound() {
      // test ignore
      const sound = new Audio(require('../../assets/sounds/hud-chew.mp3'))
      sound.play()
      this.muteSound()
    },
  },
}
</script>
