import AnnounceService from '../services/announce'
import router from '@router';

const mutations = {
  setAnnounce(state, announce) {
    state.announceDetail = announce
  },
  setAnnounces(state, announces) {
    state.announces = announces
  },
}
const actions = {
  async fetchAnnounces(
    { commit, dispatch },
    { limit = '', page = '', search = '', agentId = '', masterId = '' }
  ) {
    try {
      const announces = await AnnounceService.getAnnounces(
        limit,
        page,
        search,
        agentId,
        masterId
      )
      commit('setAnnounces', announces)
      return announces
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async fetchAnnounce({ commit, dispatch }, id) {
    try {
      const announce = await AnnounceService.fetchAnnounceById(id)
      commit('setAnnounce', announce)
      return announce
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async createAnnounce({ dispatch }, data) {
    try {
      const announce = await AnnounceService.createAnnounce(data)
      await router.push(`/announce/detail/${announce.id}`)
      dispatch('notifySuccess')
      return announce
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async updateAnnounce({ dispatch }, { id, data }) {
    try {
      const announce = await AnnounceService.updateAnnounce(id, data)
      dispatch('notifySuccess')
      return announce
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async deleteAnnounce({ dispatch }, id) {
    try {
      const announce = await AnnounceService.deleteAnnounce(id)
      dispatch('notifySuccess')
      return announce
    } catch (e) {
      dispatch('notifyError', e)
    }
  }
}

const getters = {
  announces: (state) => state.announces,
  announceDetail: (state) => state.announceDetail,
}

const state = {
  announceDetail: null,
  announces: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
}
export default {
  state,
  mutations,
  actions,
  getters,
}
