import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/file-management`

export default {
    uploadFile(data) {
        try {
            const formData = new FormData();
            formData.append('image', data)
            const options = {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
            return axios.post(`${baseURL}/upload`, formData, options)
        } catch (e) {
            throw e
        }
    },

    removeFile(data) {
        try {
            const json = JSON.stringify({ imageUrl: data });
            const options = {
                headers: { 'Content-Type': 'application/json' },
            }
            return axios.post(`${baseURL}/remove`, json, options)
        } catch (e) {
            throw e
        }
    }
}
