<template>
  <img
    v-if="bankAbbrev"
    :alt="bankAbbrev"
    :src="require(`@assets/images/banks/${bankAbbrev}.svg`)"
    :style="`background-color: ${bankColor}`"
    class="bank"
    width="25"
  />
  <!-- <TrueIcon v-else-if="bankCode && bankCode === 'TRUE'" /> -->
</template>

<script>
import {BANK_ICONS, banks} from '@utils/data-mock'
import {BankCodeEnum} from '@src/constants/bank';

export default {
  name: 'BankIcon',
  components: {
    // TrueIcon: () => import('@components/commons/true-wallet-icon.vue'),
  },
  props: {
    bankCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bank: BANK_ICONS,
    }
  },
  computed: {
    banks() {
      return banks.filter((bank) => process.env.VUE_APP_IS_LA === 'true' || bank.bank_code !== BankCodeEnum.BCEL)
    },
    bankAbbrev() {
      const found = this.banks.filter((bank) => process.env.VUE_APP_IS_LA === 'true' || bank.bank_code !== BankCodeEnum.BCEL).find((prop) => prop.bank_code === this.bankCode)
      return found?.bank_abbrev.toLowerCase()
    },
    bankColor() {
      return BANK_ICONS[this.bankAbbrev]?.color || '#fff'
    },
  },
}
</script>

<style lang="scss" scoped>
.bank {
  padding: 2px;
  border-radius: 7px;
}
</style>
