<template>
<b-form inline>
  <label class="mr-sm-2" for="limit">แสดงผลทั้งหมด</label>
  <b-select
    id="limit"
    v-model="limit"
  >
    <b-form-select-option
      v-for="option in options"
      :key="option.key"
      :value="option.value"
    >{{ option.value }} </b-form-select-option>
  </b-select>
</b-form>
</template>

<script>

export default {
  name: 'LimitSelect',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      limit: 20,
      options: [
        { key: '10', value: 10 },
        { key: '20', value: 20 },
        { key: '50', value: 50 },
        { key: '100', value: 100 },
      ],
    }
  },
  watch: {
    limit(val) {
      this.$emit('input', val)
    },
    value(val) {
      if (val !== this.limit) {
        this.limit = val
      }
    },
  },
  created() {
    if (this.value) {
      this.limit = this.value
    }
  },
}
</script>
