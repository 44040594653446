import CreditService from '../services/credit'
import UserService from '../services/user'

const mutations = {
  FETCH_AGENT_CREDIT_LOGS_REQUEST(state) {
    state.isFetchingAgentCreditLogs = true
  },
  FETCH_AGENT_CREDIT_LOGS_SUCCESS(state, payload) {
    state.isFetchingAgentCreditLogs = false
    state.agentCredits = payload
  },
  FETCH_AGENT_CREDIT_LOGS_FAILURE(state) {
    state.isFetchingAgentCreditLogs = false
  },
  FETCH_PLAYERS_CREDIT_HISTORY_REQUEST(state) {
    state.isFetchingPlayersCreditHistory = true
  },
  FETCH_PLAYERS_CREDIT_HISTORY_SUCCESS(state, payload) {
    state.isFetchingPlayersCreditHistory = false
    state.playersCreditHistory = payload
  },
  FETCH_PLAYERS_CREDIT_HISTORY_FAILURE(state) {
    state.isFetchingPlayersCreditHistory = false
  },
  FETCH_UN_MATCH_HISTORY_REQUEST(state) {
    state.isFetchingUnMatchHistory = true
    state.isFetchingUnMatchHistoryError = false
    state.isFetchingUnMatchHistorySuccess = false
  },
  FETCH_UN_MATCH_HISTORY_SUCCESS(state, payload) {
    state.isFetchingUnMatchHistory = false
    state.isFetchingUnMatchHistorySuccess = true
    state.unMatchHistory = payload
  },
  FETCH_UN_MATCH_HISTORY_FAILURE(state, payload) {
    state.isFetchingUnMatchHistory = false
    state.isFetchingUnMatchHistoryError = payload
  },
  FETCH_UN_MATCH_HISTORY_BY_ID_REQUEST(state) {
    state.isFetchingUnMatchHistoryById = true
    state.isFetchingUnMatchHistoryByIdError = false
    state.isFetchingUnMatchHistoryByIdSuccess = false
  },
  FETCH_UN_MATCH_HISTORY_BY_ID_SUCCESS(state, payload) {
    state.isFetchingUnMatchHistoryById = false
    state.isFetchingUnMatchHistoryByIdSuccess = true
    if (payload && payload.id) {
      state.unMatchHistoryDetail = payload
    }
  },
  FETCH_UN_MATCH_HISTORY_BY_ID_FAILURE(state, payload) {
    state.isFetchingUnMatchHistoryById = false
    state.isFetchingUnMatchHistoryByIdError = payload
  },
  MATCH_UN_MATCH_HISTORY_REQUEST(state) {
    state.isMatchingUnMatchHistory = true
    state.isMatchingUnMatchHistoryError = false
    state.isMatchingUnMatchHistorySuccess = false
  },
  MATCH_UN_MATCH_HISTORY_SUCCESS(state) {
    state.isMatchingUnMatchHistory = false
    state.isMatchingUnMatchHistorySuccess = true
  },
  MATCH_UN_MATCH_HISTORY_FAILURE(state) {
    state.isMatchingUnMatchHistory = false
  },
  DELETE_UN_MATCH_HISTORY_REQUEST(state) {
    state.isDeletingUnMatchHistory = true
    state.isDeletingUnMatchHistoryError = false
    state.isDeletingUnMatchHistorySuccess = false
  },
  DELETE_UN_MATCH_HISTORY_SUCCESS(state) {
    state.isDeletingUnMatchHistory = false
    state.isDeletingUnMatchHistorySuccess = true
  },
  DELETE_UN_MATCH_HISTORY_FAILURE(state) {
    state.isDeletingUnMatchHistory = false
  },
  FETCH_CREDIT_HISTORY_BY_PLAYER_ID_REQUEST(state) {
    state.isFetchingCreditHistoryByPlayerId = true
    state.isFetchingCreditHistoryByPlayerIdError = false
    state.isFetchingCreditHistoryByPlayerIdSuccess = false
  },
  FETCH_CREDIT_HISTORY_BY_PLAYER_ID_SUCCESS(state, payload) {
    state.isFetchingCreditHistoryByPlayerId = false
    state.isFetchingCreditHistoryByPlayerIdSuccess = true
    state.playerCreditHistory = payload
  },
  FETCH_CREDIT_HISTORY_BY_PLAYER_ID_FAILURE(state, payload) {
    state.isFetchingCreditHistoryByPlayerId = false
    state.isFetchingCreditHistoryByPlayerIdError = payload
  },
  FETCH_WITHDRAW_REQUESTS_REQUEST(state) {
    state.isFetchingWithdrawRequests = true
    state.isFetchingWithdrawRequestsError = false
    state.isFetchingWithdrawRequestsSuccess = false
  },
  FETCH_WITHDRAW_REQUESTS_SUCCESS(state, payload) {
    state.isFetchingWithdrawRequests = false
    state.isFetchingWithdrawRequestsSuccess = true
    state.withdrawRequests = payload
  },
  FETCH_WITHDRAW_REQUESTS_FAILURE(state, payload) {
    state.isFetchingWithdrawRequests = false
    state.isFetchingWithdrawRequestsError = payload
  },
  DIRECT_DEPOSIT_AGENT_CREDIT_REQUEST(state) {
    state.isDepositingDirectAgentCredit = true
    state.isDepositingDirectAgentCreditError = false
    state.isDepositingDirectAgentCreditSuccess = false
  },
  DIRECT_DEPOSIT_AGENT_CREDIT_SUCCESS(state) {
    state.isDepositingDirectAgentCredit = false
    state.isDepositingDirectAgentCreditSuccess = true
  },
  DIRECT_DEPOSIT_AGENT_CREDIT_FAILURE(state) {
    state.isDepositingDirectAgentCredit = false
    state.isDepositingDirectAgentCreditError = true
  },
  DIRECT_WITHDRAW_AGENT_CREDIT_REQUEST(state) {
    state.isWithdrawingDirectAgentCredit = true
    state.isWithdrawingDirectAgentCreditError = false
    state.isWithdrawingDirectAgentCreditSuccess = false
  },
  DIRECT_WITHDRAW_AGENT_CREDIT_SUCCESS(state) {
    state.isWithdrawingDirectAgentCredit = false
    state.isWithdrawingDirectAgentCreditSuccess = true
  },
  DIRECT_WITHDRAW_AGENT_CREDIT_FAILURE(state) {
    state.isWithdrawingDirectAgentCredit = false
    state.isWithdrawingDirectAgentCreditError = true
  },
  DIRECT_WITHDRAW_CREDIT_REQUEST(state) {
    state.isWithdrawingDirectCredit = true
    state.isWithdrawingDirectCreditError = false
    state.isWithdrawingDirectCreditSuccess = false
  },
  DIRECT_WITHDRAW_CREDIT_SUCCESS(state) {
    state.isWithdrawingDirectCredit = false
    state.isWithdrawingDirectCreditSuccess = true
  },
  DIRECT_WITHDRAW_CREDIT_FAILURE(state) {
    state.isWithdrawingDirectCredit = false
    state.isWithdrawingDirectCreditError = true
  },
  ACCEPT_PLAYER_WITHDRAW_REQUEST(state) {
    state.isAcceptingPlayerWithdraw = true
    state.isAcceptingPlayerWithdrawError = false
    state.isAcceptingPlayerWithdrawSuccess = false
  },
  ACCEPT_PLAYER_WITHDRAW_SUCCESS(state) {
    state.isAcceptingPlayerWithdraw = false
    state.isAcceptingPlayerWithdrawSuccess = true
  },
  ACCEPT_PLAYER_WITHDRAW_FAILURE(state) {
    state.isAcceptingPlayerWithdraw = false
    state.isAcceptingPlayerWithdrawError = true
  },
  REJECT_PLAYER_WITHDRAW_REQUEST(state) {
    state.isRejectingPlayerWithdraw = true
    state.isRejectingPlayerWithdrawError = false
    state.isRejectingPlayerWithdrawSuccess = false
  },
  REJECT_PLAYER_WITHDRAW_SUCCESS(state) {
    state.isRejectingPlayerWithdraw = false
    state.isRejectingPlayerWithdrawSuccess = true
  },
  REJECT_PLAYER_WITHDRAW_FAILURE(state) {
    state.isRejectingPlayerWithdraw = false
    state.isRejectingPlayerWithdrawError = true
  },
  UPLOAD_SLIP_FILE_REQUEST(state) {
    state.isUploadingSlipFile = true
    state.isUploadingSlipFileError = false
    state.isUploadingSlipFileSuccess = false
  },
  UPLOAD_SLIP_FILE_SUCCESS(state, payload) {
    state.isUploadingSlipFile = false
    state.isUploadingSlipFileSuccess = true
    state.uploadedFile = payload
  },
  UPLOAD_SLIP_FILE_FAILURE(state) {
    state.isUploadingSlipFile = false
    state.isUploadingSlipFileError = true
  },
  TOGGLE_INTERVAL_FETCH(state) {
    state.intervalFetch.enabled = !state.intervalFetch.enabled
  },
  MANUAL_SET_STATUS_REQUEST(state) {
    state.isSettingStatusToSuccess = true;
    state.isSettingStatusToSuccessError = '';
  },
  MANUAL_SET_STATUS_SUCCESS(state) {
    state.isSettingStatusToSuccess = false;
  },
  MANUAL_SET_STATUS_FAILURE(state, payload) {
    state.isSettingStatusToSuccess = false;
    state.isSettingStatusToSuccessError = payload;
  },
  SET_UNMATCH_FILTER_TYPE(state, payload) {
    state.unMatchFilterType = payload;
  }
}
const actions = {
  async fetchMasterCreditLogs({ commit, dispatch }, { limit, page, search, masterId, agentId, date, type }) {
    try {
      commit('FETCH_AGENT_CREDIT_LOGS_REQUEST')
      const response = await CreditService.fetchAgentCreditLogs(limit, page, search, masterId, agentId, date, type)
      commit('FETCH_AGENT_CREDIT_LOGS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_AGENT_CREDIT_LOGS_FAILURE', e.message)
    }
  },
  async fetchAgentCreditLogs({ commit, dispatch }, { limit, page, search, masterId, agentId, userAgentId, type, from, to }) {
    try {
      commit('FETCH_AGENT_CREDIT_LOGS_REQUEST')
      const response = await CreditService.fetchAgentCreditLogs(limit, page, search, masterId, agentId, userAgentId, type, from, to)
      commit('FETCH_AGENT_CREDIT_LOGS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_AGENT_CREDIT_LOGS_FAILURE', e.message)
    }
  },
  async fetchPlayersCreditHistory(
    { commit, dispatch },
    { limit, page, search, masterId, gameId, agentId, userAgentId, type, date}
  ) {
    try {
      commit('FETCH_PLAYERS_CREDIT_HISTORY_REQUEST')
      const response = await CreditService.fetchAllCreditLogs(limit, page, search, masterId, gameId, agentId, userAgentId, type, date)
      commit('FETCH_PLAYERS_CREDIT_HISTORY_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PLAYERS_CREDIT_HISTORY_FAILURE', e.message)
    }
  },
  async fetchUnMatchHistory({ commit, dispatch, state }, { limit, offset, agentId }) {
    try {
      commit('FETCH_UN_MATCH_HISTORY_REQUEST')

      const response = await CreditService.fetchUnMatchHistory(
        limit,
        offset,
        agentId,
        state.unMatchFilterType,
      )
      response.data?.map((item) => {
        //hardcoded add 7 hours becuz core banking response -7 h
        let created_at = new Date(item.created_at)
        item.created_at = new Date(
          created_at.setHours(created_at.getHours() + 7)
        ).toISOString()
        return item
      })

      commit('FETCH_UN_MATCH_HISTORY_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_UN_MATCH_HISTORY_FAILURE', e.message)
    }
  },
  async fetchUnMatchHistoryById({ commit, dispatch }, id) {
    try {
      commit('FETCH_UN_MATCH_HISTORY_BY_ID_REQUEST')
      const response = await CreditService.fetchUnMatchHistoryById(id)

      commit(
        'FETCH_UN_MATCH_HISTORY_BY_ID_SUCCESS',
        response.unmatchedTxn ? response.unmatchedTxn : {}
      )
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_UN_MATCH_HISTORY_BY_ID_FAILURE', e.message)
    }
  },
  async deleteUnMatchHistoryById({ commit, dispatch }, id) {
    try {
      commit('DELETE_UN_MATCH_HISTORY_REQUEST')
      await CreditService.deleteUnMatchHistoryById(id)
      commit('DELETE_UN_MATCH_HISTORY_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_UN_MATCH_HISTORY_FAILURE', e.message)
    }
  },
  async matchUnMatchHistory({ commit, dispatch }, { id, userId }) {
    try {
      commit('MATCH_UN_MATCH_HISTORY_REQUEST')
      await CreditService.matchUnMatchHistory(id, userId)
      commit('MATCH_UN_MATCH_HISTORY_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('MATCH_UN_MATCH_HISTORY_FAILURE', e.message)
    }
  },
  async matchUnMatchHistoryByCodeName({ commit, dispatch }, { id, codeName }) {
    try {
      commit('MATCH_UN_MATCH_HISTORY_REQUEST')
      await CreditService.matchUnMatchHistoryByCodeName(id, codeName)
      commit('MATCH_UN_MATCH_HISTORY_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('MATCH_UN_MATCH_HISTORY_FAILURE', e.message)
    }
  },
  async fetchCreditHistoryByPlayerId(
    { commit, dispatch },
    { playerId, limit, offset, type = 'all' }
  ) {
    try {
      commit('FETCH_CREDIT_HISTORY_BY_PLAYER_ID_REQUEST')
      const response = await CreditService.fetchCreditHistoryByPlayerId(
        playerId,
        limit,
        offset,
        type
      )
      commit('FETCH_CREDIT_HISTORY_BY_PLAYER_ID_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_CREDIT_HISTORY_BY_PLAYER_ID_FAILURE', e.message)
    }
  },
  async fetchWithdrawRequests({ commit, dispatch, state }) {
    try {
      commit('FETCH_WITHDRAW_REQUESTS_REQUEST')
      let lastFetchItem = null
      if (state.withdrawRequests?.data?.length > 0) {
        lastFetchItem = state.withdrawRequests.data[0].id
      }
      const response = await CreditService.fetchWithdrawRequests()
      response.data?.map((item) => {
        //hardcoded add 7 hours becuz core banking response -7 h
        let created_at = new Date(item.created_at)
        item.created_at = new Date(
          created_at.setHours(created_at.getHours() + 7)
        ).toISOString()
        return item
      })

      if (response?.data?.length > 0 && lastFetchItem !== response.data[0].id) {
        dispatch('makeSound')
      }

      commit('FETCH_WITHDRAW_REQUESTS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_WITHDRAW_REQUESTS_FAILURE', e.message)
    }
  },
  async directDepositAgentCredit({ commit, dispatch }, { agentId, data }) {
    try {
      commit('DIRECT_DEPOSIT_AGENT_CREDIT_REQUEST')
      await CreditService.directDepositAgentCredit(agentId, data)
      commit('DIRECT_DEPOSIT_AGENT_CREDIT_SUCCESS')
      dispatch('fetchMasterCreditLogs')
      dispatch('notifySuccess', 'created')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DIRECT_DEPOSIT_AGENT_CREDIT_FAILURE', e.message)
    }
  },
  async directWithdrawAgentCredit({ commit, dispatch }, { agentId, data }) {
    try {
      commit('DIRECT_WITHDRAW_AGENT_CREDIT_REQUEST')
      await CreditService.directWithdrawAgentCredit(agentId, data)
      commit('DIRECT_WITHDRAW_AGENT_CREDIT_SUCCESS')
      dispatch('fetchMasterCreditLogs')
      dispatch('notifySuccess', 'created')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DIRECT_WITHDRAW_AGENT_CREDIT_FAILURE', e.message)
    }
  },
  async uploadSlipFile({ commit, dispatch }, { file }) {
    try {
      commit('UPLOAD_SLIP_FILE_REQUEST')
      const result = await UserService.uploadFile(file)
      commit('UPLOAD_SLIP_FILE_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPLOAD_SLIP_FILE_FAILURE', e.message)
    }
  },
  async rejectPlayerWithdraw(
    { commit, dispatch },
    { txnId, isReturnCredit, detail }
  ) {
    try {
      commit('REJECT_PLAYER_WITHDRAW_REQUEST')
      await CreditService.rejectPlayerWithdraw(txnId, isReturnCredit, detail)
      commit('REJECT_PLAYER_WITHDRAW_SUCCESS')
      dispatch('fetchWithdrawRequests')
      dispatch('fetchPlayersCreditHistory')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('REJECT_PLAYER_WITHDRAW_FAILURE', e.message)
    }
  },
  async acceptPlayerWithdraw({ commit, dispatch }, txnId) {
    try {
      commit('ACCEPT_PLAYER_WITHDRAW_REQUEST')
      await CreditService.acceptPlayerWithdraw(txnId)
      commit('ACCEPT_PLAYER_WITHDRAW_SUCCESS')
      dispatch('fetchWithdrawRequests')
      dispatch('fetchPlayersCreditHistory')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ACCEPT_PLAYER_WITHDRAW_FAILURE', e.message)
    }
  },
  async manualSetStatusToSuccess({ commit, dispatch }, { txnId }) {
    try {
      commit('MANUAL_SET_STATUS_REQUEST')
      await CreditService.manualSetStatusToSuccess(txnId)
      commit('MANUAL_SET_STATUS_SUCCESS')
      dispatch('fetchWithdrawRequests')
    } catch (e) {
      dispatch('notifyError', e)
      commit('MANUAL_SET_STATUS_FAILURE', e.message)
    }
  },
  toggleIntervalFetch({ commit }) {
    commit('TOGGLE_INTERVAL_FETCH')
  },
  setUnMatchFilterType({ commit }, { type }) {
    commit('SET_UNMATCH_FILTER_TYPE', type)
  }
}
const getters = {
  agentCredits: (state) => state.agentCredits,
  masterCredits: (state) => state.masterCredits,
  withdrawRequests: (state) => state.withdrawRequests,
  playersCreditHistory: (state) => state.playersCreditHistory,
  playerCreditHistory: (state) => state.playerCreditHistory,
  unMatchHistory: (state) => state.unMatchHistory,
  unMatchHistoryDetail: (state) => state.unMatchHistoryDetail,
  uploadedFile: (state) => state.uploadedFile,
}
const state = {
  agentCredits: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingAgentCreditLogs: false,
  masterCredits: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingMasterCreditLogs: false,

  playersCreditHistory: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingPlayersCreditHistory: false,

  unMatchHistory: {
    data: [],
    total: 0,
    next: 0,
    previous: 0,
    pagination: { totalItems: 0 },
  },
  isFetchingUnMatchHistory: false,
  isFetchingUnMatchHistoryError: '',
  isFetchingUnMatchHistorySuccess: false,

  unMatchHistoryDetail: {
    detail: [],
  },
  isFetchingUnMatchHistoryById: false,
  isFetchingUnMatchHistoryByIdError: '',
  isFetchingUnMatchHistoryByIdSuccess: false,

  isMatchingUnMatchHistory: false,
  isMatchingUnMatchHistoryError: '',
  isMatchingUnMatchHistorySuccess: false,

  isDeletingUnMatchHistory: false,
  isDeletingUnMatchHistoryError: '',
  isDeletingUnMatchHistorySuccess: false,

  playerCreditHistory: { data: [], total: 0, next: 0, previous: 0 },
  isFetchingCreditHistoryByPlayerId: false,
  isFetchingCreditHistoryByPlayerIdError: '',
  isFetchingCreditHistoryByPlayerIdSuccess: false,

  withdrawRequests: { data: [], total: 0, next: 0, previous: 0 },
  isFetchingWithdrawRequests: false,
  isFetchingWithdrawRequestsError: '',
  isFetchingWithdrawRequestsSuccess: false,

  isDepositingDirectAgentCredit: false,
  isDepositingDirectAgentCreditError: false,
  isDepositingDirectAgentCreditSuccess: false,

  isWithdrawingDirectAgentCredit: false,
  isWithdrawingDirectAgentCreditError: false,
  isWithdrawingDirectAgentCreditSuccess: false,

  isWithdrawingDirectCredit: false,
  isWithdrawingDirectCreditError: false,
  isWithdrawingDirectCreditSuccess: false,

  isAcceptingPlayerWithdraw: false,
  isAcceptingPlayerWithdrawError: false,
  isAcceptingPlayerWithdrawSuccess: false,
  isRejectingPlayerWithdraw: false,
  isRejectingPlayerWithdrawError: false,
  isRejectingPlayerWithdrawSuccess: false,

  uploadedFile: '',
  isUploadingSlipFile: false,
  isUploadingSlipFileError: false,
  isUploadingSlipFileSuccess: false,

  intervalFetch: {
    intervalTime: 10000,
    enabled: false,
  },

  isSettingStatusToSuccess: false,
  isSettingStatusToSuccessError: '',
  unMatchFilterType: '',
}
export default {
  state,
  mutations,
  actions,
  getters,
}
