import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import UserService from '../services/user'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress'
import { allRoutes } from './routes'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes: allRoutes, 
  mode: 'history',
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    NProgress.start()
  }

  const isTokenExisted = UserService.getAccessToken();
  // If auth isn't required for the route, just continue.
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  if(authRequired){
    return isTokenExisted ? next() : next('/login')
  }
  if(routeTo.name === 'login'){
    return isTokenExisted ? next('/dashboard'): next()
  }
  return next()
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
