import LineService from '../services/line'

const mutations = {
  FETCH_LINE_NOTIFICATIONS_REQUEST(state) {
    state.isFetchingLineNotifications = true
  },
  FETCH_LINE_NOTIFICATIONS_SUCCESS(state, payload) {
    state.isFetchingLineNotifications = false
    state.lineNotifications = payload
  },
  FETCH_LINE_NOTIFICATIONS_FAILURE(state) {
    state.isFetchingLineNotifications = false
  },
  ADD_LINE_NOTIFICATION_REQUEST(state) {
    state.isCreatingLineNotification = true
  },
  ADD_LINE_NOTIFICATION_SUCCESS(state) {
    state.isCreatingLineNotification = false
  },
  ADD_LINE_NOTIFICATION_FAILURE(state) {
    state.isCreatingLineNotification = false
  },
  DELETE_LINE_NOTIFICATION_REQUEST(state) {
    state.isDeletingLineNotification = true
  },
  DELETE_LINE_NOTIFICATION_SUCCESS(state) {
    state.isDeletingLineNotification = false
  },
  DELETE_LINE_NOTIFICATION_FAILURE(state) {
    state.isDeletingLineNotification = false
  },
  FETCH_LINE_NOTIFICATION_BY_ID_REQUEST(state) {
    state.isFetchingLineNotification = true
  },
  FETCH_LINE_NOTIFICATION_BY_ID_SUCCESS(state, payload) {
    state.isFetchingLineNotification = false
    state.lineNotificationDetail = payload
  },
  FETCH_LINE_NOTIFICATION_BY_ID_FAILURE(state) {
    state.isFetchingLineNotification = false
  },
  UPDATE_LINE_NOTIFICATION_REQUEST(state) {
    state.isUpdatingLineNotification = true
  },
  UPDATE_LINE_NOTIFICATION_SUCCESS(state) {
    state.isUpdatingLineNotification = false
  },
  UPDATE_LINE_NOTIFICATION_FAILURE(state) {
    state.isUpdatingLineNotification = false
  },
}

const actions = {
  async fetchLineNotifications(
    { commit, dispatch },
    { limit = '', page = '', search = '', masterId = '', agentId = '' }
  ) {
    try {
      commit('FETCH_LINE_NOTIFICATIONS_REQUEST')
      const response = await LineService.fetchLineNotifications(
        limit,
        page,
        search,
        masterId,
        agentId
      )
      commit('FETCH_LINE_NOTIFICATIONS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LINE_NOTIFICATIONS_FAILURE', e.message)
    }
  },
  async createLineNotification({ commit, dispatch }, data) {
    try {
      commit('ADD_LINE_NOTIFICATION_REQUEST')
      await LineService.createLineNotification(data)
      commit('ADD_LINE_NOTIFICATION_SUCCESS')
      dispatch('notifySuccess', 'added')
      dispatch('fetchLineNotifications', {
        limit: '',
        page: '',
        search: '',
        masterId: '',
        agentId: '',
      })
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_LINE_NOTIFICATION_FAILURE', e.message)
    }
  },
  async deleteLineNotification({ commit, dispatch }, id) {
    try {
      commit('DELETE_LINE_NOTIFICATION_REQUEST')
      await LineService.deleteLineNotification(id)
      commit('DELETE_LINE_NOTIFICATION_SUCCESS')
      dispatch('fetchLineNotifications', {
        limit: '',
        page: '',
        search: '',
        masterId: '',
        agentId: '',
      })
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_LINE_NOTIFICATION_FAILURE', e.message)
    }
  },
  async fetchLineNotificationById( { commit, dispatch }, id ) {
    try {
      commit('FETCH_LINE_NOTIFICATION_BY_ID_REQUEST')
      const response = await LineService.fetchLineNotificationById(id )
      commit('FETCH_LINE_NOTIFICATION_BY_ID_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LINE_NOTIFICATION_BY_ID_FAILURE', e.message)
    }
  },
  async updateLineNotification({ commit, dispatch }, {id, data}) {
    try {
      commit('UPDATE_LINE_NOTIFICATION_REQUEST')
      await LineService.updateLineNotification(id, data)
      commit('UPDATE_LINE_NOTIFICATION_SUCCESS')
      dispatch('notifySuccess', 'updated')
      dispatch('fetchLineNotifications', {
        limit: '',
        page: '',
        search: '',
        masterId: '',
        agentId: '',
      })
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_LINE_NOTIFICATION_FAILURE', e.message)
    }
  },
}

const getters = {}

const state = {
  isFetchingLineNotifications: false,
  isCreatingLineNotification: false,
  isDeletingLineNotification: false,
  lineNotifications: [],

  isFetchingLineNotification: false,
  lineNotificationDetail: {},
  isUpdatingLineNotification: false,
}

export default {
  mutations,
  actions,
  getters,
  state,
}
