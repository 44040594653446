<template>
  <div class="navbar navbar-expand navbar-custom">
    <div class="container-fluid">
      <!-- LOGO -->
      <a href="/" class="navbar-brand d-none d-md-block">
        <span class="d-inline h5 ml-2 text-logo">
          Fukushū-sha
          <span class="text-primary">
            {{ adminRole }}
          </span>
        </span>
      </a>

      <ul class="navbar-nav d-md-none">
        <li class="nav-item">
          <button
            class="button-menu-mobile open-left"
            @click="$emit('toggle-menu')"
          >
            <feather
                v-if="isMenuOpened"
                type="x"
                class="close-icon"
            ></feather>
            <feather
                v-else
              type="menu"
              class="menu-icon align-middle"
            ></feather>
          </button>
        </li>
      </ul>

      <ul class="navbar-nav mx-auto">
        <b-button variant="danger" @click="onClickEmergency">
          <i class="uil uil-exclamation-triangle"></i>
          Emergency
        </b-button>
      </ul>

      <ul class="navbar-nav align-items-center">
        <li
            v-if="userInfo.credit"
            class="nav-item text-info d-none d-sm-inline-block"
            @click="getMe"
        >
          {{ $t('credits.credit')}}
          {{ userInfo.credit | currency }}
            <b-spinner
                v-if="isGettingMe"
                variant="info"
                small
            ></b-spinner>
            <i v-else class="uil uil-sync"/>
        </li>

        <AccountDropdown />
      </ul>
    </div>
  </div>
  <!-- end Topbar -->
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    AccountDropdown: () => import('./nav/account-dropdown'),
  },
  props: {
    isMenuOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      intervalIdentifier: null
    }
  },
  computed: {
    ...mapGetters(['brandName', 'brandLogo', 'adminRole', 'userInfo']),
    ...mapState({
      isGettingMe: (state) => state.user.isGettingMe,
    }),
  },
  mounted() {

  },
  beforeDestroy() {
    clearInterval(this.intervalIdentifier)
  },
  methods: {
    ...mapActions(['getMe', 'banAgent']),
    async loopGetMe() {
      await this.getMe()
      setTimeout(() => {
        this.loopGetMe()
      }, 5000)
    },
    onClickEmergency() {
      this.$bvModal
        .msgBoxConfirm('คำเตือน! หากกดยืนยัน เอเจ้นนี้จะไม่สามารถเข้าใช้งานได้ทั้งระบบ ยืนยันใช่หรือไม่?', {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
              this.banAgent(this.userInfo.agentId)
          }
        })
    }
  },
}
</script>

<style lang="scss">
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.button-menu-mobile {
  outline: none !important;
}
</style>
