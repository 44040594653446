import router from '../router/index'
import UserService from '../services/user'

const mutations = {
  LOGIN_REQUEST(state) {
    state.isLoggingIn = true
  },
  LOGIN_SUCCESS(state) {
    state.isLoggingIn = false
    state.isLoggingInError = true
  },
  LOGIN_FAILURE(state) {
    state.isLoggingIn = false
  },
  VERIFY_LOGIN_REQUEST(state) {
    state.isVerifyingLogin = true
  },
  VERIFY_LOGIN_SUCCESS(state) {
    state.isVerifyingLogin = false
  },
  VERIFY_LOGIN_FAILURE(state) {
    state.isVerifyingLogin = false
  },
  VALIDATE_USER_SUCCESS(state) {
    state.loggedIn = true
  },
  VALIDATE_USER_FAILURE(state) {
    state.loggedIn = false
  },
  CHANGE_LOGIN_STATE(state, payload) {
    state.loginState = payload
  },
  CREATE_NEW_PIN_REQUEST(state) {
    state.isCreatingNewPin = true
  },
  CREATE_NEW_PIN_SUCCESS(state) {
    state.isCreatingNewPin = false
    state.isCreatedPin = true
  },
  CREATE_NEW_PIN_FAILURE(state) {
    state.isCreatingNewPin = false
  },
  SAVE_CREATE_PIN_TOKEN(state, payload) {
    state.createPinToken = payload
  },
  SAVE_LOGIN_STATE_TOKEN(state, payload) {
    state.loginStateToken = payload
  },
  PIN_LOGIN_REQUEST(state) {
    state.isPinLoggingIn = true
  },
  PIN_LOGIN_SUCCESS(state) {
    state.isPinLoggingIn = false
  },
  PIN_LOGIN_FAILURE(state, payload) {
    state.isPinLoggingIn = false
    state.isPinLoggingInError = payload
  },
}
const actions = {
  async init({ commit, dispatch }) {
    // dispatch('fetchBrands')
    const userToken = UserService.getAccessToken()
    if (userToken) {
      await dispatch('getMe')
      await dispatch('fetchPermissions')
      commit('VALIDATE_USER_SUCCESS')
    } else {
      commit('VALIDATE_USER_FAILURE')
    }
  },
  async login({ commit, dispatch }, { username, password }) {
    try {
      commit('LOGIN_REQUEST')
      const result = await UserService.login(username, password)

      switch (result.state) {
        case 'REQUIRED_CREATE_PIN':
          commit('CHANGE_LOGIN_STATE', result.state)
          commit('SAVE_CREATE_PIN_TOKEN', result.createPinToken)
          return
        case 'LOGIN_STATE_0':
          commit('SAVE_LOGIN_STATE_TOKEN', result.loginStateToken)
          commit('CHANGE_LOGIN_STATE', result.state)
          return
      }

      commit('LOGIN_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('LOGIN_FAILURE')
    }
  },
  /**
   *
   * @param {object} data
   * @param {string} data.pin
   */
  async createNewPin({ commit, dispatch, state }, pin) {
    try {
      commit('CREATE_NEW_PIN_REQUEST')
      const result = await UserService.createPin({
        pin,
        createPinToken: state.createPinToken,
      })
      if (result.state === 'LOGIN_STATE_0') {
        commit('SAVE_LOGIN_STATE_TOKEN', result.loginStateToken)
        commit('CHANGE_LOGIN_STATE', result.state)
      }
      commit('CREATE_NEW_PIN_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_NEW_PIN_FAILURE')
    }
  },
  async pinLogin({ commit, state, dispatch }, pin) {
    try {
      commit('PIN_LOGIN_REQUEST')
      const result = await UserService.pinLogin({
        loginStateToken: state.loginStateToken,
        pin,
      })

      UserService.saveAccessToken(result)
      commit('LOGIN_SUCCESS', result)
      await dispatch('init')
      const { path } = router.currentRoute
      if (path !== '/dashboard') {
        router.replace('/dashboard')
      }
      commit('PIN_LOGIN_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('PIN_LOGIN_FAILURE', e.message)
    }
  },
  async verifyLogin({ commit, dispatch }, { ref, otp }) {
    try {
      commit('VERIFY_LOGIN_REQUEST')
      const result = await UserService.verifyLogin(ref, otp)
      const token = result?.data?.token || result?.token
      if (token) {
        UserService.saveAccessToken(token)
        commit('VERIFY_LOGIN_SUCCESS', token)
        await dispatch('init')
        const { path } = router.currentRoute
        if (path !== '/dashboard') {
          router.replace('/dashboard')
        }
      }
    } catch (e) {
      dispatch('notifyError', e)
      commit('VERIFY_LOGIN_FAILURE')
    }
  },
  async logout({ commit }) {
    localStorage.clear()
    commit('VALIDATE_USER_FAILURE')
    const { path } = router.currentRoute
    if (path !== '/login') {
      window.location.replace('/login')
    }
  },
}
const getters = {
  loggedIn: (state) => state.loggedIn,
}
const state = {
  loggedIn: false,
  isLoggingIn: false,
  isLoggingInError: false,
  isVerifyingLogin: false,

  loginState: 'LOGIN_USER_PASSWORD',
  isCreatingNewPin: false,
  isCreatedPin: false,
  createPinToken: '',
  isPinLoggingIn: false,
  isPinLoggingInError: '',
}
export default {
  state,
  mutations,
  actions,
  getters,
}
