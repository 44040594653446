import axios from 'axios'
import { v4 as uuid } from 'uuid';

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/deposit`

export default {
  fetchDepositProcessing(params) {
    try {
      return axios.get(`${baseURL}/processing`, {
        params
      })
    } catch (e) {
      throw e
    }
  },
  fetchDepositDone(params) {
    try {
      return axios.get(`${baseURL}/done`, {
        params
      })
    } catch (e) {
      throw e
    }
  },
  fetchDepositUnmatched(limit, page, search, masterId, agentId) {
    try {
      return axios.get(`${baseURL}/unmatched?limit=${limit}&page=${page}&search=${search}&masterId=${masterId}&agentId=${agentId}`)
    } catch (e) {
      throw e
    }
  },
  fetchDepositUnmatchedById(id) {
    try {
      return axios.get(`${baseURL}/${id}/unmatched`)
    } catch (e) {
      throw e
    }
  },
  addDepositUnmatched(id, data) {
    try {
      return axios.post(`${baseURL}/${id}/unmatched`, data)
    } catch (e) {
      throw e
    }
  },
  deleteDepositUnmatched(id) {
    try {
      return axios.delete(`${baseURL}/${id}/unmatched`)
    } catch (e) {
      throw e
    }
  },
  addDepositManual(agentId, data) {
    try {
      const formData = new FormData()
      formData.append('amount', data.amount)
      formData.append('username', data.username)
      formData.append('transactionDateTime', data.transactionDateTime)
      formData.append('agentBankAccountId', data.agentBankAccountId)
      formData.append('remark', data.remark)
      formData.append('image', data.image)
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { agentId }
      }
      return axios.post(`${baseURL}/manual`, formData, options)
    } catch (e) {
      throw e
    }
  },
  toggleDepositStatus(id, remark) {
    try {
      return axios.post(`${baseURL}/${id}/toggle`, { remark, trackingId: uuid() })
    } catch (e) {
      throw e
    }
  },
  fetchBankAccounts() {
    try {
      return axios.get(`${baseURL}/bank-accounts`)
    } catch (e) {
      throw e
    }
  },
  exportDepositTransactions(params) {
    try {
      return axios.request({
          method: 'POST',
          url: `${baseURL}/export`,
          responseType: 'blob',
          params
        })
        .then((response) => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'รายการฝาก.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
    } catch (e) {
      throw e
    }
  },
}
