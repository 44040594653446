import axios from 'axios'
// retrigger build
const baseURL = `${process.env.VUE_APP_NEW_API_URL}`
const userURL = `${process.env.VUE_APP_API_URL}/user`

export default {
  fetchRoles(limit, page, search, masterId, agentId) {
    try {
      return axios.get(`${baseURL}/role?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`)
    } catch (e) {
      throw e
    }
  },
  fetchRoleById(id) {
    try {
      return axios.get(`${baseURL}/role/${id}`)
    } catch (e) {
      throw e
    }
  },
  createRole(data) {
    try {
      return axios.post(`${baseURL}/role`, data)
    } catch (e) {
      throw e
    }
  },
  updateRole(id, data) {
    try {
      return axios.put(`${baseURL}/role/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  deleteRole(id) {
    try {
      return axios.delete(`${baseURL}/role/${id}`)
    } catch (e) {
      throw e
    }
  },
  fetchPermissions() {
    try {
      return axios.get(`${baseURL}/permission`)
    } catch (e) {
      throw e
    }
  },
  fetchPermissionById(id) {
    try {
      return axios.get(`${userURL}/admins/permissions/${id}`)
    } catch (e) {
      throw e
    }
  },
  createPermission(data) {
    try {
      return axios.post(`${userURL}/admins/permissions`, data)
    } catch (e) {
      throw e
    }
  },
  updatePermission(id, data) {
    try {
      return axios.put(`${userURL}/admins/permissions/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  deletePermission(id) {
    try {
      return axios.delete(`${userURL}/admins/permissions/${id}`)
    } catch (e) {
      throw e
    }
  },
}
