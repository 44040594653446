<template>
  <div class="form-group m-0">
    <div class="input-group input-group-merge">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <feather
            type="calendar"
            class="align-middle icon-sm"
          ></feather>
        </span>
      </div>
      <flat-pickr
        v-model="value"
        class="form-control"
        :config="dateConfig.monthSelect"
      ></flat-pickr>
    </div>
  </div>

</template>

<script>
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  props: {
    selectedDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: new Date(),
      dateConfig: {
        monthSelect: {
          plugins: [
            new monthSelectPlugin({
              shorthand: true,
              dateFormat: 'Y-m',
            }),
          ],
        },
      },
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.$emit('update', val)
      }
    },
  },
}
</script>