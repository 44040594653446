import PromotionCodeService from '@src/services/promotion-code';

const state = {
    list: null
}

const mutations = {
    SET_LIST(state, list) {
        state.list = list
    }
}

const actions = {
    async fetchPromotionCodes({ commit, dispatch }, pagination) {
        try {
            const result = await PromotionCodeService.getAll(pagination)
            commit('SET_LIST', result)
        } catch (e) {
            dispatch('notifyError', e)
        }
    },
    async findPromotionCode({ dispatch }, id) {
        try {
            return await PromotionCodeService.findById(id)
        } catch (e) {
            dispatch('notifyError', e)
        }
    },
    async createPromotionCode({ dispatch }, payload) {
        try {
            const result = await PromotionCodeService.create(payload)
            dispatch('notifySuccess')
            return result?.id ?? null
        } catch (e) {
            dispatch('notifyError', e)
            return null
        }
    },
    async updatePromotionCode({ dispatch }, {id, payload }) {
        try {
            await PromotionCodeService.update(id, payload)
            dispatch('notifySuccess')
        } catch (e) {
            dispatch('notifyError', e)
        }
    },
    async deletePromotionCode({ dispatch }, id) {
        try {
            await PromotionCodeService.delete(id)
            dispatch('notifySuccess', 'deleted')
        } catch (e) {
            dispatch('notifyError', e)
        }
    }
}

const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters,
}