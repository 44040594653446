import axios from 'axios'

// const baseURL = `${process.env.VUE_APP_NEW_API_URL}/user-agent`
const userURL = `${process.env.VUE_APP_API_URL}/user`

export default {
  fetchAffiliateSummary() {
    try {
      return axios.get(`${userURL}/affiliate/me/summary`)
    } catch (e) {
      throw e
    }
  },
  fetchAgentAffiliate(agentId, gameId, gameType) {
    try {
      return axios.get(
        `${userURL}/affiliate/levels/agent?agent_id=${agentId}&game_id=${gameId}&game_type=${gameType}`
      )
    } catch (e) {
      throw e
    }
  },
  createAgentAffiliate(data) {
    try {
      return axios.post(`${userURL}/affiliate/levels/agent`, data)
    } catch (e) {
      throw e
    }
  },
  updateAgentAffiliate(data) {
    try {
      return axios.put(`${userURL}/affiliate/levels/agent`, data)
    } catch (e) {
      throw e
    }
  },
  deleteAgentAffiliate(id) {
    try {
      return axios.delete(`${userURL}/affiliate/agent/${id}`)
    } catch (e) {
      throw e
    }
  },
  fetchPlayerAffiliate(userId, agentId, gameId, gameType) {
    try {
      return axios.get(
        `${userURL}/affiliate/levels/afuser?user_id=${userId}&agent_id=${agentId}&game_id=${gameId}&game_type=${gameType}`
      )
    } catch (e) {
      throw e
    }
  },
  updatePlayerAffiliate(data) {
    try {
      return axios.put(`${userURL}/affiliate/levels/afuser`, data)
    } catch (e) {
      throw e
    }
  },
}
