import BotService from '../services/bot'

const mutations = {
    FETCH_BOT_SETTINGS_REQUEST(state) {
        state.isFetchingBotSettings = true;
        state.isFetchingBotSettingsError = false;
        state.isFetchingBotSettingsSuccess = false;
    },
    FETCH_BOT_SETTINGS_SUCCESS(state, payload) {
        state.isFetchingBotSettings = false;
        state.isFetchingBotSettingsSuccess = true;
        state.botSettings = payload;
    },
    FETCH_BOT_SETTINGS_FAILURE(state, payload) {
        state.isFetchingBotSettings = false;
        state.isFetchingBotSettingsError = payload;
    },
    UPDATE_BOT_SETTINGS_REQUEST(state, payload) {
        state.isUpdatingBotSettings = true;
        state.isUpdatingBotSettingsError = false;
        state.isUpdatingBotSettingsSuccess = false;
        state.botSettingId = payload;
    },
    UPDATE_BOT_SETTINGS_SUCCESS(state) {
        state.isUpdatingBotSettings = false;
        state.isUpdatingBotSettingsSuccess = true;
    },
    UPDATE_BOT_SETTINGS_FAILURE(state, payload) {
        state.isUpdatingBotSettings = false;
        state.isUpdatingBotSettingsError = payload;
    },
    FLUSH_QUEUE_REQUEST(state) {
        state.isFlushingQueue = true;
        state.isFlushingQueueSuccess = false;
        state.isFlushingQueueError = false;
    },
    FLUSH_QUEUE_SUCCESS(state) {
        state.isFlushingQueue = false;
        state.isFlushingQueueSuccess = true;
    },
    FLUSH_QUEUE_FAILURE(state) {
        state.isFlushingQueue = false;
        state.isFlushingQueueError = true;
    },
}
const actions = {
    async fetchBotSettings({ commit, dispatch }) {
        try {
          commit('FETCH_BOT_SETTINGS_REQUEST');
          const response = await BotService.fetchBotSettings();
          commit('FETCH_BOT_SETTINGS_SUCCESS', response);
        } catch (e) {
          dispatch('notifyError', e)
          commit('FETCH_BOT_SETTINGS_FAILURE', e.message);
        }
      },
    async updateBotSettings({ commit, dispatch }, {id, data}) {
        try {
            commit('UPDATE_BOT_SETTINGS_REQUEST', id);
            await BotService.updateBotSettings(id, data);
            commit('UPDATE_BOT_SETTINGS_SUCCESS');
        } catch (e) {
            dispatch('notifyError', e)
            commit('UPDATE_BOT_SETTINGS_FAILURE', e.message);
        }
    },
    async flushQueue({ commit, dispatch }) {
        try {
            commit('FLUSH_QUEUE_REQUEST');
            await BotService.flushQueue();
            commit('FLUSH_QUEUE_SUCCESS');
        } catch (e) {
            dispatch('notifyError', e)
            commit('FLUSH_QUEUE_FAILURE', e.message);
        }
    },
}
const getters = {
    botSettings: (state) => state.botSettings,
}
const state = {
    botSettings: {},
    isFetchingBotSettings: false,
    isFetchingBotSettingsError: '',
    isFetchingBotSettingsSuccess: false,

    botSettingId: 0,
    isUpdatingBotSettings: false,
    isUpdatingBotSettingsError: '',
    isUpdatingBotSettingsSuccess: false,

    isFlushingQueue: false,
    isFlushingQueueError: false,
    isFlushingQueueSuccess: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
