import axios from 'axios'
import UserService from '../services/user'
import store from '../stores'
import router from '../router/index'

// set timeout
const instance = axios.create()
instance.defaults.timeout = 15000

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const userToken = UserService.getAccessToken()
    if (userToken) {
      config.headers.authorization = `Bearer ${userToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)
// Your Interceptor code to do something with the response data
// Return response data
axios.interceptors.response.use(
  (response) => {
    if (response.data instanceof Blob) {
      return response.data
    }
    return response.data.is_success && response.data.data
      ? response.data.data
      : response.data
  },
  (error) => {
    // Your Interceptor code to do something with response error
    const response = error?.response || {}
    const { status } = response || {}

    if(status) {
      if(status === 401) {
        store.dispatch('logout')
      } else if(status === 403) {
        router.replace('/dashboard')
      }
    }
    const message = response && response.data?.message ? response.data.message || 'เกิดข้อผิดพลาด' : status || error
    return Promise.reject(message)
  }
)
