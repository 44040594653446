import CmsService from '../services/cms'

const mutations = {
  FETCH_CMS_SITES_REQUEST(state) {
    state.isFetchingCmsSites = true
  },
  FETCH_CMS_SITES_SUCCESS(state, payload) {
    state.isFetchingCmsSites = false
    state.cmsSites = payload
  },
  FETCH_CMS_SITES_FAILURE(state) {
    state.isFetchingCmsSites = false
  },
  FETCH_CMS_SITE_REQUEST(state) {
    state.isFetchingCmsSite = true
  },
  FETCH_CMS_SITE_SUCCESS(state, payload) {
    state.isFetchingCmsSite = false
    state.cmsSite = payload
  },
  FETCH_CMS_SITE_FAILURE(state) {
    state.isFetchingCmsSite = false
  },
  FETCH_ENUM_TYPES_REQUEST(state) {
    state.isFetchingEnumTypes = true
  },
  FETCH_ENUM_TYPES_SUCCESS(state, payload) {
    state.isFetchingEnumTypes = false
    state.cmsEnumTypes = payload
  },
  FETCH_ENUM_TYPES_FAILURE(state) {
    state.isFetchingEnumTypes = false
  },
  FETCH_CMS_SITE_FILES_REQUEST(state) {
    state.isFetchedCmsSiteFiles = false
    state.isFetchingCmsSiteFiles = true
  },
  FETCH_CMS_SITE_FILES_SUCCESS(state, payload) {
    state.isFetchingCmsSiteFiles = false
    state.isFetchedCmsSiteFiles = true
    state.cmsSiteFiles = payload
  },
  FETCH_CMS_SITE_FILES_FAILURE(state) {
    state.isFetchingCmsSiteFiles = false
  },
  UPLOAD_CMS_FILES_REQUEST(state) {
    state.isUploadingCmsFiles = true
  },
  UPLOAD_CMS_FILES_SUCCESS(state) {
    state.isUploadingCmsFiles = false
  },
  UPLOAD_CMS_FILES_FAILURE(state) {
    state.isUploadingCmsFiles = false
  },
  DELETE_CMS_FILE_REQUEST(state) {
    state.isDeletingCmsFile = true
  },
  DELETE_CMS_FILE_SUCCESS(state) {
    state.isDeletingCmsFile = false
  },
  DELETE_CMS_FILE_FAILURE(state) {
    state.isDeletingCmsFile = false
  },
  UPDATE_CMS_SITE_REQUEST(state) {
    state.isUpdatingCmsSite = true
  },
  UPDATE_CMS_SITE_SUCCESS(state) {
    state.isUpdatingCmsSite = false
  },
  UPDATE_CMS_SITE_FAILURE(state) {
    state.isUpdatingCmsSite = false
  },
  FLUSH_CMS_SITES_CACHE_REQUEST(state) {
    state.isFlushingSitesCache = true
  },
  FLUSH_CMS_SITES_CACHE_SUCCESS(state) {
    state.isFlushingSitesCache = false
  },
  FLUSH_CMS_SITES_CACHE_FAILURE(state) {
    state.isFlushingSitesCache = false
  },
  CREATE_CMS_SITE_BANNERS_REQUEST(state) {
    state.isCreatingSiteBanners = true
  },
  CREATE_CMS_SITE_BANNERS_SUCCESS(state) {
    state.isCreatingSiteBanners = false
  },
  CREATE_CMS_SITE_BANNERS_FAILURE(state) {
    state.isCreatingSiteBanners = false
  },
  REMOVE_CMS_SITE_BANNER_REQUEST(state) {
    state.isRemovingCmsSiteBanner = true
  },
  REMOVE_CMS_SITE_BANNER_SUCCESS(state) {
    state.isRemovingCmsSiteBanner = false
  },
  REMOVE_CMS_SITE_BANNER_FAILURE(state) {
    state.isRemovingCmsSiteBanner = false
  },
  CREATE_CMS_SITE_PROMOTIONS_REQUEST(state) {
    state.isCreatingCmsSitePromotions = true
  },
  CREATE_CMS_SITE_PROMOTIONS_SUCCESS(state) {
    state.isCreatingCmsSitePromotions = false
  },
  CREATE_CMS_SITE_PROMOTIONS_FAILURE(state) {
    state.isCreatingCmsSitePromotions = false
  },
  REMOVE_CMS_SITE_PROMOTION_REQUEST(state) {
    state.isRemovingCmsSitePromotion = true
  },
  REMOVE_CMS_SITE_PROMOTION_SUCCESS(state) {
    state.isRemovingCmsSitePromotion = false
  },
  REMOVE_CMS_SITE_PROMOTION_FAILURE(state) {
    state.isRemovingCmsSitePromotion = false
  },
  CREATE_CMS_SITE_REFERRALS_REQUEST(state) {
    state.isCreatingCmsSiteReferrals = true
  },
  CREATE_CMS_SITE_REFERRALS_SUCCESS(state) {
    state.isCreatingCmsSiteReferrals = false
  },
  CREATE_CMS_SITE_REFERRALS_FAILURE(state) {
    state.isCreatingCmsSiteReferrals = false
  },
  REMOVE_CMS_SITE_REFERRAL_REQUEST(state) {
    state.isRemovingCmsSiteReferral = true
  },
  REMOVE_CMS_SITE_REFERRAL_SUCCESS(state) {
    state.isRemovingCmsSiteReferral = false
  },
  REMOVE_CMS_SITE_REFERRAL_FAILURE(state) {
    state.isRemovingCmsSiteReferral = false
  },
  CREATE_CMS_SITE_FLOATING_MENUS_REQUEST(state) {
    state.isCreatingCmsSiteFloatingMenus = true
  },
  CREATE_CMS_SITE_FLOATING_MENUS_SUCCESS(state) {
    state.isCreatingCmsSiteFloatingMenus = false
  },
  CREATE_CMS_SITE_FLOATING_MENUS_FAILURE(state) {
    state.isCreatingCmsSiteFloatingMenus = false
  },
  REMOVE_CMS_SITE_FLOATING_MENU_REQUEST(state) {
    state.isRemovingCmsSiteFloatingMenu = true
  },
  REMOVE_CMS_SITE_FLOATING_MENU_SUCCESS(state) {
    state.isRemovingCmsSiteFloatingMenu = false
  },
  REMOVE_CMS_SITE_FLOATING_MENU_FAILURE(state) {
    state.isRemovingCmsSiteFloatingMenu = false
  },
  CREATE_CMS_SITE_LAYOUT_REQUEST(state) {
    state.isCreatingCmsSiteLayout = true
  },
  CREATE_CMS_SITE_LAYOUT_SUCCESS(state) {
    state.isCreatingCmsSiteLayout = false
  },
  CREATE_CMS_SITE_LAYOUT_FAILURE(state) {
    state.isCreatingCmsSiteLayout = false
  },
  UPDATE_CMS_SITE_LAYOUT_REQUEST(state) {
    state.isUpdatingCmsSiteLayout = true
  },
  UPDATE_CMS_SITE_LAYOUT_SUCCESS(state) {
    state.isUpdatingCmsSiteLayout = false
  },
  UPDATE_CMS_SITE_LAYOUT_FAILURE(state) {
    state.isUpdatingCmsSiteLayout = false
  },
  REMOVE_CMS_SITE_LAYOUT_REQUEST(state) {
    state.isRemovingCmsSiteLayout = true
  },
  REMOVE_CMS_SITE_LAYOUT_SUCCESS(state) {
    state.isRemovingCmsSiteLayout = false
  },
  REMOVE_CMS_SITE_LAYOUT_FAILURE(state) {
    state.isRemovingCmsSiteLayout = false
  },
  CREATE_CMS_SITE_ROW_REQUEST(state) {
    state.isCreatingCmsSiteRow = true
  },
  CREATE_CMS_SITE_ROW_SUCCESS(state) {
    state.isCreatingCmsSiteRow = false
  },
  CREATE_CMS_SITE_ROW_FAILURE(state) {
    state.isCreatingCmsSiteRow = false
  },
  REMOVE_CMS_SITE_ROW_REQUEST(state) {
    state.isRemovingCmsSiteRow = true
  },
  REMOVE_CMS_SITE_ROW_SUCCESS(state) {
    state.isRemovingCmsSiteRow = false
  },
  REMOVE_CMS_SITE_ROW_FAILURE(state) {
    state.isRemovingCmsSiteRow = false
  },
  ENABLE_DEVELOPMENT_REQUEST(state) {
    state.isEnablingDevelopment = true
  },
  ENABLE_DEVELOPMENT_SUCCESS(state) {
    state.isEnablingDevelopment = false
  },
  ENABLE_DEVELOPMENT_FAILURE(state) {
    state.isEnablingDevelopment = false
  },
  MERGE_CHANGE_TO_PROD_REQUEST(state) {
    state.isMerging = true
    state.isMerged = false
  },
  MERGE_CHANGE_TO_PROD_SUCCESS(state) {
    state.isMerging = false
    state.isMerged = true
  },
  MERGE_CHANGE_TO_PROD_FAILURE(state) {
    state.isMerging = false
  },
  DELETE_DEV_SITE_REQUEST(state) {
    state.isDeletingDevSite = true
  },
  DELETE_DEV_SITE_SUCCESS(state) {
    state.isDeletingDevSite = false
  },
  DELETE_DEV_SITE_FAILURE(state) {
    state.isDeletingDevSite = false
  },
  CREATE_CMS_SITE_REQUEST(state) {
    state.isCreatingCmsSite = true
  },
  CREATE_CMS_SITE_SUCCESS(state) {
    state.isCreatingCmsSite = false
  },
  CREATE_CMS_SITE_FAILURE(state) {
    state.isCreatingCmsSite = false
  },
  FETCH_ARTICLES_REQUEST(state) {
    state.isFetchingCmsArticles = true
  },
  FETCH_ARTICLES_SUCCESS(state, payload) {
    state.isFetchingCmsArticles = false
    state.cmsArticles = payload
  },
  FETCH_ARTICLES_FAILURE(state) {
    state.isFetchingCmsArticles = false
  },
  CREATE_ARTICLE_REQUEST(state) {
    state.isCreatingArticle = true
  },
  CREATE_ARTICLE_SUCCESS(state) {
    state.isCreatingArticle = false
  },
  CREATE_ARTICLE_FAILURE(state) {
    state.isCreatingArticle = false
  },
  REMOVE_ARTIClE_REQUEST(state) {
    state.isRemovingArticle = true
  },
  REMOVE_ARTIClE_SUCCESS(state) {
    state.isRemovingArticle = false
  },
  REMOVE_ARTIClE_FAILURE(state) {
    state.isRemovingArticle = false
  },
  CREATE_CMS_SITE_FLOATING_ADS_REQUEST(state) {
    state.isCreatingCmsSiteFloatingAds = true
  },
  CREATE_CMS_SITE_FLOATING_ADS_SUCCESS(state) {
    state.isCreatingCmsSiteFloatingAds = false
  },
  CREATE_CMS_SITE_FLOATING_ADS_FAILURE(state) {
    state.isCreatingCmsSiteFloatingAds = false
  },
  REMOVE_CMS_SITE_FLOATING_ADS_REQUEST(state) {
    state.isRemovingCmsSiteFloatingAds = true
  },
  REMOVE_CMS_SITE_FLOATING_ADS_SUCCESS(state) {
    state.isRemovingCmsSiteFloatingAds = false
  },
  REMOVE_CMS_SITE_FLOATING_ADS_FAILURE(state) {
    state.isRemovingCmsSiteFloatingAds = false
  },
  CREATE_CMS_SITE_GAME_BANNERS_REQUEST(state) {
    state.isCreatingSiteGameBanners = true
  },
  CREATE_CMS_SITE_GAME_BANNERS_SUCCESS(state) {
    state.isCreatingSiteGameBanners = false
  },
  CREATE_CMS_SITE_GAME_BANNERS_FAILURE(state) {
    state.isCreatingSiteGameBanners = false
  },
  REMOVE_CMS_SITE_GAME_BANNER_REQUEST(state) {
    state.isRemovingCmsSiteGameBanner = true
  },
  REMOVE_CMS_SITE_GAME_BANNER_SUCCESS(state) {
    state.isRemovingCmsSiteGameBanner = false
  },
  REMOVE_CMS_SITE_GAME_BANNER_FAILURE(state) {
    state.isRemovingCmsSiteGameBanner = false
  },
  CREATE_CMS_SITE_GAME_COVERS_REQUEST(state) {
    state.isCreatingSiteGameCovers = true
  },
  CREATE_CMS_SITE_GAME_COVERS_SUCCESS(state) {
    state.isCreatingSiteGameCovers = false
  },
  CREATE_CMS_SITE_GAME_COVERS_FAILURE(state) {
    state.isCreatingSiteGameCovers = false
  },
  FETCH_ENUM_TYPES_REMAP_REQUEST(state) {
    state.isFetchingEnumTypesRemap = true
  },
  FETCH_ENUM_TYPES_REMAP_SUCCESS(state, payload) {
    state.isFetchingEnumTypesRemap = false
    state.cmsEnumTypesRemap = payload
  },
  FETCH_ENUM_TYPES_REMAP_FAILURE(state) {
    state.isFetchingEnumTypesRemap = false
  },
  FETCH_ALL_ENUM_TYPES_REQUEST(state) {
    state.isFetchingAllEnumTypes = true
  },
  FETCH_ALL_ENUM_TYPES_SUCCESS(state, payload) {
    state.isFetchingAllEnumTypes = false
    state.cmsAllEnumTypes = payload
  },
  FETCH_ALL_ENUM_TYPES_FAILURE(state) {
    state.isFetchingAllEnumTypes = false
  },
  CREATE_REMAP_TYPES_REQUEST(state) {
    state.isCreatingRemapTypes = true
  },
  CREATE_REMAP_TYPES_SUCCESS(state) {
    state.isCreatingRemapTypes = false
  },
  CREATE_REMAP_TYPES_FAILURE(state) {
    state.isCreatingRemapTypes = false
  },
  FETCH_REMAP_TYPES_GLOBAL_REQUEST(state) {
    state.isFetchingRemapTypesGlobal = true
  },
  FETCH_REMAP_TYPES_GLOBAL_SUCCESS(state, payload) {
    state.isFetchingRemapTypesGlobal = false
    state.cmsEnumTypesRemapGlobal = payload
  },
  FETCH_REMAP_TYPES_GLOBAL_FAILURE(state) {
    state.isFetchingRemapTypesGlobal = false
  },
  CREATE_REMAP_TYPES_GLOBAL_REQUEST(state) {
    state.isCreatingRemapTypesGlobal = true
  },
  CREATE_REMAP_TYPES_GLOBAL_SUCCESS(state) {
    state.isCreatingRemapTypesGlobal = false
  },
  CREATE_REMAP_TYPES_GLOBAL_FAILURE(state) {
    state.isCreatingRemapTypesGlobal = false
  },
  REMOVE_REMAP_TYPES_GLOBAL_REQUEST(state) {
    state.isRemovingRemapTypesGlobal = true
  },
  REMOVE_REMAP_TYPES_GLOBAL_SUCCESS(state) {
    state.isRemovingRemapTypesGlobal = false
    state.cmsEnumTypesRemapGlobal = null
  },
  REMOVE_REMAP_TYPES_GLOBAL_FAILURE(state) {
    state.isRemovingRemapTypesGlobal = false
  },
}

const actions = {
  async fetchEnumTypes({ commit, dispatch }, siteId) {
    try {
      commit('FETCH_ENUM_TYPES_REQUEST')
      const data = await CmsService.fetchEnumTypes(siteId)
      commit('FETCH_ENUM_TYPES_SUCCESS', data)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_ENUM_TYPES_FAILURE')
    }
  },
  async fetchEnumTypesRemap({ commit, dispatch }, siteId) {
    try {
      commit('FETCH_ENUM_TYPES_REMAP_REQUEST')
      const data = await CmsService.fetchEnumTypesRemap(siteId)
      commit('FETCH_ENUM_TYPES_REMAP_SUCCESS', data)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_ENUM_TYPES_REMAP_FAILURE')
    }
  },
  async fetchAllEnumTypes({ commit, dispatch }) {
    try {
      commit('FETCH_ALL_ENUM_TYPES_REQUEST')
      const data = await CmsService.fetchEnumTypes()
      commit('FETCH_ALL_ENUM_TYPES_SUCCESS', data)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_ALL_ENUM_TYPES_FAILURE')
    }
  },
  async fetchCmsSites({ commit, dispatch }) {
    try {
      commit('FETCH_CMS_SITES_REQUEST')
      const data = await CmsService.fetchCmsSites()
      commit('FETCH_CMS_SITES_SUCCESS', data)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_CMS_SITES_FAILURE')
    }
  },
  async fetchCmsSiteById({ commit, dispatch }, siteId) {
    try {
      commit('FETCH_CMS_SITE_REQUEST')
      const data = await CmsService.fetchCmsSiteById(siteId)
      commit('FETCH_CMS_SITE_SUCCESS', data)
      dispatch('fetchCmsArticles')
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_CMS_SITE_FAILURE')
    }
  },
  async fetchCmsSiteFiles({ commit, dispatch }, siteId) {
    try {
      commit('FETCH_CMS_SITE_FILES_REQUEST')
      const data = await CmsService.fetchCmsSiteFiles(siteId)
      commit('FETCH_CMS_SITE_FILES_SUCCESS', data)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_CMS_SITE_FILES_FAILURE')
    }
  },
  async updateCmsSite({ commit, dispatch }, { siteId, data }) {
    try {
      commit('UPDATE_CMS_SITE_REQUEST')
      await CmsService.updateCmsSite(siteId, data)
      commit('UPDATE_CMS_SITE_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_CMS_SITE_FAILURE')
    }
  },
  async uploadCmsFiles({ commit, dispatch }, { siteId, formData }) {
    try {
      commit('UPLOAD_CMS_FILES_REQUEST')
      await CmsService.uploadCmsFiles(siteId, formData)
      commit('UPLOAD_CMS_FILES_SUCCESS')
      dispatch('fetchCmsSiteFiles', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPLOAD_CMS_FILES_FAILURE')
    }
  },
  async removeCmsFile({ commit, dispatch }, { siteId, fileId }) {
    try {
      commit('DELETE_CMS_FILE_REQUEST')
      await CmsService.removeFile(siteId, fileId)
      commit('DELETE_CMS_FILE_SUCCESS')
      dispatch('fetchCmsSiteFiles', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_CMS_FILE_FAILURE')
    }
  },
  async flushSitesCache({ commit, dispatch }) {
    try {
      commit('FLUSH_CMS_SITES_CACHE_REQUEST')
      await CmsService.flushSiteCache()
      dispatch('notifySuccess', 'Cache was flush successfully')
      commit('FLUSH_CMS_SITES_CACHE_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('FLUSH_CMS_SITES_CACHE_FAILURE')
    }
  },
  async createCmsBanners({ commit, dispatch }, { siteId, banners }) {
    try {
      commit('CREATE_CMS_SITE_BANNERS_REQUEST')
      await CmsService.createBanners({
        cmsSiteId: siteId,
        banners,
      })
      commit('CREATE_CMS_SITE_BANNERS_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_BANNERS_FAILURE')
    }
  },
  async removeCmsBanner({ commit, dispatch }, { siteId, bannerId }) {
    try {
      commit('REMOVE_CMS_SITE_BANNER_REQUEST')
      await CmsService.removeBanner(siteId, bannerId)
      commit('REMOVE_CMS_SITE_BANNER_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_CMS_SITE_BANNER_FAILURE')
    }
  },
  async createCmsPromotions({ commit, dispatch }, { siteId, promotions }) {
    try {
      commit('CREATE_CMS_SITE_PROMOTIONS_REQUEST')
      await CmsService.createPromotions({
        cmsSiteId: siteId,
        promotions,
      })
      commit('CREATE_CMS_SITE_PROMOTIONS_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_PROMOTIONS_FAILURE')
    }
  },
  async removeCmsPromotion({ commit, dispatch }, { siteId, promotionId }) {
    try {
      commit('REMOVE_CMS_SITE_PROMOTION_REQUEST')
      await CmsService.removePromotion(siteId, promotionId)
      commit('REMOVE_CMS_SITE_PROMOTION_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_CMS_SITE_PROMOTION_FAILURE')
    }
  },
  async createCmsReferrals({ commit, dispatch }, { siteId, referrals }) {
    try {
      commit('CREATE_CMS_SITE_REFERRALS_REQUEST')
      await CmsService.createReferrals({
        cmsSiteId: siteId,
        referrals,
      })
      commit('CREATE_CMS_SITE_REFERRALS_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_REFERRALS_FAILURE')
    }
  },
  async removeCmsReferral({ commit, dispatch }, { siteId, referralId }) {
    try {
      commit('REMOVE_CMS_SITE_REFERRAL_REQUEST')
      await CmsService.removeReferral(siteId, referralId)
      commit('REMOVE_CMS_SITE_REFERRAL_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_CMS_SITE_REFERRAL_FAILURE')
    }
  },
  async createCmsFloatingMenus(
    { commit, dispatch },
    { siteId, floatingMenus }
  ) {
    try {
      commit('CREATE_CMS_SITE_FLOATING_MENUS_REQUEST')
      await CmsService.createFloatingMenus({
        cmsSiteId: siteId,
        floatingMenus,
      })
      commit('CREATE_CMS_SITE_FLOATING_MENUS_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_FLOATING_MENUS_FAILURE')
    }
  },
  async removeCmsFloatingMenu(
    { commit, dispatch },
    { siteId, floatingMenuId }
  ) {
    try {
      commit('REMOVE_CMS_SITE_FLOATING_MENU_REQUEST')
      await CmsService.removeFloatingMenu(siteId, floatingMenuId)
      commit('REMOVE_CMS_SITE_FLOATING_MENU_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_CMS_SITE_FLOATING_MENU_FAILURE')
    }
  },
  async createCmsLayout({ commit, dispatch }, { siteId, data }) {
    try {
      commit('CREATE_CMS_SITE_LAYOUT_REQUEST')
      await CmsService.createLayout({ cmsSiteId: siteId, ...data })
      commit('CREATE_CMS_SITE_LAYOUT_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_LAYOUT_FAILURE')
    }
  },
  async updateCmsLayout({ commit, dispatch }, { siteId, layoutId, data }) {
    try {
      commit('UPDATE_CMS_SITE_LAYOUT_REQUEST')
      await CmsService.updateLayout(siteId, layoutId, data)
      commit('UPDATE_CMS_SITE_LAYOUT_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_CMS_SITE_LAYOUT_FAILURE')
    }
  },
  async removeCmsLayout({ commit, dispatch }, { siteId, layoutId }) {
    try {
      commit('REMOVE_CMS_SITE_LAYOUT_REQUEST')
      await CmsService.removeLayout(siteId, layoutId)
      commit('REMOVE_CMS_SITE_LAYOUT_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_CMS_SITE_LAYOUT_FAILURE')
    }
  },
  async createCmsRow({ commit, dispatch }, { siteId, layoutId, data }) {
    try {
      commit('CREATE_CMS_SITE_ROW_REQUEST')
      await CmsService.createLayoutRow({
        cmsSiteId: siteId,
        cmsLayoutId: layoutId,
        ...data,
      })
      commit('CREATE_CMS_SITE_ROW_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_ROW_FAILURE')
    }
  },
  async removeCmsRow({ commit, dispatch }, { siteId, layoutId, rowId }) {
    try {
      commit('REMOVE_CMS_SITE_ROW_REQUEST')
      await CmsService.removeLayoutRow(siteId, layoutId, rowId)
      commit('REMOVE_CMS_SITE_ROW_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_CMS_SITE_ROW_FAILURE')
    }
  },
  async enableDevelopment({ commit, dispatch }, siteId) {
    try {
      commit('ENABLE_DEVELOPMENT_REQUEST')
      await CmsService.enableDevelopment(siteId)
      commit('ENABLE_DEVELOPMENT_SUCCESS')
      dispatch('fetchCmsSites')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ENABLE_DEVELOPMENT_FAILURE')
    }
  },
  async goProd({ commit, dispatch }, siteId) {
    try {
      commit('MERGE_CHANGE_TO_PROD_REQUEST')
      await CmsService.goProd(siteId)
      commit('MERGE_CHANGE_TO_PROD_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('MERGE_CHANGE_TO_PROD_FAILURE')
    }
  },
  async deleteDevSite({ commit, dispatch }, siteId) {
    try {
      commit('DELETE_DEV_SITE_REQUEST')
      await CmsService.deleteDevSite(siteId)
      dispatch('fetchCmsSites')
      commit('DELETE_DEV_SITE_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_DEV_SITE_FAILURE')
    }
  },
  async createCmsSite({ commit, dispatch }, data) {
    try {
      commit('CREATE_CMS_SITE_REQUEST')
      await CmsService.createCmsSite(data)
      dispatch('fetchCmsSites')
      commit('CREATE_CMS_SITE_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_FAILURE')
    }
  },
  async fetchCmsArticles({ commit, dispatch, getters }) {
    try {
      commit('FETCH_ARTICLES_REQUEST')
      const data = await CmsService.fetchArticles(getters.siteId)
      commit('FETCH_ARTICLES_SUCCESS', data)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_ARTICLES_FAILURE')
    }
  },
  async createCmsArticle({ commit, dispatch }, data) {
    try {
      commit('CREATE_ARTICLE_REQUEST')
      await CmsService.createArticle(data)
      commit('CREATE_ARTICLE_SUCCESS')
      dispatch('fetchCmsArticles')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_ARTICLE_FAILURE')
    }
  },
  async removeCmsArticle({ commit, dispatch }, { siteId, articleId }) {
    try {
      commit('REMOVE_ARTIClE_REQUEST')
      await CmsService.removeArticle(siteId, articleId)
      commit('REMOVE_ARTIClE_SUCCESS')
      dispatch('fetchCmsArticles')
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_ARTIClE_FAILURE')
    }
  },
  async createCmsFloatingAds({ commit, dispatch }, { siteId, floatingAds }) {
    try {
      commit('CREATE_CMS_SITE_FLOATING_ADS_REQUEST')
      await CmsService.createFloatingAds({
        cmsSiteId: siteId,
        floatingAds,
      })
      commit('CREATE_CMS_SITE_FLOATING_ADS_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_FLOATING_ADS_FAILURE')
    }
  },
  async removeCmsFloatingAds({ commit, dispatch }, { siteId, floatingAdsId }) {
    try {
      commit('REMOVE_CMS_SITE_FLOATING_ADS_REQUEST')
      await CmsService.removeFloatingAds(siteId, floatingAdsId)
      commit('REMOVE_CMS_SITE_FLOATING_ADS_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_CMS_SITE_FLOATING_ADS_FAILURE')
    }
  },
  async createCmsGameBanners({ commit, dispatch }, { siteId, gameBanners }) {
    try {
      commit('CREATE_CMS_SITE_GAME_BANNERS_REQUEST')
      await CmsService.createGameBanners({
        cmsSiteId: siteId,
        gameBanners,
      })
      commit('CREATE_CMS_SITE_GAME_BANNERS_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_GAME_BANNERS_FAILURE')
    }
  },
  async removeCmsGameBanner({ commit, dispatch }, { siteId, gameBannerId }) {
    try {
      commit('REMOVE_CMS_SITE_GAME_BANNER_REQUEST')
      await CmsService.removeGameBanner(siteId, gameBannerId)
      commit('REMOVE_CMS_SITE_GAME_BANNER_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_CMS_SITE_GAME_BANNER_FAILURE')
    }
  },
  async createCmsGameCovers({ commit, dispatch }, { siteId, games }) {
    try {
      commit('CREATE_CMS_SITE_GAME_COVERS_REQUEST')
      await CmsService.createGameCovers({
        cmsSiteId: siteId,
        games,
      })
      commit('CREATE_CMS_SITE_GAME_COVERS_SUCCESS')
      dispatch('fetchCmsSiteById', siteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_CMS_SITE_GAME_COVERS_FAILURE')
    }
  },
  async createRemapTypes({ commit, dispatch }, data) {
    try {
      commit('CREATE_REMAP_TYPES_REQUEST')
      await CmsService.createRemapTypes(data)
      commit('CREATE_REMAP_TYPES_SUCCESS')
      dispatch('fetchEnumTypesRemap', data.cmsSiteId)
      dispatch('fetchEnumTypes', data.cmsSiteId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_REMAP_TYPES_FAILURE')
    }
  },
  async fetchRemapTypesGlobal({ commit, dispatch }) {
    try {
      commit('FETCH_REMAP_TYPES_GLOBAL_REQUEST')
      const response = await CmsService.fetchRemapTypesGlobal()
      if (response.data === null) {
        commit('FETCH_REMAP_TYPES_GLOBAL_SUCCESS', null)
      } else {
        commit('FETCH_REMAP_TYPES_GLOBAL_SUCCESS', response)
      }
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_REMAP_TYPES_GLOBAL_FAILURE')
    }
  },
  async createRemapTypesGlobal({ commit, dispatch }, data) {
    try {
      commit('CREATE_REMAP_TYPES_GLOBAL_REQUEST')
      await CmsService.createRemapTypesGlobal(data)
      commit('CREATE_REMAP_TYPES_GLOBAL_SUCCESS')
      dispatch('fetchRemapTypesGlobal')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_REMAP_TYPES_GLOBAL_FAILURE')
    }
  },
  async removeRemapTypesGlobal({ commit, dispatch }) {
    try {
      commit('REMOVE_REMAP_TYPES_GLOBAL_REQUEST')
      await CmsService.deleteRemapTypesGlobal()
      commit('REMOVE_REMAP_TYPES_GLOBAL_SUCCESS')
      dispatch('fetchRemapTypesGlobal')
    } catch (e) {
      dispatch('notifyError', e)
      commit('REMOVE_REMAP_TYPES_GLOBAL_FAILURE')
    }
  },
}

const getters = {
  siteId: (state) => state.cmsSite?.id,
  cmsBanners: (state) => state.cmsSite?.banners || [],
  cmsPromotions: (state) => state.cmsSite?.promotions || [],
  cmsReferrals: (state) => state.cmsSite?.referrals || [],
  cmsFloatingMenus: (state) => state.cmsSite?.floatingMenus || [],
  cmsLayouts: (state) => state.cmsSite?.layouts || [],
  cmsFloatingAds: (state) => state.cmsSite?.floatingAds || [],
  cmsGameBanners: (state) => state.cmsSite?.gameBanners || [],
  cmsGameCovers: (state) => state.cmsSite?.gameCovers || [],
  isEnableRemapTypesGlobal: (state) => state.cmsEnumTypesRemapGlobal !== null,
}

const state = {
  isFetchingCmsSites: false,
  cmsSites: [],
  isFetchingCmsSite: false,
  cmsSite: null,
  isUpdatingCmsSite: false,
  isFetchingEnumTypes: false,
  cmsEnumTypes: {
    cards: [],
    floatingMenus: [],
    footers: [],
    navs: [],
    titles: [],
    topMenus: [],
    banners: [],
  },
  isFetchingCmsSiteFiles: false,
  cmsSiteFiles: [],
  isUploadingCmsFiles: false,
  isDeletingCmsFile: false,
  isFlushingSitesCache: false,
  isCreatingSiteBanners: false,
  isRemovingCmsSiteBanner: false,
  isCreatingCmsSitePromotions: false,
  isRemovingCmsSitePromotion: false,
  isCreatingCmsSiteReferrals: false,
  isRemovingCmsSiteReferral: false,
  isCreatingCmsSiteFloatingMenus: false,
  isRemovingCmsSiteFloatingMenu: false,
  isCreatingCmsSiteLayout: false,
  isRemovingCmsSiteLayout: false,
  isCreatingCmsSiteRow: false,
  isRemovingCmsSiteRow: false,
  isEnablingDevelopment: false,
  isMerging: false,
  isMerged: false,
  isDeletingDevSite: false,
  isCreatingCmsSite: false,
  isUpdatingCmsSiteLayout: false,
  isFetchedCmsSiteFiles: false,
  isFetchingCmsArticles: false,
  isCreatingArticle: false,
  cmsArticles: [],
  isRemovingArticle: true,
  isCreatingCmsSiteFloatingAds: false,
  isRemovingCmsSiteFloatingAds: false,
  isCreatingSiteGameBanners: false,
  isRemovingSiteGameBanners: false,
  isFetchingEnumTypesRemap: false,
  cmsEnumTypesRemap: null,
  isFetchingAllEnumTypes: false,
  cmsAllEnumTypes: {
    cards: [],
    floatingMenus: [],
    footers: [],
    navs: [],
    titles: [],
    topMenus: [],
    banners: [],
  },
  isCreatingRemapTypes: false,
  isFetchingRemapTypesGlobal: false,
  cmsEnumTypesRemapGlobal: null,
  isCreatingRemapTypesGlobal: false,
  isRemovingRemapTypesGlobal: false,
}

export default {
  mutations,
  actions,
  getters,
  state,
}
