import BlacklistService from '../services/blacklist'

const mutations = {
  FETCH_BLACKLISTS_REQUEST(state) {
    state.isFetchingBlacklists = true
  },
  FETCH_BLACKLISTS_SUCCESS(state, payload) {
    state.isFetchingBlacklists = false
    state.blacklists = payload
  },
  FETCH_BLACKLISTS_FAILURE(state) {
    state.isFetchingBlacklists = false
  },
  FETCH_BLACKLIST_REQUEST(state) {
    state.isFetchingBlacklist = true
  },
  FETCH_BLACKLIST_SUCCESS(state, payload) {
    state.isFetchingBlacklist = false
    state.blacklistDetail = payload
  },
  FETCH_BLACKLIST_FAILURE(state) {
    state.isFetchingBlacklist = false
  },
  ADD_BLACKLIST_REQUEST(state) {
    state.isAddingBlacklist = true
  },
  ADD_BLACKLIST_SUCCESS(state) {
    state.isAddingBlacklist = false
  },
  ADD_BLACKLIST_FAILURE(state) {
    state.isAddingBlacklist = false
  },
  UPDATE_BLACKLIST_REQUEST(state) {
    state.isUpdatingBlacklist = true
  },
  UPDATE_BLACKLIST_SUCCESS(state) {
    state.isUpdatingBlacklist = false
  },
  UPDATE_BLACKLIST_FAILURE(state) {
    state.isUpdatingBlacklist = false
  },
  DELETE_BLACKLIST_REQUEST(state) {
    state.isDeletingBlacklist = true
  },
  DELETE_BLACKLIST_SUCCESS(state) {
    state.isDeletingBlacklist = false
  },
  DELETE_BLACKLIST_FAILURE(state) {
    state.isDeletingBlacklist = false
  },
}
const actions = {
  async fetchBlacklists({ commit, dispatch }, {limit = '', page = '', search = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_BLACKLISTS_REQUEST')
      const response = await BlacklistService.fetchBlacklists(limit, page, search, masterId, agentId)
      commit('FETCH_BLACKLISTS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BLACKLISTS_FAILURE', e.message)
    }
  },
  async fetchBlacklist({ commit, dispatch }, id) {
    try {
      commit('FETCH_BLACKLIST_REQUEST')
      const result = await BlacklistService.fetchBlacklistById(id)
      commit('FETCH_BLACKLIST_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BLACKLIST_FAILURE', e.message)
    }
  },
  async createBlacklist({ commit, dispatch }, data) {
    try {
      commit('ADD_BLACKLIST_REQUEST')
      await BlacklistService.createBlacklist(data)
      commit('ADD_BLACKLIST_SUCCESS')
      dispatch('notifySuccess', 'added')
      dispatch('fetchBlacklists', {limit: '', page: '', search: '', masterId: '', agentId: ''})
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_BLACKLIST_FAILURE', e.message)
    }
  },
  async updateBlacklist({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_BLACKLIST_REQUEST')
      await BlacklistService.updateBlacklist(id, data)
      commit('UPDATE_BLACKLIST_SUCCESS')
      dispatch('notifySuccess', 'updated')
      dispatch('fetchBlacklists', {limit: '', page: '', search: '', masterId: '', agentId: ''})
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_BLACKLIST_FAILURE', e.message)
    }
  },
  async deleteBlacklist({ commit, dispatch }, id) {
    try {
      commit('DELETE_BLACKLIST_REQUEST')
      await BlacklistService.deleteBlacklist(id)
      commit('DELETE_BLACKLIST_SUCCESS')
      dispatch('fetchBlacklists', {limit: '', page: '', search: '', masterId: '', agentId: ''})
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_BLACKLIST_FAILURE', e.message)
    }
  },
}
const getters = {
  blacklists: (state) => state.blacklists,
  blacklistDetail: (state) => state.blacklistDetail,
}
const state = {
  blacklists: [],
  isFetchingBlacklists: false,

  blacklistDetail: {},
  isFetchingBlacklist: false,
  isAddingBlacklists: false,
  isUpdatingBlacklist: false,
  isDeletingBlacklist: false,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
