<template>
  <div class="dataTables_paginate paging_simple_numbers float-right">
    <ul class="pagination pagination-rounded mb-0 mt-2">
      <b-pagination
        v-model="page"
        :per-page="perPage"
        :total-rows="total"
        first-number
      ></b-pagination>
      <b-form-input v-model="page" style="width: 50px"></b-form-input>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'PaginationInput',
  props: {
    currentPage: {
      type: Number,
      default: null,
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    perPage: {
      type: Number,
      required: true,
      default: 50,
    }
  },
  data() {
    return {
      page: 1,
    }
  },
  watch: {
    page(val) {
      if (val) {
        this.$emit('update', val)
      }
    },
  },
  created() {
    if(this.currentPage){
      this.page = this.currentPage
    }
  },
}
</script>