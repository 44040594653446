import RewardService from '../services/reward'
import UserService from '../services/user'
import router from '../router/index'

const mutations = {
  FETCH_BOXES_REQUEST(state) {
    state.isFetchingBoxes = true
  },
  FETCH_BOXES_SUCCESS(state, payload) {
    state.isFetchingBoxes = false
    state.boxes = payload
  },
  FETCH_BOXES_FAILURE(state) {
    state.isFetchingBoxes = false
  },
  FETCH_BOX_REQUEST(state) {
    state.isFetchingBox = true
  },
  FETCH_BOX_SUCCESS(state, payload) {
    state.isFetchingBox = false
    state.boxDetail = payload
  },
  FETCH_BOX_FAILURE(state) {
    state.isFetchingBox = false
  },
  FETCH_BOX_HISTORY_REQUEST(state) {
    state.isFetchingBoxHistory = true
  },
  FETCH_BOX_HISTORY_SUCCESS(state, payload) {
    state.isFetchingBoxHistory = false
    state.boxHistory = payload
  },
  FETCH_BOX_HISTORY_FAILURE(state) {
    state.isFetchingBoxHistory = false
  },
  CREATE_BOX_REQUEST(state) {
    state.isCreatingBox = true
  },
  CREATE_BOX_SUCCESS(state, payload) {
    state.isCreatingBox = false
    state.boxDetail = payload
  },
  CREATE_BOX_FAILURE(state) {
    state.isCreatingBox = false
  },
  UPDATE_BOX_REQUEST(state) {
    state.isUpdatingBox = true
  },
  UPDATE_BOX_SUCCESS(state) {
    state.isUpdatingBox = false
  },
  UPDATE_BOX_FAILURE(state) {
    state.isUpdatingBox = false
  },
  DELETE_BOX_REQUEST(state) {
    state.isDeletingBox = true
  },
  DELETE_BOX_SUCCESS(state) {
    state.isDeletingBox = false
  },
  DELETE_BOX_FAILURE(state) {
    state.isDeletingBox = false
  },
  ADD_REWARD_BOX_REQUEST(state) {
    state.isAddingRewardBox = true
  },
  ADD_REWARD_BOX_SUCCESS(state, payload) {
    state.isAddingRewardBox = false
    state.boxDetail = payload
  },
  ADD_REWARD_BOX_FAILURE(state) {
    state.isAddingRewardBox = false
  },
  UPDATE_REWARD_BOX_REQUEST(state) {
    state.isUpdatingRewardBox = true
  },
  UPDATE_REWARD_BOX_SUCCESS(state) {
    state.isUpdatingRewardBox = false
  },
  UPDATE_REWARD_BOX_FAILURE(state) {
    state.isUpdatingRewardBox = false
  },
  DELETE_REWARD_BOX_REQUEST(state) {
    state.isDeletingRewardBox = true
  },
  DELETE_REWARD_BOX_SUCCESS(state) {
    state.isDeletingRewardBox = false
  },
  DELETE_REWARD_BOX_FAILURE(state) {
    state.isDeletingRewardBox = false
  },
  FETCH_COUPON_SETTINGS_REQUEST(state) {
    state.isFetchingCouponSettings = true
  },
  FETCH_COUPON_SETTINGS_SUCCESS(state, payload) {
    state.isFetchingCouponSettings = false
    state.couponSettings = payload
  },
  FETCH_COUPON_SETTINGS_FAILURE(state) {
    state.isFetchingCouponSettings = false
  },
  UPDATE_COUPON_SETTINGS_REQUEST(state, id) {
    state.isUpdatingCouponSettings = true
    state.couponId = id
  },
  UPDATE_COUPON_SETTINGS_SUCCESS(state) {
    state.isUpdatingCouponSettings = false
  },
  UPDATE_COUPON_SETTINGS_FAILURE(state) {
    state.isUpdatingCouponSettings = false
  },
  FETCH_COUPON_HISTORY_REQUEST(state) {
    state.isFetchingCouponHistory = true
  },
  FETCH_COUPON_HISTORY_SUCCESS(state, payload) {
    state.isFetchingCouponHistory = false
    state.couponHistory = payload
  },
  FETCH_COUPON_HISTORY_FAILURE(state) {
    state.isFetchingCouponHistory = false
  },
  FETCH_COUPON_EXCHANGE_HISTORY_REQUEST(state) {
    state.isFetchingCouponExchangeHistory = true
  },
  FETCH_COUPON_EXCHANGE_HISTORY_SUCCESS(state, payload) {
    state.isFetchingCouponExchangeHistory = false
    state.couponExchangeHistory = payload
  },
  FETCH_COUPON_EXCHANGE_HISTORY_FAILURE(state) {
    state.isFetchingCouponExchangeHistory = false
  },
  FETCH_REWARD_POINT_SETTINGS_REQUEST(state) {
    state.isFetchingRewardPointSettings = true
  },
  FETCH_REWARD_POINT_SETTINGS_SUCCESS(state, payload) {
    state.isFetchingRewardPointSettings = false
    state.rewardPointSettings = payload
  },
  FETCH_REWARD_POINT_SETTINGS_FAILURE(state) {
    state.isFetchingRewardPointSettings = false
  },
  UPDATE_REWARD_POINT_SETTINGS_REQUEST(state, id) {
    state.isUpdatingRewardPointSettings = true
    state.rewardPointId = id
  },
  UPDATE_REWARD_POINT_SETTINGS_SUCCESS(state, payload) {
    state.isUpdatingRewardPointSettings = false
    state.rewardPointSettings = payload
  },
  UPDATE_REWARD_POINT_SETTINGS_FAILURE(state) {
    state.isUpdatingRewardPointSettings = false
  },
  FETCH_REFILL_PROMOTIONS_REQUEST(state) {
    state.isFetchingRefillPromotions = true
  },
  FETCH_REFILL_PROMOTIONS_SUCCESS(state, payload) {
    state.isFetchingRefillPromotions = false
    state.refillPromotions = payload
  },
  FETCH_REFILL_PROMOTIONS_FAILURE(state, payload) {
    state.isFetchingRefillPromotions = false
    state.isFetchingRefillPromotionsError = payload
  },
  CREATE_REFILL_PROMOTION_REQUEST(state) {
    state.isCreatingRefillPromotion = true
  },
  CREATE_REFILL_PROMOTION_SUCCESS(state) {
    state.isCreatingRefillPromotion = false
  },
  CREATE_REFILL_PROMOTION_FAILURE(state, payload) {
    state.isCreatingRefillPromotion = false
    state.isCreatingRefillPromotionError = payload
  },
  FETCH_REFILL_PROMOTION_REQUEST(state) {
    state.isFetchingRefillPromotion = true
  },
  FETCH_REFILL_PROMOTION_SUCCESS(state, payload) {
    state.isFetchingRefillPromotion = false
    state.refillPromotionDetail = payload
  },
  FETCH_REFILL_PROMOTION_FAILURE(state, payload) {
    state.isFetchingRefillPromotion = false
    state.isFetchingRefillPromotionError = payload
  },
  UPDATE_REFILL_PROMOTION_REQUEST(state) {
    state.isUpdatingRefillPromotion = true
  },
  UPDATE_REFILL_PROMOTION_SUCCESS(state, payload) {
    state.isUpdatingRefillPromotion = false
    state.refillPromotionDetail = payload
  },
  UPDATE_REFILL_PROMOTION_FAILURE(state) {
    state.isUpdatingRefillPromotion = false
    state.isUpdatingRefillPromotionError = ''
  },
}
const actions = {
  async fetchBoxes({ commit, dispatch }, agentId) {
    try {
      commit('FETCH_BOXES_REQUEST')
      const response = await RewardService.fetchBoxes(agentId)
      commit('FETCH_BOXES_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BOXES_FAILURE', e.message)
    }
  },
  async fetchBox({ commit, dispatch }, { agentId, id }) {
    try {
      commit('FETCH_BOX_REQUEST')
      const response = await RewardService.fetchBoxById(agentId, id)
      commit('FETCH_BOX_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BOX_FAILURE', e.message)
    }
  },
  async fetchBoxHistory({ commit, dispatch }, agentId) {
    try {
      commit('FETCH_BOX_HISTORY_REQUEST')
      const response = await RewardService.fetchBoxHistory(agentId)
      commit('FETCH_BOX_HISTORY_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BOX_HISTORY_FAILURE', e.message)
    }
  },
  async createBox({ commit, dispatch }, { agentId, data, file }) {
    try {
      commit('CREATE_BOX_REQUEST')
      if (file) {
        const { url } = await UserService.uploadFile(file)
        if (url) { 
          data.imageURL = url
          const response = await RewardService.createBox(agentId, data)
          commit('CREATE_BOX_SUCCESS', response)
          router.push(`/rewards/detail/${response.id}`)
          dispatch('notifySuccess', 'created')
        }
      } else {
        const response = await RewardService.createBox(agentId, data)
        commit('CREATE_BOX_SUCCESS', response)
        router.push(`/rewards/detail/${response.id}`)
        dispatch('notifySuccess', 'created')
      }
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_BOX_FAILURE', e.message)
    }
  },
  async updateBox({ commit, dispatch }, { agentId, id, data, file }) {
    try {
      commit('UPDATE_BOX_REQUEST')
      if (file) {
        const { url } = await UserService.uploadFile(file)
        if (url) {
          data.imageURL = url
          const response = await RewardService.updateBox(agentId, id, data)
          commit('UPDATE_BOX_SUCCESS', response)
          dispatch('notifySuccess', 'updated')
        }
      } else {
        const response = await RewardService.updateBox(agentId, id, data)
        commit('UPDATE_BOX_SUCCESS', response)
        dispatch('notifySuccess', 'updated')
      }
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_BOX_FAILURE', e.message)
    }
  },
  async toggleBoxStatus({ dispatch }, { agentId, id }) {
    try {
      await RewardService.toggleBoxStatus(agentId, id)
      dispatch('fetchBoxes')
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async deleteBox({ commit, dispatch }, { agentId, id }) {
    try {
      commit('DELETE_BOX_REQUEST')
      const response = await RewardService.deleteBox(agentId, id)
      commit('DELETE_BOX_SUCCESS', response)
      dispatch('fetchBoxes')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_BOX_FAILURE', e.message)
    }
  },
  async addRewardBox({ commit, dispatch }, { agentId, boxId, data }) {
    try {
      commit('ADD_REWARD_BOX_REQUEST')
      const response = await RewardService.createReward(agentId, boxId, data)
      commit('ADD_REWARD_BOX_SUCCESS', response)
      dispatch('notifySuccess', 'created')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_REWARD_BOX_FAILURE', e.message)
    }
  },
  async updateRewardBox({ commit, dispatch }, { agentId, boxId, rewardId , data}) {
    try {
      commit('UPDATE_REWARD_BOX_REQUEST')
      const response = await RewardService.updateReward(
        agentId, boxId, rewardId , data
      )
      commit('UPDATE_REWARD_BOX_SUCCESS', response)
      dispatch('fetchBox', boxId)
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_REWARD_BOX_FAILURE', e.message)
    }
  },
  async deleteRewardBox({ commit, dispatch }, { agentId, boxId, rewardId }) {
    try {
      commit('DELETE_REWARD_BOX_REQUEST')
      const response = await RewardService.deleteReward(
        agentId,
        boxId,
        rewardId
      )
      commit('DELETE_REWARD_BOX_SUCCESS', response)
      dispatch('fetchBox', boxId)
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_REWARD_BOX_FAILURE', e.message)
    }
  },
  async fetchCouponSettings({ commit, dispatch }, agentId) {
    try {
      commit('FETCH_COUPON_SETTINGS_REQUEST')
      const response = await RewardService.fetchCouponSettings(agentId)
      commit('FETCH_COUPON_SETTINGS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_COUPON_SETTINGS_FAILURE', e.message)
    }
  },
  async updateCouponSettings({ commit, dispatch }, { agentId, id, data }) {
    try {
      commit('UPDATE_COUPON_SETTINGS_REQUEST', id)
      await RewardService.updateCouponSettings(agentId, id, data)
      commit('UPDATE_COUPON_SETTINGS_SUCCESS')
      dispatch('fetchCouponSettings')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_COUPON_SETTINGS_FAILURE', e.message)
    }
  },
  async fetchCouponHistory({ commit, dispatch }, agentId) {
    try {
      commit('FETCH_COUPON_HISTORY_REQUEST')
      const response = await RewardService.fetchCouponHistory(agentId)
      commit('FETCH_COUPON_HISTORY_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_COUPON_HISTORY_FAILURE', e.message)
    }
  },
  async fetchCouponExchangeHistory({ commit, dispatch }, agentId) {
    try {
      commit('FETCH_COUPON_EXCHANGE_HISTORY_REQUEST')
      const response = await RewardService.fetchCouponExchangeHistory(agentId)
      commit('FETCH_COUPON_EXCHANGE_HISTORY_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_COUPON_EXCHANGE_HISTORY_FAILURE', e.message)
    }
  },
  async fetchRewardPointSettings({ commit, dispatch }, agentId) {
    try {
      commit('FETCH_REWARD_POINT_SETTINGS_REQUEST')
      const response = await RewardService.fetchRewardPointSettings(agentId)
      commit('FETCH_REWARD_POINT_SETTINGS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_REWARD_POINT_SETTINGS_FAILURE', e.message)
    }
  },
  async updateRewardPointSettings({ commit, dispatch }, { agentId, id, data }) {
    try {
      commit('UPDATE_REWARD_POINT_SETTINGS_REQUEST', id)
      await RewardService.updateRewardPointSettings(agentId, id, data)
      commit('UPDATE_REWARD_POINT_SETTINGS_SUCCESS')
      dispatch('fetchRewardPointSettings')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_REWARD_POINT_SETTINGS_FAILURE', e.message)
    }
  },
  async fetchRefillPromotions({ commit, dispatch }) {
    try {
      commit('FETCH_REFILL_PROMOTIONS_REQUEST')
      const response = await RewardService.fetchRefillPromotions()
      commit('FETCH_REFILL_PROMOTIONS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_REFILL_PROMOTIONS_FAILURE', e.message)
    }
  },
  async createRefillPromotion({ commit, dispatch }, { agentId, data }) {
    try {
      commit('CREATE_REFILL_PROMOTION_REQUEST')
      await RewardService.createRefillPromotion(agentId, data)
      dispatch('notifySuccess', 'สร้างรางวัลเรียบร้อยแล้ว')
      commit('CREATE_REFILL_PROMOTION_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_REFILL_PROMOTION_FAILURE', e.message)
    }
  },
  async fetchRefillPromotionById({ commit, dispatch }, conditionId) {
    try {
      commit('FETCH_REFILL_PROMOTION_REQUEST')
      const response = await RewardService.fetchRefillConditionById(conditionId)
      commit('FETCH_REFILL_PROMOTION_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_REFILL_PROMOTION_FAILURE', e.message)
    }
  },
  async updateRefillPromotion({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_REFILL_PROMOTION_REQUEST')
      const response = await RewardService.updateRefillPromotion(id, data)
      commit('UPDATE_REFILL_PROMOTION_SUCCESS', response)
      dispatch('notifySuccess', 'อัพเดทรางวัลเรียบร้อยแล้ว')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_REFILL_PROMOTION_FAILURE', e.message)
    }
  },
  async toggleRefillPromotionStatus({ dispatch }, id) {
    try {
      await RewardService.toggleRefillPromotionStatus(id)
      dispatch('fetchRefillPromotions')
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
}
const getters = {
  boxes: (state) => state.boxes,
  boxDetail: (state) => state.boxDetail,
  couponSettings: (state) => state.couponSettings,
  rewardPointSettings: (state) => state.rewardPointSettings,
  couponHistory: (state) => state.couponHistory,
  couponExchangeHistory: (state) => state.couponExchangeHistory,
  refillPromotionDetail: (state) => state.refillPromotionDetail,
}
const state = {
  boxes: [],
  isFetchingBoxes: false,

  boxDetail: {},
  isFetchingBox: false,
  isCreatingBox: false,
  isUpdatingBox: false,
  isDeletingBox: false,

  boxHistory: [],
  isFetchingBoxHistory: false,

  isAddingRewardBox: false,
  isDeletingRewardBox: false,
  isUpdatingRewardBox: false,

  couponSettings: [],
  couponId: '',
  isFetchingCouponSettings: false,
  isUpdatingCouponSettings: false,

  couponHistory: [],
  isFetchingCouponHistory: false,
  couponExchangeHistory: [],
  isFetchingCouponExchangeHistory: false,

  rewardPointSettings: [],
  rewardPointId: '',
  isFetchingRewardPointSettings: false,
  isUpdatingRewardPointSettings: false,

  isFetchingRefillPromotions: false,
  isFetchingRefillPromotionsError: '',
  refillPromotions: [],

  isCreatingRefillPromotion: false,
  isCreatingRefillPromotionError: '',

  isFetchingRefillPromotion: false,
  isFetchingRefillPromotionError: false,
  refillPromotionDetail: null,

  isUpdatingRefillPromotion: false,
  isUpdatingRefillPromotionError: '',
}
export default {
  state,
  mutations,
  actions,
  getters,
}
