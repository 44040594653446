<template>
  <div>
    <b-row class="page-title align-items-center">
      <b-col>
        <h4 class="mb-1 mt-0">
          <span v-if="title">
            {{ title }}
          </span>
          <span v-else>
            {{
              $te(`routes.${currentRouteName}`)
                ? $t(`routes.${currentRouteName}`)
                : currentRouteName
            }}
          </span>
        </h4>
        <b-breadcrumb
          v-if="breadcrumbs.length > 0"
          :items="breadcrumbs"
        />
      </b-col>
      <b-col>
        <div class="float-right d-flex mt-1">
          <slot name="right" />
        </div>
      </b-col>
    </b-row>
    <slot name="bottom" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
    breadcrumbs() {
      const { breadCrumbs } = this.$route.meta
      if (breadCrumbs && breadCrumbs.length > 0) {
        return breadCrumbs.map((item) => {
          return {
            text: `${
              this.$te(`routes.${item.name}`)
                ? this.$t(`routes.${item.name}`)
                : item.name
            }`,
            href: item.href,
            active: this.currentRouteName === item.name,
          }
        })
      }
      return []
    },
  },
}
</script>

