/* eslint-disable camelcase */
import Vue from 'vue'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {
  between,
  double,
  email,
  integer,
  length,
  max_value,
  min,
  min_value,
  numeric,
  regex,
  required,
} from 'vee-validate/dist/rules'

// Add a rule.
extend('required', {
  ...required,
  message: 'จำเป็นต้องใส่',
})
extend('email', email)
extend('integer', integer)
extend('numeric', {
  ...numeric,
  message: 'ต้องเป็นตัวเลขเท่านั้น'
})
extend('between', {
  ...between,
  message: (fieldName, params) => `ต้องมีค่าระหว่าง ${params.min} ถึง ${params.max}`
})
extend('length', {
  ...length,
  message: (fieldName, params) => `ต้องมีความยาว ${params.length} ตัวอักษร`
})
extend('min_value', {
  ...min_value,
  message: (fieldName, params) => `ต้องมีค่าอย่างน้อย ${params.min}`,
})
extend('min', {
  ...min,
  message: (fieldName, params) =>
      `ต้องใส่ตัวอักษรอย่างน้อย ${params.length} ตัว`,
})
extend('regex', { ...regex, message: 'ต้องใส่ตัวเลขเท่านั้น' })
extend('phoneNumber', {
  validate: (value) => {
    return /^[0][689]{1}[0-9]{8}$/.test(value);
  },
  message: 'หมายเลขโทรศัพท์ไม่ถูกต้อง (ขึ้นต้นด้วย 06, 08, 09)',
});
extend('uuid', {
  validate: (value) => {
    return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(value);
  },
  message: (fieldName) => `${fieldName} ไม่ถูกต้อง`,
});
extend('double', {
  ...double,
  message: (fieldName, params) =>
    `ต้องเป็นทศนิยม ${params.decimals} ตำแหน่ง เท่านั้น`
})
extend('max_value', {
  ...max_value,
  message: (fieldName, params) => `ต้องมีค่าไม่เกิน ${params.max}`,
})
extend('is', {
  ...max_value,
  message: (fieldName, params) => `ต้องมีค่าไม่เกิน ${params.max}`,
})
extend('unique', {
  validate: (value, params) => {
    return !params.includes(value)
  },
  message: (fieldName) => `${fieldName} ซ้ำกัน`
})

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
