import LottoService from '../services/lotto'
import {LottoGameTypeEnum} from '@src/constants/lotto';

const mutations = {
  FETCH_LOTTO_GAME_LIST_REQUEST(state) {
    state.isFetchingLottoGame = true
  },
  FETCH_LOTTO_GAME_LIST_SUCCESS(state, payload) {
    state.isFetchingLottoGame = false
    state.lottoGameList = payload
  },
  FETCH_LOTTO_GAME_LIST_FAILURE(state) {
    state.isFetchingLottoGame = false
  },
  TOGGLE_STATUS_LOTTO_GAME_ROUNDS_REQUEST(state) {
    state.isToggleStatusLottoGameRounds = true
  },
  TOGGLE_STATUS_LOTTO_GAME_ROUNDS_SUCCESS(state) {
    state.isToggleStatusLottoGameRounds = false
  },
  TOGGLE_STATUS_LOTTO_GAME_ROUNDS_FAILURE(state) {
    state.isToggleStatusLottoGameRounds = false
  },
  FETCH_LOTTO_CONFIG_BY_ID_REQUEST(state) {
    state.isFetchingLottoConfigById = true
  },
  FETCH_LOTTO_CONFIG_BY_ID_SUCCESS(state, payload) {
    state.isFetchingLottoConfigById = false
    state.lottoConfigDetail = payload
  },
  FETCH_LOTTO_CONFIG_BY_ID_FAILURE(state) {
    state.isFetchingLottoConfigById = false
  },
  UPDATE_LOTTO_CONFIG_BY_ID_REQUEST(state) {
    state.isUpdatingLottoConfigById = true
  },
  UPDATE_LOTTO_CONFIG_BY_ID_SUCCESS(state) {
    state.isUpdatingLottoConfigById = false
  },
  UPDATE_LOTTO_CONFIG_BY_ID_FAILURE(state) {
    state.isUpdatingLottoConfigById = false
  },
  UPDATE_LOTTO_GAME_DETAIL_BY_ID_REQUEST(state) {
    state.isUpdatingLottoGameDetailById = true
  },
  UPDATE_LOTTO_GAME_DETAIL_BY_ID_SUCCESS(state) {
    state.isUpdatingLottoGameDetailById = false
  },
  UPDATE_LOTTO_GAME_DETAIL_BY_ID_FAILURe(state) {
    state.isUpdatingLottoGameDetailById = false
  },
  FETCH_LOTTO_GAME_REQUEST(state) {
    state.isFetchingLottoGame = true
  },
  FETCH_LOTTO_GAME_SUCCESS(state, payload) {
    state.isFetchingLottoGame = false
    state.lottoGame = payload
  },
  FETCH_LOTTO_GAME_FAILURE(state) {
    state.isFetchingLottoGame = false
  },
  FETCH_LOTTO_SUMMARY_REQUEST(state) {
    state.isFetchingLottoSummary = true
  },
  FETCH_LOTTO_SUMMARY_SUCCESS(state, payload) {
    state.isFetchingLottoSummary = false
    state.lottoSummary = payload
  },
  FETCH_LOTTO_SUMMARY_FAILURE(state) {
    state.isFetchingLottoSummary = false
  },
  FETCH_LAST_GAME_ROUND_REQUEST(state) {
    state.isFetchingLastGameRound = true
  },
  FETCH_LAST_GAME_ROUND_SUCCESS(state, payload) {
    state.isFetchingLastGameRound = false
    state.lastGameRound = payload
  },
  FETCH_LAST_GAME_ROUND_FAILURE(state) {
    state.isFetchingLastGameRound = false
  },
  CANCEL_ALL_LOTTO_BET_REQUEST(state) {
    state.isCancelingAllLottoBet = true
  },
  CANCEL_ALL_LOTTO_BET_SUCCESS(state) {
    state.isCancelingAllLottoBet = false
  },
  CANCEL_ALL_LOTTO_BET_FAILURE(state) {
    state.isCancelingAllLottoBet = false
  },
  SUBMIT_RESULT_REQUEST(state) {
    state.isSubmittingResult = true
  },
  SUBMIT_RESULT_SUCCESS(state) {
    state.isSubmittingResult = false
  },
  SUBMIT_RESULT_FAILURE(state) {
    state.isSubmittingResult = false
  },
  SUBMIT_OPEN_NEW_ROUND_REQUEST(state) {
    state.isSubmittingOpenNewRound = true
  },
  SUBMIT_OPEN_NEW_ROUND_SUCCESS(state) {
    state.isSubmittingOpenNewRound = false
  },
  SUBMIT_OPEN_NEW_ROUND_FAILURE(state) {
    state.isSubmittingOpenNewRound = false
  },
  UPDATE_YEEKEE_CONFIG_REQUEST(state) {
    state.isUpdatingYeekeeConfig = true
  },
  UPDATE_YEEKEE_CONFIG_SUCCESS(state) {
    state.isUpdatingYeekeeConfig = false
  },
  UPDATE_YEEKEE_CONFIG_FAILURE(state) {
    state.isUpdatingYeekeeConfig = false
  },
  FETCH_LOTTO_USER_BET_LIST_REQUEST(state) {
    state.isFetchingLottoUserBet = true
  },
  FETCH_LOTTO_USER_BET_LIST_SUCCESS(state, payload) {
    state.isFetchingLottoUserBet = false
    state.lottoUserBetList = payload
  },
  FETCH_LOTTO_USER_BET_LIST_FAILURE(state) {
    state.isFetchingLottoUserBet = false
  },
  FETCH_LOTTO_USER_BET_REQUEST(state) {
    state.isFetchingLottoUserBet = true
  },
  FETCH_LOTTO_USER_BET_SUCCESS(state, payload) {
    state.isFetchingLottoUserBet = false
    state.lottoUserBet = payload
  },
  FETCH_LOTTO_USER_BET_FAILURE(state) {
    state.isFetchingLottoUserBet = false
  },
  SET_PAYOUT_RATE_REQUEST(state) {
    state.isSubmitPayoutRate = true
  },
  SET_PAYOUT_RATE_SUCCESS(state) {
    state.isSubmitPayoutRate = false
  },
  SET_PAYOUT_RATE_FAILURE(state) {
    state.isSubmitPayoutRate = false
  },
  UPDATE_LIMIT_NUMBER_REQUEST(state) {
    state.isUpdatingLimitNumber = true
  },
  UPDATE_LIMIT_NUMBER_SUCCESS(state) {
    state.isUpdatingLimitNumber = false
  },
  UPDATE_LIMIT_NUMBER_FAILURE(state) {
    state.isUpdatingLimitNumber = false
  },
  GET_PAYOUT_RATE_REQUEST(state) {
    state.isFetchingPayoutRate = true
  },
  GET_PAYOUT_RATE_SUCCESS(state, payload) {
    state.isFetchingPayoutRate = false
    state.lottoPayoutRate = payload
  },
  GET_PAYOUT_RATE_FAILURE(state) {
    state.isFetchingPayoutRate = false
  },
  GET_LIMIT_NUMBERS_REQUEST(state) {
    state.isFetchingLimitNumbers = true
  },
  GET_LIMIT_NUMBERS_SUCCESS(state, payload) {
    state.isFetchingLimitNumbers = false
    state.lottoLimitNumbers = payload
  },
  GET_LIMIT_NUMBERS_FAILURE(state) {
    state.isFetchingLimitNumbers = false
  },
  SET_PAYOUT_RATE_NUMBER_REQUEST(state) {
    state.isSubmitPayoutRateNumber = true
  },
  SET_PAYOUT_RATE_NUMBER_SUCCESS(state) {
    state.isSubmitPayoutRateNumber = false
  },
  SET_PAYOUT_RATE_NUMBER_FAILURE(state) {
    state.isSubmitPayoutRateNumber = false
  },
  SET_IS_CALCULATED(state, value) {
    state.isCalculated = value
  }
}

const actions = {
  async fetchLottoGameByGameType(
    { commit, dispatch },
    { limit = 20, page = 1, search = '', agentId = '', masterId = '', gameType }
  ) {
    try {
      commit('FETCH_LOTTO_GAME_LIST_REQUEST')
      const response = await LottoService.fetchLottoGameByGameType(
        limit,
        page,
        search,
        agentId,
        masterId,
        gameType
      )
      commit('FETCH_LOTTO_GAME_LIST_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LOTTO_GAME_LIST_FAILURE', e.message)
    }
  },
  async toggleStatusLottoGameRounds({ commit, dispatch }, { id, gameType }) {
    try {
      commit('TOGGLE_STATUS_LOTTO_GAME_ROUNDS_REQUEST')
      await LottoService.toggleStatusLottoGameRounds(id)
      commit('TOGGLE_STATUS_LOTTO_GAME_ROUNDS_SUCCESS')
      dispatch('fetchLottoGameByGameType', { gameType })
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('TOGGLE_STATUS_LOTTO_GAME_ROUNDS_FAILURE', e.message)
    }
  },
  async fetchLottoGameConfigById({ dispatch, commit }, id) {
    try {
      commit('FETCH_LOTTO_CONFIG_BY_ID_REQUEST')
      const response = await LottoService.fetchLottoGameConfigById(id)
      commit('FETCH_LOTTO_CONFIG_BY_ID_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LOTTO_CONFIG_BY_ID_FAILURE', e.message)
    }
  },
  async updateLottoGameConfigById({ dispatch, commit }, { id, configs }) {
    try {
      commit('UPDATE_LOTTO_CONFIG_BY_ID_REQUEST')
      await LottoService.updateLottoGameConfigById(id, configs)
      commit('UPDATE_LOTTO_CONFIG_BY_ID_SUCCESS')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_LOTTO_CONFIG_BY_ID_FAILURE', e.message)
      return false
    }
  },
  async updateLottoGameDetailById({ dispatch, commit }, { id, payload }) {
    try {
      commit('UPDATE_LOTTO_GAME_DETAIL_BY_ID_REQUEST')
      await LottoService.updateLottoGameDetailById(id, payload)
      commit('UPDATE_LOTTO_GAME_DETAIL_BY_ID_SUCCESS')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_LOTTO_GAME_DETAIL_BY_ID_FAILURE', e.message)
      return false
    }
  },
  async fetchLottoGameById({ commit, dispatch }, id) {
    try {
      commit('FETCH_LOTTO_GAME_REQUEST')
      const response = await LottoService.fetchLottoGameById(id)
      commit('FETCH_LOTTO_GAME_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LOTTO_GAME_FAILURE', e.message)
    }
  },
  async getSummaryResultByGameRoundId({ commit, dispatch }, { id, payload }) {
    try {
      commit('FETCH_LOTTO_SUMMARY_REQUEST')
      const response = await LottoService.getSummaryResultByGameRoundId(
        id,
        payload
      )
      commit('FETCH_LOTTO_SUMMARY_SUCCESS', response)
      commit('SET_IS_CALCULATED', true)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LOTTO_SUMMARY_FAILURE', e.message)
      commit('SET_IS_CALCULATED', false)
    }
  },
  resetLottoSummary({ commit }) {
    commit('FETCH_LOTTO_SUMMARY_SUCCESS', {
      betResult: {},
      totalWinAmount: 0,
      totalBetAmount: 0,
      totalProfit: 0,
      affiliateAmount: 0
    })
    commit('SET_IS_CALCULATED', false)
  },
  async fetchLastGameRoundByGameId({ commit, dispatch }, id) {
    try {
      commit('FETCH_LAST_GAME_ROUND_REQUEST')
      const response = await LottoService.fetchLastGameRoundByGameId(id)
      commit('FETCH_LAST_GAME_ROUND_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LAST_GAME_ROUND_FAILURE', e.message)
    }
  },
  async cancelAllLottoBetByGameRoundId({ commit, dispatch }, id) {
    try {
      commit('CANCEL_ALL_LOTTO_BET_REQUEST')
      await LottoService.cancelAllLottoBetByGameRoundId(id)
      commit('CANCEL_ALL_LOTTO_BET_SUCCESS')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CANCEL_ALL_LOTTO_BET_FAILURE', e.message)
    }
  },
  async submitLottoResult({ commit, dispatch }, { id, payload }) {
    try {
      commit('SUBMIT_RESULT_REQUEST')
      await LottoService.submitLottoResult(id, payload)
      commit('SUBMIT_RESULT_SUCCESS')
      dispatch('notifySuccess', 'created')
    } catch (e) {
      dispatch('notifyError', e)
      commit('SUBMIT_RESULT_FAILURE', e.message)
    }
  },
  async submitOpenNewRound({ commit, dispatch }, { id, payload }) {
    try {
      commit('SUBMIT_OPEN_NEW_ROUND_REQUEST')
      await LottoService.submitOpenNewRound(id, payload)
      commit('SUBMIT_OPEN_NEW_ROUND_SUCCESS')
      dispatch('notifySuccess', 'created')
    } catch (e) {
      dispatch('notifyError', e)
      commit('SUBMIT_OPEN_NEW_ROUND_FAILURE', e.message)
    }
  },
  async updateYeekeeConfig({ commit, dispatch }, { lottoGameId, payload }) {
    try {
      commit('UPDATE_YEEKEE_CONFIG_REQUEST')
      await LottoService.updateYeekeeConfig(lottoGameId, payload)
      commit('UPDATE_YEEKEE_CONFIG_SUCCESS')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_YEEKEE_CONFIG_FAILURE', e.message)
      return false
    }
  },
  async fetchLottoUserBetByGameId(
    { commit, dispatch },
    {
      limit = 20,
      page = 1,
      search = '',
      agentId = '',
      masterId = '',
      userId,
      lottoGameRoundId,
      lottoBetStatus,
      gameType,
      gameId,
    }
  ) {
    try {
      commit('FETCH_LOTTO_USER_BET_LIST_REQUEST')
      const response = await LottoService.fetchLottoUserBetByGameId(
        limit,
        page,
        search,
        agentId,
        masterId,
        userId,
        lottoGameRoundId,
        lottoBetStatus,
        gameType,
        gameId
      )
      commit('FETCH_LOTTO_USER_BET_LIST_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LOTTO_USER_BET_LIST_FAILURE', e.message)
    }
  },
  async fetchLottoUserBetByUserBetId({ commit, dispatch }, id) {
    try {
      commit('FETCH_LOTTO_USER_BET_REQUEST')
      const response = await LottoService.fetchLottoUserBetByUserBetId(id)
      commit('FETCH_LOTTO_USER_BET_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LOTTO_USER_BET_FAILURE', e.message)
    }
  },
  async setPayoutRateByGameId({ commit, dispatch }, { lottoGameId, payload }) {
    try {
      commit('SET_PAYOUT_RATE_REQUEST')
      await LottoService.setPayoutRateByGameId(lottoGameId, payload)
      commit('SET_PAYOUT_RATE_SUCCESS')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      commit('SET_PAYOUT_RATE_FAILURE', e.message)
      return false
    }
  },
  async updateLimitNumber({ commit, dispatch }, { lottoGameId, payload }) {
    try {
      commit('UPDATE_LIMIT_NUMBER_REQUEST')
      await LottoService.updateLimitNumber(lottoGameId, payload)
      commit('UPDATE_LIMIT_NUMBER_SUCCESS')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_LIMIT_NUMBER_FAILURE', e.message)
      return false
    }
  },
  async getPayoutRateByLottoGameId({ commit, dispatch }, id) {
    try {
      commit('GET_PAYOUT_RATE_REQUEST')
      const response = await LottoService.getPayoutRateByLottoGameId(id)
      commit('GET_PAYOUT_RATE_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('GET_PAYOUT_RATE_FAILURE', e.message)
    }
  },
  async getLimitNumbersByLottoGameId({ commit, dispatch }, id) {
    try {
      commit('GET_LIMIT_NUMBERS_REQUEST')
      const response = await LottoService.getLimitNumbersByLottoGameId(id)
      commit('GET_LIMIT_NUMBERS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('GET_LIMIT_NUMBERS_FAILURE', e.message)
    }
  },
  async setPayoutRateNumberByGameId({ commit, dispatch }, { id, payload }) {
    try {
      commit('SET_PAYOUT_RATE_NUMBER_REQUEST')
      await LottoService.setPayoutRateNumberByGameId(id, payload)
      commit('SET_PAYOUT_RATE_NUMBER_SUCCESS')
      dispatch('notifySuccess', 'created')
    } catch (e) {
      dispatch('notifyError', e)
      commit('SET_PAYOUT_RATE_NUMBER_FAILURE', e.message)
    }
  },
  async fetchLottoSummary(
    { commit, dispatch },
    { fromDate, toDate, gameType, lottoGameId }
  ) {
    try {
      commit('FETCH_LOTTO_SUMMARY_REQUEST')
      const response = await LottoService.fetchLottoSummary(
        fromDate,
        toDate,
        gameType,
        lottoGameId
      )
      commit('FETCH_LOTTO_SUMMARY_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_LOTTO_SUMMARY_FAILURE', e.message)
    }
  },
}

const compareLottoOrder = (a, b) => {
  if(a.gameType === LottoGameTypeEnum.YEEKEE && b.gameType === LottoGameTypeEnum.YEEKEE) {
    return +a.name.split(' ')[2] > b.name.split(' ')[2] ? 1 : -1
  } else {
    const priority = [
      LottoGameTypeEnum.THAI_GOVERNMENT,
      LottoGameTypeEnum.THAI_BANK,
      LottoGameTypeEnum.THAI_STOCK,
      LottoGameTypeEnum.FOREIGN_LOTTO,
      LottoGameTypeEnum.FOREIGN_STOCK,
      LottoGameTypeEnum.YEEKEE,
    ]
    return priority.indexOf(a.gameType) > priority.indexOf(b.gameType) ? 1 : -1
  }
}

const getters = {
  lottoGameList: (state) => state.lottoGameList,
  lottoGame: (state) => state.lottoGame,
  lottoSummary: (state) => state.lottoSummary,
  lastGameRound: (state) => state.lastGameRound,
  lottoUserBetList: (state) => state.lottoUserBetList,
  lottoUserBet: (state) => state.lottoUserBet,
  lottoPayoutRate: (state) => state.lottoPayoutRate,
  sortedLottoGameList: (state) => [...state.lottoGameList.items].sort(compareLottoOrder)
}

const state = {
  lottoGameList: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  lottoUserBetList: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  lottoUserBet: {},
  isFetchingLottoGame: false,
  isFetchingLottoConfigById: false,
  lottoConfigDetail: {},
  isUpdatingLottoConfigById: false,
  isFetchingLottoSummary: false,
  isFetchingLastGameRound: false,
  isCalculated: false,
  isCancelingAllLottoBet: false,
  isSubmittingResult: false,
  isSubmittingOpenNewRound: false,
  isFetchingLottoUserBet: false,
  isSubmitPayoutRate: false,
  isSubmitPayoutRateNumber: false,
  isUpdatingLimitNumber: false,
  isFetchingPayoutRate: false,
  isFetchingLimitNumbers: false,
  lottoGame: {},
  lastGameRound: {},
  lottoPayoutRate: [],
  lottoLimitNumbers: [],
  lottoSummary: {
    betResult: {},
    totalWinAmount: 0,
    totalBetAmount: 0,
    totalProfit: 0,
    affiliateAmount: 0
  },
  isUpdatingYeekeeConfig: false,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
