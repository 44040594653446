import BrandService from '../services/brand'
import UserService from '../services/user'

const mutations = {
  FETCH_BRANDS_REQUEST(state) {
    state.isFetchingBrands = true
  },
  FETCH_BRANDS_SUCCESS(state, payload) {
    state.isFetchingBrands = false
    if (payload && payload.length > 0) {
      const brand_name = payload.find((prop) => prop.key === 'brand_name')
      const logo = payload.find((prop) => prop.key === 'logo')
      state.brand = {
        brand_name,
        logo,
      }
    }
    state.brands = payload
  },
  FETCH_BRANDS_FAILURE(state) {
    state.isFetchingBrands = false
  },
  ADD_BRAND_REQUEST(state) {
    state.isAddingBrand = true
  },
  ADD_BRAND_SUCCESS(state) {
    state.isAddingBrand = false
  },
  ADD_BRAND_FAILURE(state) {
    state.isAddingBrand = false
  },
  FETCH_SETTING_APP_REQUEST(state) {
    state.isFetchingSettingApp = true
  },
  FETCH_SETTING_APP_SUCCESS(state, payload) {
    state.isFetchingSettingApp = false
    state.settingApp = payload
  },
  FETCH_SETTING_APP_FAILURE(state) {
    state.isFetchingSettingApp = false
  },
  UPDATE_SETTING_APP_REQUEST(state) {
    state.isUpdatingSettingApp = true
  },
  UPDATE_SETTING_APP_SUCCESS(state) {
    state.isUpdatingSettingApp = false
  },
  UPDATE_SETTING_APP_FAILURE(state) {
    state.isUpdatingSettingApp = false
  },
}
const actions = {
  async fetchBrands({ commit, dispatch }) {
    try {
      commit('FETCH_BRANDS_REQUEST')
      const response = await BrandService.fetchBrands()
      commit('FETCH_BRANDS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BRANDS_FAILURE', e.message)
    }
  },
  async createBrand({ commit, dispatch }, { brand_name, file }) {
    try {
      commit('ADD_BRAND_REQUEST')
      const { url } = await UserService.uploadFile(file)
      if (url) {
        const response = await BrandService.createBrand({
          brand_name,
          logo: url,
        })

        commit('ADD_BRAND_SUCCESS', response)
      }
      dispatch('fetchBrands')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_BRAND_FAILURE', e.message)
    }
  },
  async fetchSettingApp({ commit, dispatch }) {
    try {
      commit('FETCH_SETTING_APP_REQUEST')
      const response = await BrandService.fetchSettingApp()
      commit('FETCH_SETTING_APP_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_SETTING_APP_FAILURE', e.message)
    }
  },
  async updateSettingApp({ commit, dispatch }, data) {
    try {
      commit('UPDATE_SETTING_APP_REQUEST')
      await BrandService.updateSettingApp(data)
      commit('UPDATE_SETTING_APP_SUCCESS')
      dispatch('fetchSettingApp')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_SETTING_APP_FAILURE', e.message)
    }
  },
}
const getters = {
  brand: (state) => state.brand,
  brandName: (state) => state.brand.brand_name.value || 'Fukushū-sha',
  brandLogo: (state) =>
    state.brand.logo.value && state.brand.logo.value !== 'null'
      ? state.brand.logo.value
      : null,
}
const state = {
  brand: { brand_name: {}, logo: {} },
  isFetchingBrands: false,
  isAddingBrands: false,

  settingApp: {},
  isFetchingSettingApp: false,
  isUpdatingSettingApp: false,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
