import axios from 'axios'
const userURL = `${process.env.VUE_APP_API_URL}/user`
export default {
  fetchNotificationToken() {
    try {
      return axios.get(`${userURL}/setting/agents/notification_token`)
    } catch (e) {
      throw e
    }
  },
  setNotificationToken(data) {
    try {
      return axios.put(`${userURL}/setting/agents/notification_token`, data)
    } catch (e) {
      throw e
    }
  },
}
