<template>
  <div class="left-side-menu-dark">
    <div class="left-side-menu">
      <div class="sidebar-content mt-4">
        <div
          v-if="isCondensed"
          id="sidebar-menu"
        >
          <AppMenu />
        </div>
        <VuePerfectScrollbar
          v-else
          v-once
          class="slimscroll-menu"
          :settings="settings"
        >
          <div id="sidebar-menu">
            <AppMenu />
          </div>
        </VuePerfectScrollbar>
      </div>
      <!-- Sidebar -left -->
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppMenu from './app-menu.vue'

export default {
  components: {
    VuePerfectScrollbar,
    AppMenu,
  },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    }
  },
}
</script>

<style lang="scss">
.slimscroll-menu {
  height: 100%;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
</style>
