import axios from 'axios'
import {v4 as uuid} from 'uuid'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/user`
const userURL = `${process.env.VUE_APP_API_URL}/user`

export default {
  createPlayer(data) {
    try {
      return axios.post(baseURL, data)
    } catch (e) {
      throw e
    }
  },
  getPlayers(params) {
    try {
      return axios.get(
        baseURL, {
          params
        }
      )
    } catch (e) {
      throw e
    }
  },
  fetchPlayerById(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  updatePlayer(id, data) {
    try {
      return axios.put(`${baseURL}/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  setUserBetLimit(id, data) {
    try {
      return axios.put(`${baseURL}/${id}/bet-limit`, data)
    } catch (e) {
      throw e
    }
  },
  unlockPlayerPromotion(id, removePromotion = false) {
    try {
      return axios.post(`${baseURL}/${id}/unlock-promotion`, {removePromotion})
    } catch (e) {
      throw e
    }
  },
  exchangePlayerWallet(id) {
    try {
      return axios.post(`${baseURL}/${id}/exchange-wallet`)
    } catch (e) {
      throw e
    }
  },
  exportPlayers(params) {
    try {
      return axios.request({
          method: 'POST',
          url: `${baseURL}/export`,
          responseType: 'blob',
          params
        })
        .then((response) => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'รายชื่อผู้เล่น.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
    } catch (e) {
      throw e
    }
  },
  banPlayer(id) {
    try {
      return axios.put(`${userURL}/users/${id}/ban`)
    } catch (e) {
      throw e
    }
  },
  deletePlayer(id) {
    try {
      return axios.delete(`${userURL}/users/${id}`)
    } catch (e) {
      throw e
    }
  },
  depositPlayerCredit(id, data) {
    try {
      return axios.post(`${baseURL}/${id}/deposit`, data)
    } catch (e) {
      throw e
    }
  },
  withdrawPlayerCredit(id, data) {
    try {
      return axios.post(`${baseURL}/${id}/withdraw`, data)
    } catch (e) {
      throw e
    }
  },
  addFreeCreditPlayer(id) {
    try {
      return axios.post(`${baseURL}/${id}/free-credit`, { trackingId: uuid() })
    } catch (e) {
      throw e
    }
  },
  fetchPlayerAf(id) {
    try {
      return axios.get(`${baseURL}/${id}/affiliate`)
    } catch (e) {
      throw e
    }
  },
  fetchPlayerCashback(id) {
    try {
      return axios.get(`${baseURL}/${id}/cashback`)
    } catch (e) {
      throw e
    }
  },
  fetchPlayerPromotion(id) {
    try {
      return axios.get(`${baseURL}/${id}/promotion`)
    } catch (e) {
      throw e
    }
  },
  fetchPlayerAfMembers(id, currentPage, limit, search = '') {
    try {
      return axios.get(`${baseURL}/${id}/af-member`, {
        params: {
          page: currentPage,
          limit: limit,
          search
        }
      })
    } catch (e) {
      throw e
    }
  },
  fetchLatestPromotion(id) {
    try {
      return axios.get(
        `${baseURL}/${id}/latest-promotion`
      )
    } catch (e) {
      throw e
    }
  },
  restorePlayerTurn(id) {
    try {
      return axios.post(
        `${baseURL}/${id}/restore-promotion`
      )
    } catch (e) {
      throw e
    }
  },
}
