import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}`
const userURL = `${process.env.VUE_APP_API_URL}/user`

export default {
  // รายงานเครดิตผู้เล่น
  fetchAllCreditLogs(limit, page, search, masterId, gameId, agentId, userAgentId, type, date) {
    try {
      return axios.get(`${baseURL}/credit-log`, {
        params: {
          limit, page, search, masterId, gameId, agentId, userAgentId, type, date
        },
      })
    } catch (e) {
      throw e
    }
  },
  // รายงานเครดิตเอเยนต์
  fetchAgentCreditLogs(limit, page, search, masterId, agentId, userAgentId, type, from, to) {
    try {
      return axios.get(`${baseURL}/agent-credit-log?limit=${limit}&page=${page}&search=${search}&masterId=${masterId}&agentId=${agentId}&userAgentId=${userAgentId}&type=${type}&from=${from}&to=${to}`)
    } catch (e) {
      throw e
    }
  },
  fetchMasterOrAgentCreditHistory(agentId, limit = 50, offset = 0) {
    try {
      return axios.get(`${userURL}/admins/logs/credit-logs`, {
        params: {
          limit,
          offset,
          agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchPlayersCreditHistory(
    limit,
    offset,
    { code_name, username, firstname, lastname, type }
  ) {
    try {
      return axios.get(`${userURL}/admins/logs/credit_requests`, {
        params: {
          limit,
          offset,
          code_name,
          username,
          firstname,
          lastname,
          type,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchCreditHistoryByPlayerId(userId, limit, offset, type = 'all') {
    try {
      return axios.get(
        `${userURL}/admins/logs/credit-logs/user/${userId}?limit=${limit}&offset=${offset}&type=${type}`
      )
      // return axios.get(
      //   `${userURL}/admins/logs/credit_requests?user_id=${userId}&limit=${limit}&offset=${offset}`
      // )
    } catch (e) {
      throw e
    }
  },
  fetchUnMatchHistory(limit, offset, agentId, channel = '') {
    try {
      return axios.get(`${userURL}/admins/logs/unmatch-txn`, {
        params: {
          limit,
          offset,
          agentId,
          channel
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchUnMatchHistoryById(id) {
    try {
      return axios.get(`${userURL}/admins/logs/unmatch-txn/${id}`)
    } catch (e) {
      throw e
    }
  },
  deleteUnMatchHistoryById(id) {
    try {
      return axios.delete(`${userURL}/admins/logs/unmatch-txn/${id}`)
    } catch (e) {
      throw e
    }
  },
  matchUnMatchHistory(id, userId) {
    try {
      return axios.put(`${userURL}/admins/logs/unmatch-txn/${id}`, {
        userId: userId,
      })
    } catch (e) {
      throw e
    }
  },
  matchUnMatchHistoryByCodeName(id, codeName) {
    try {
      return axios.put(`${userURL}/admins/logs/unmatch-txn-code-name/${id}`, {
        codeName: codeName,
      })
    } catch (e) {
      throw e
    }
  },
  fetchWithdrawRequests() {
    try {
      return axios.get(`${userURL}/admins/withdraw_requests`)
    } catch (e) {
      throw e
    }
  },
  directDepositAgentCredit(agentId, data) {
    try {
      return axios.put(
        `${userURL}/admins/agents/${agentId}/credits/direct_deposit`,
        data
      )
    } catch (e) {
      throw e
    }
  },
  directWithdrawAgentCredit(agentId, data) {
    try {
      return axios.put(
        `${userURL}/admins/agents/${agentId}/credits/direct_withdraw`,
        data
      )
    } catch (e) {
      throw e
    }
  },
  uploadWithdrawFile(agentId, file) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.post(
        `${userURL}/admins/agents/${agentId}/credits/direct_deposit`,
        formData,
        options
      )
    } catch (e) {
      throw e
    }
  },
  acceptPlayerWithdraw(txnId) {
    try {
      return axios.post(`${userURL}/admins/withdraw_requests/${txnId}/approve`)
    } catch (e) {
      throw e
    }
  },
  rejectPlayerWithdraw(txnId, isReturnCredit, detail) {
    try {
      return axios.post(
        `${userURL}/admins/withdraw_requests/${txnId}/reject/${isReturnCredit}`,
        { detail }
      )
    } catch (e) {
      throw e
    }
  },
  manualSetStatusToSuccess(txnId) {
    try {
      return axios.put(
        `${userURL}/admins/withdraw_requests/${txnId}/manual_status_to_success`,
      )
    } catch (e) {
      throw e
    }
  },
}
