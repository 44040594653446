import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './stores'
import i18n from './locales'

import './plugins'
import './design/index.scss'
import './utils/mixins'
import './utils/filters'

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    store.dispatch('init')
  },
  render: (h) => h(App),
}).$mount('#app')
store.$app = app
