import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}/game`

export default {
    fetchBotSettings() {
      try {
        return axios.get(`${baseURL}/admins/app-settings`);
      } catch (e) {
        throw e;
      }
    },
    flushQueue() {
      try {
        return axios.delete(`${baseURL}/admins/queue/flush`);
      } catch (e) {
        throw e;
      }
    },
    updateBotSettings(id, data) {
        try {
          return axios.put(`${baseURL}/admins/app-settings/${id}`, data);
        } catch (e) {
          throw e;
        }
      },
  };
  