import Vue from 'vue'
import VueI18n from 'vue-i18n'
import th from './th'
import en from './en'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'th', // set locale
  fallbackLocale: 'en',
  messages: {
    th: {...th},
    en: {...en},
  },
})

export default i18n