import axios from 'axios'
const baseURL = `${process.env.VUE_APP_API_URL}/user`
export default {
  fetchCashbackSettings() {
    try {
      return axios.get(`${baseURL}/cashback/setting`)
    } catch (e) {
      throw e
    }
  },
  fetchCashbackSettingByAgentId(agentId) {
    try {
      return axios.get(`${baseURL}/cashback/setting/${agentId}`)
    } catch (e) {
      throw e
    }
  },
  fetchCashbackSettingById(id) {
    try {
      return axios.get(`${baseURL}/cashback/setting/${id}`)
    } catch (e) {
      throw e
    }
  },
  createCashbackSettings(data) {
    try {
      return axios.post(`${baseURL}/cashback/setting`, data)
    } catch (e) {
      throw e
    }
  },
  updateCashbackSettings(id, data) {
    try {
      return axios.put(`${baseURL}/cashback/setting/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  deleteCashbackSettings(id) {
    try {
      return axios.delete(`${baseURL}/cashback/setting/${id}`)
    } catch (e) {
      throw e
    }
  },
  fetchCashbackTransaction(limit, page) {
    try {
      return axios.get(
        `${baseURL}/cashback/transaction?limit=${limit}&page=${page}`
      )
    } catch (e) {
      throw e
    }
  },
}
