import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}`

const PromotionService = {
  fetchPromotions(limit, page, search, agentId, masterId, gameType) {
    try {
      return axios.get(`${baseURL}/promotion?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}&gameType=${gameType}`)
    } catch (e) {
      throw e
    }
  },
  fetchPromotionById(id) {
    try {
      return axios.get(`${baseURL}/promotion/${id}`)
    } catch (e) {
      throw e
    }
  },
  addPromotion(data) {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key === 'repeatConfig') {
          Object
              .keys(value)
              .forEach((day) => {
                const configs = value[day]
                configs.forEach((config, j) => {
                  formData.append(`${key}[${day}][${j}][startTime]`, config.startTime)
                  formData.append(`${key}[${day}][${j}][endTime]`, config.endTime)
                })
              })
        } else if(Array.isArray(value)){
          value.forEach((prop, i) => {
            for (const [arrKey, arrValue] of Object.entries(prop)) {
              formData.append(`${key}[${i}][${arrKey}]`, arrValue);
            }
          })
        } else {
          formData.append(`${key}`, value);
        }
      }
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.post(`${baseURL}/promotion`, formData, options)
    } catch (e) {
      throw e
    }
  },
  updatePromotion(id, data) {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        /*
        {
          '0': [{ startTime: 'HH:mm', endTime: 'HH:mm' }, { startTime: 'HH:mm', endTime: 'HH:mm' }],
          '1': [{ startTime: 'HH:mm', endTime: 'HH:mm' }],
          '2': [{ startTime: 'HH:mm', endTime: 'HH:mm' }],
        }
         */
        if (key === 'repeatConfig') {
          Object
              .keys(value)
              .forEach((day) => {
                const configs = value[day]
                configs.forEach((config, j) => {
                  formData.append(`${key}[${day}][${j}][startTime]`, config.startTime)
                  formData.append(`${key}[${day}][${j}][endTime]`, config.endTime)
                })
              })
        } else if(Array.isArray(value)){
          value.forEach((prop, i) => {
            for (const [arrKey, arrValue] of Object.entries(prop)) {
              formData.append(`${key}[${i}][${arrKey}]`, arrValue);
            }
          })
        } else {
          formData.append(`${key}`, value);
        }
      }
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.put(`${baseURL}/promotion/${id}`, formData, options)
    } catch (e) {
      throw e
    }
  },
  updatePromotionOrder(id, data) {
    try {
      return axios.put(`${baseURL}/promotion/order/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  deletePromotion(id) {
    try {
      return axios.delete(`${baseURL}/promotion/${id}`)
    } catch (e) {
      throw e
    }
  },
}

export default PromotionService
