<template>
  <!-- Footer Start -->
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          {{currentYear}} © {{ brandName}}. All Rights Reserved. Crafted with
          <i class="uil uil-heart text-danger font-size-12"></i>
          {{`(${appVersion})`}}
        </div>
      </div>
    </div>
  </footer>
  <!-- end Footer -->
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['brandName']),
    appVersion() {
      return process.env.VUE_APP_VERSION
    },
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
