import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/user-agent`
const userURL = `${process.env.VUE_APP_API_URL}/user`

export default {
  getAssistants(limit, page, search, agentId, masterId) {
    try {
      return axios.get(
        `${baseURL}?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`
      )
    } catch (e) {
      throw e
    }
  },
  getAssistantsForInput() {
    try {
      return axios.get(`${baseURL}/assistants`)
    } catch (e) {
      throw e
    }
  },
  fetchAssistantById(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  createAssistant(data) {
    try {
      return axios.post(`${baseURL}`, data)
    } catch (e) {
      throw e
    }
  },
  updateAssistant(id, data) {
    try {
      return axios.put(`${baseURL}/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  setExcludePermissions(id, data) {
    try {
      return axios.put(
        `${userURL}/admins/user_agents/${id}/role/permissions/exclude`,
        data
      )
    } catch (e) {
      throw e
    }
  },
  deleteAssistant(id) {
    try {
      return axios.delete(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  banAssistant(id) {
    try {
      return axios.post(`${baseURL}/${id}/toggle-ban`)
    } catch (e) {
      throw e
    }
  },
  loginAs(id) {
    try {
      return axios.post(`${baseURL}/${id}/login-as`)
    } catch (e) {
      throw e
    }
  },
}
