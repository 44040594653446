import TrackingLinkService from '../services/tracking-link'
import router from '@router';

const mutations = {
  setTrackingLink(state, trackingLink) {
    state.trackingLinkDetail = trackingLink
  },
  setTrackingLinks(state, trackingLinks) {
    state.trackingLinks = trackingLinks
  },
}
const actions = {
  async fetchTrackingLinks(
    { commit, dispatch },
    { limit = '', page = '', search = '', agentId = '', masterId = '' }
  ) {
    try {
      const trackingLinks = await TrackingLinkService.getTrackingLinks(
        limit,
        page,
        search,
        agentId,
        masterId
      )
      commit('setTrackingLinks', trackingLinks)
      return trackingLinks
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async fetchTrackingLink({ commit, dispatch }, id) {
    try {
      const trackingLink = await TrackingLinkService.fetchTrackingLinkById(id)
      commit('setTrackingLink', trackingLink)
      return trackingLink
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async fetchTrackingLinkData({ commit, dispatch }, id) {
    try {
      const trackingLink = await TrackingLinkService.fetchTrackingLinkDataById(id)
      commit('setTrackingLink', trackingLink)
      return trackingLink
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async createTrackingLink({ dispatch }, data) {
    try {
      const trackingLink = await TrackingLinkService.createTrackingLink(data)
      await router.push(`/tracking-link/${trackingLink.id}`)
      dispatch('notifySuccess')
      return trackingLink
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async updateTrackingLink({ dispatch }, { id, data }) {
    try {
      const trackingLink = await TrackingLinkService.updateTrackingLink(id, data)
      dispatch('notifySuccess')
      return trackingLink
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async deleteTrackingLink({ dispatch }, id) {
    try {
      const trackingLink = await TrackingLinkService.deleteTrackingLink(id)
      dispatch('notifySuccess')
      return trackingLink
    } catch (e) {
      dispatch('notifyError', e)
    }
  }
}

const getters = {
  trackingLinks: (state) => state.trackingLinks,
  trackingLinkDetail: (state) => state.trackingLinkDetail,
  'trackingLinkDetail.totalRegister': (state) => state.trackingLinkDetail.detailAgents.reduce((a, b) => +a + b.totalRegister, 0)
}

const state = {
  trackingLinkDetail: null,
  trackingLinks: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
}
export default {
  state,
  mutations,
  actions,
  getters,
}
