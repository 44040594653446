import axios from 'axios'
const baseURL = `${process.env.VUE_APP_NEW_API_URL}`

export default {
  async fetchWhitelists(limit, page, search, masterId, agentId) {
    try {
      return axios.get(
        `${baseURL}/whitelist?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`
      )
    } catch (e) {
      throw e
    }
  },
  async createWhitelist(data) {
    try {
      return axios.post(`${baseURL}/whitelist`, data)
    } catch (e) {
      throw e
    }
  },
  async deleteWhitelist(id) {
    try {
      return axios.delete(`${baseURL}/whitelist/${id}`)
    } catch (e) {
      throw e
    }
  },
}
