import FileManagementService from '../services/file-management'

const mutations = {
    UPLOAD_IMAGE_REQUEST(state) {
        state.isUploadImage = true
    },
    UPLOAD_IMAGE_SUCCESS(state) {
        state.isUploadImage = false
    },
    UPLOAD_IMAGE_FAILURE(state) {
        state.isUploadImage = false
    },
    REMOVE_IMAGE_REQUEST(state) {
        state.isRemoveImage = true
    },
    REMOVE_IMAGE_SUCCESS(state) {
        state.isRemoveImage = false
    },
    REMOVE_IMAGE_FAILURE(state) {
        state.isRemoveImage = false
    }
}
const actions = {
    async uploadFile({ commit, dispatch }, file) {
        try {
            commit('UPLOAD_IMAGE_REQUEST')
            const imageUrl = await FileManagementService.uploadFile(file)
            commit('UPLOAD_IMAGE_SUCCESS')
            dispatch('notifySuccess', 'uploaded')
            return imageUrl
        } catch (e) {
            dispatch('notifyError', e)
            commit('UPLOAD_IMAGE_FAILURE')
        }
    },

    async removeFile({ commit, dispatch }, data) {
        try {
            commit('REMOVE_IMAGE_REQUEST')
            await FileManagementService.removeFile(data)
            commit('REMOVE_IMAGE_SUCCESS')
            dispatch('notifySuccess', 'removed')
        } catch (e) {
            dispatch('notifyError', e)
            commit('REMOVE_IMAGE_FAILURE')
        }
    },
}
const state = {
    isUploadImage: false,
    isRemoveImage: false
}

export default { state, actions, mutations }