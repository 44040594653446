import axios from 'axios'
import UserService from './user'

const baseURL = `${process.env.VUE_APP_API_URL}/reward`

export default {
  fetchBoxes(agentId) {
    try {
      return axios.get(`${baseURL}/admins/boxes`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchBoxById(agentId, id) {
    try {
      return axios.get(`${baseURL}/admins/boxes/${id}`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchBoxHistory(agentId) {
    try {
      return axios.get(`${baseURL}/boxes/history`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  createBox(agentId, data) {
    try {
      return axios.post(`${baseURL}/admins/boxes`, data, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  updateBox(agentId, id, data) {
    try {
      return axios.put(`${baseURL}/admins/boxes/${id}`, data, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  toggleBoxStatus(agentId, id) {
    try {
      return axios.put(`${baseURL}/admins/boxes/${id}/status`, null, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  deleteBox(agentId, id) {
    try {
      return axios.delete(`${baseURL}/boxes/${id}`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  createReward(agentId, boxId, data) {
    try {
      return axios.post(`${baseURL}/admins/boxes/${boxId}/rewards`, data, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  updateReward(agentId, boxId, rewardId, data) {
    try {
      return axios.put(`${baseURL}/boxes/${boxId}/rewards/${rewardId}`, data, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  deleteReward(agentId, boxId, rewardId) {
    try {
      return axios.delete(`${baseURL}/boxes/${boxId}/rewards/${rewardId}`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchCouponSettings(agentId) {
    try {
      return axios.get(`${baseURL}/coupon/setting`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  updateCouponSettings(agentId, id, data) {
    try {
      return axios.put(`${baseURL}/coupon/setting/${id}`, data, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchCouponHistory(agentId) {
    try {
      return axios.get(`${baseURL}/coupon/history`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchCouponExchangeHistory(agentId) {
    try {
      return axios.get(`${baseURL}/coupon/exchange/history`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchRewardPointSettings(agentId) {
    try {
      return axios.get(`${baseURL}/reward-point/setting`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  updateRewardPointSettings(agentId, id, data) {
    try {
      return axios.put(`${baseURL}/reward-point/setting/${id}`, data, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchRefillPromotions(agentId) {
    try {
      return axios.get(`${baseURL}/admins/conditions/refills`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchRefillConditionById(conditionId) {
    try {
      return axios.get(`${baseURL}/admins/conditions/refills/${conditionId}`, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
        },
      })
    } catch (e) {
      throw e
    }
  },
  createRefillPromotion(agentId, data) {
    try {
      return axios.post(`${baseURL}/admins/conditions/refills`, data, {
        headers: {
          authorization: `Bearer ${UserService.getAccessToken()}`,
          'agent-id': agentId,
        },
      })
    } catch (e) {
      throw e
    }
  },
  updateRefillPromotion(conditionId, data) {
    try {
      return axios.put(
        `${baseURL}/admins/conditions/refills/${conditionId}`,
        data,
        {
          headers: {
            authorization: `Bearer ${UserService.getAccessToken()}`,
          },
        }
      )
    } catch (e) {
      throw e
    }
  },
  toggleRefillPromotionStatus(conditionId) {
    try {
      return axios.put(
        `${baseURL}/admins/conditions/refills/${conditionId}/status`,
        null,
        {
          headers: {
            authorization: `Bearer ${UserService.getAccessToken()}`,
          },
        }
      )
    } catch (e) {
      throw e
    }
  },
}
