import axios from 'axios'

const baseURL = `${process.env.VUE_APP_API_URL}/user`

const BrandService = {
  fetchBrands() {
    try {
      return axios.get(`${baseURL}/setting/brand`)
    } catch (e) {
      throw e
    }
  },
  createBrand(data) {
    try {
      return axios.post(`${baseURL}/setting/brand`, data)
    } catch (e) {
      throw e
    }
  },
  fetchSettingApp() {
    try {
      return axios.get(`${baseURL}/setting/app`)
    } catch (e) {
      throw e
    }
  },
  updateSettingApp(data) {
    try {
      return axios.post(`${baseURL}/setting/app`, data)
    } catch (e) {
      throw e
    }
  },
}

export default BrandService
