import router from '../router/index'
import UserService from '../services/user'

const mutations = {
  GET_ME_REQUEST(state) {
    state.isGettingMe = true
  },
  GET_ME_SUCCESS(state, payload) {
    state.isGettingMe = false
    state.isGettingMeError = true
    state.userInfo = payload
  },
  GET_ME_FAILURE(state) {
    state.isGettingMe = false
    state.isGettingMeError = true
  },
  FETCH_USERS_REQUEST(state) {
    state.isFetchingUsers = true
    state.isFetchingUsersError = false
    state.isFetchingUsersSuccess = false
  },
  FETCH_USERS_SUCCESS(state, payload) {
    state.isFetchingUsers = false
    state.isFetchingUsersSuccess = true
    state.users = payload
  },
  FETCH_USERS_FAILURE(state) {
    state.isFetchingUsers = false
    state.isFetchingUsersError = true
  },
  UPDATE_PROFILE_REQUEST(state) {
    state.isUpdatingProfile = true
    state.isUpdatingProfileError = false
    state.isUpdatingProfileSuccess = false
  },
  UPDATE_PROFILE_SUCCESS(state) {
    state.isUpdatingProfile = false
    state.isUpdatingProfileSuccess = true
  },
  UPDATE_PROFILE_FAILURE(state) {
    state.isUpdatingProfile = false
    state.isUpdatingProfileError = true
  },
  RESET_PASSWORD_USER_REQUEST(state) {
    state.isResettingPasswordUser = true
    state.isResettingPasswordUserError = false
    state.isResettingPasswordUserSuccess = false
  },
  RESET_PASSWORD_USER_SUCCESS(state) {
    state.isResettingPasswordUser = false
    state.isResettingPasswordUserSuccess = true
  },
  RESET_PASSWORD_USER_FAILURE(state, payload) {
    state.isResettingPasswordUser = false
    state.isResettingPasswordUserError = payload
  },
  FETCH_BANK_ACCOUNTS_REQUEST(state) {
    state.isFetchingBankAccounts = true
    state.isFetchingBankAccountsError = false
    state.isFetchingBankAccountsSuccess = false
  },
  FETCH_BANK_ACCOUNTS_SUCCESS(state, payload) {
    state.isFetchingBankAccounts = false
    state.isFetchingBankAccountsSuccess = true
    state.userBankAccounts = payload
  },
  FETCH_BANK_ACCOUNTS_FAILURE(state) {
    state.isFetchingBankAccounts = false
    state.isFetchingBankAccountsError = true
  },
  FETCH_BANK_ACCOUNT_REQUEST(state) {
    state.isFetchingBankAccount = true
    state.isFetchingBankAccountError = false
    state.isFetchingBankAccountSuccess = false
  },
  FETCH_BANK_ACCOUNT_SUCCESS(state, payload) {
    state.isFetchingBankAccount = false
    state.isFetchingBankAccountSuccess = true
    state.userBankAccountDetail = payload
  },
  FETCH_BANK_ACCOUNT_FAILURE(state) {
    state.isFetchingBankAccount = false
    state.isFetchingBankAccountError = true
  },
  ADD_BANK_ACCOUNT_REQUEST(state) {
    state.isAddingBankAccount = true
    state.isAddingBankAccountError = false
    state.isAddingBankAccountSuccess = false
  },
  ADD_BANK_ACCOUNT_SUCCESS(state) {
    state.isAddingBankAccount = false
    state.isAddingBankAccountSuccess = true
  },
  ADD_BANK_ACCOUNT_FAILURE(state) {
    state.isAddingBankAccount = false
    state.isAddingBankAccountError = true
  },
  UPDATE_BANK_ACCOUNT_REQUEST(state) {
    state.isUpdatingBankAccount = true
    state.isUpdatingBankAccountError = false
    state.isUpdatingBankAccountSuccess = false
  },
  UPDATE_BANK_ACCOUNT_SUCCESS(state) {
    state.isUpdatingBankAccount = false
    state.isUpdatingBankAccountSuccess = true
  },
  UPDATE_BANK_ACCOUNT_FAILURE(state) {
    state.isUpdatingBankAccount = false
    state.isUpdatingBankAccountError = true
  },
  FETCH_WITHDRAW_SETTINGS_REQUEST(state) {
    state.isFetchingWithdrawSettings = true
    state.isFetchingWithdrawSettingsError = false
    state.isFetchingWithdrawSettingsSuccess = false
  },
  FETCH_WITHDRAW_SETTINGS_SUCCESS(state, payload) {
    state.isFetchingWithdrawSettings = false
    state.isFetchingWithdrawSettingsSuccess = true
    state.withdrawSettings = payload
  },
  FETCH_WITHDRAW_SETTINGS_FAILURE(state) {
    state.isFetchingWithdrawSettings = false
    state.isFetchingWithdrawSettingsError = true
  },
  UPDATE_WITHDRAW_SETTINGS_REQUEST(state) {
    state.isUpdatingWithdrawSettings = true
    state.isUpdatingWithdrawSettingsError = false
    state.isUpdatingWithdrawSettingsSuccess = false
  },
  UPDATE_WITHDRAW_SETTINGS_SUCCESS(state) {
    state.isUpdatingWithdrawSettings = false
    state.isUpdatingWithdrawSettingsSuccess = true
  },
  UPDATE_WITHDRAW_SETTINGS_FAILURE(state) {
    state.isUpdatingWithdrawSettings = false
    state.isUpdatingWithdrawSettingsError = true
  },
  FETCH_DASHBOARD_REQUEST(state) {
    state.isFetchingDashboard = true
  },
  FETCH_DASHBOARD_SUCCESS(state, payload) {
    state.isFetchingDashboard = false
    state.dashboard = { ...state.dashboard, ...payload }
  },
  FETCH_DASHBOARD_FAILURE(state) {
    state.isFetchingDashboard = false
  },
  FETCH_USER_ACCESS_REQUEST(state) {
    state.isFetchingUserAccess = true
  },
  FETCH_USER_ACCESS_SUCCESS(state, payload) {
    state.isFetchingUserAccess = false
    state.userAccess = payload
  },
  FETCH_USER_ACCESS_FAILURE(state) {
    state.isFetchingUserAccess = false
  },
  DELETE_BANK_ACCOUNT_REQUEST(state) {
    state.isDeletingBankAccount = true
    state.isDeletingBankAccountError = ''
  },
  DELETE_BANK_ACCOUNT_SUCCESS(state) {
    state.isDeletingBankAccount = false
  },
  DELETE_BANK_ACCOUNT_FAILURE(state, payload) {
    state.isDeletingBankAccount = false
    state.isDeletingBankAccountError = payload
  },
  CLEAR_BANK_ACCOUNT_FORM(state) {
    state.userBankAccountDetail = {}
  },
  FETCH_SUMMARY_REQUEST(state) {
    state.isFetchingSummary = true
  },
  FETCH_SUMMARY_SUCCESS(state, payload) {
    state.isFetchingSummary = false
    state.summary= payload
  },
  FETCH_SUMMARY_FAILURE(state) {
    state.isFetchingSummary = false
  }
}
const actions = {
  async getMe({ commit, dispatch }) {
    try {
      commit('GET_ME_REQUEST')
      const response = await UserService.getMe()
      commit('GET_ME_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('GET_ME_FAILURE')
    }
  },
  async fetchUsers({ commit }) {
    try {
      commit('FETCH_USERS_REQUEST')
      const response = await UserService.getUsers()
      commit('FETCH_USERS_SUCCESS', response)
    } catch (e) {
      commit('FETCH_USERS_FAILURE', e.message)
    }
  },
  async updateProfile({ commit, dispatch }, data) {
    try {
      commit('UPDATE_PROFILE_REQUEST')
      await UserService.updateProfile(data)
      commit('UPDATE_PROFILE_SUCCESS')
      dispatch('notifySuccess', 'updated')
      dispatch('getMe')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_PROFILE_FAILURE', e.message)
    }
  },
  async resetPasswordUser({ commit, dispatch }, data) {
    try {
      commit('RESET_PASSWORD_USER_REQUEST')
      await UserService.resetPassword(data)
      commit('RESET_PASSWORD_USER_SUCCESS')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('RESET_PASSWORD_USER_FAILURE', e.message)
    }
  },
  async fetchBankAccounts({ commit, dispatch }, {limit = '', page = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_BANK_ACCOUNTS_REQUEST')
      const result = await UserService.fetchBankAccounts(limit, page, masterId, agentId)
      commit('FETCH_BANK_ACCOUNTS_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BANK_ACCOUNTS_FAILURE', e.message)
    }
  },
  async fetchBankAccount({ commit, dispatch }, id) {
    try {
      commit('FETCH_BANK_ACCOUNT_REQUEST')
      const result = await UserService.fetchBankAccountById(id)
      commit('FETCH_BANK_ACCOUNT_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BANK_ACCOUNT_FAILURE', e.message)
    }
  },
  async addBankAccount({ commit, dispatch }, data) {
    try {
      commit('ADD_BANK_ACCOUNT_REQUEST')
      const { id } = await UserService.addBankAccount(data)
      commit('ADD_BANK_ACCOUNT_SUCCESS')
      dispatch('notifySuccess', 'created')
      await router.push(`/bank-accounts/detail/${id}`)
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_BANK_ACCOUNT_FAILURE', e.message)
    }
  },
  async updateBankAccount({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_BANK_ACCOUNT_REQUEST')
      const { id: newId } = await UserService.updateBankAccount(id, data)
      commit('UPDATE_BANK_ACCOUNT_SUCCESS')
      if(id !== newId) {
        await router.push(`/bank-accounts/detail/${newId}`)
        dispatch('fetchBankAccount', newId)
      }
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_BANK_ACCOUNT_FAILURE', e.message)
    }
  },
  async deleteBankAccount({ commit, dispatch }, id) {
    try {
      commit('DELETE_BANK_ACCOUNT_REQUEST')
      await UserService.deleteBankAccount(id)
      commit('DELETE_BANK_ACCOUNT_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_BANK_ACCOUNT_FAILURE', e.message)
    }
  },
  async fetchWithdrawSettings({ commit, dispatch }) {
    try {
      commit('FETCH_WITHDRAW_SETTINGS_REQUEST')
      const result = await UserService.fetchWithdrawSettings()
      commit('FETCH_WITHDRAW_SETTINGS_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_WITHDRAW_SETTINGS_FAILURE', e.message)
    }
  },
  async updateWithdrawSettings({ commit, dispatch }, data) {
    try {
      commit('UPDATE_WITHDRAW_SETTINGS_REQUEST')
      await UserService.withdrawSettings(data)
      commit('UPDATE_WITHDRAW_SETTINGS_SUCCESS')
      dispatch('fetchWithdrawSettings')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_WITHDRAW_SETTINGS_FAILURE', e.message)
    }
  },
  async fetchDashboard({ commit }, { date, masterId, agentId }) {
    try {
      commit('FETCH_DASHBOARD_REQUEST')
      const result = await UserService.fetchDashboard(date, masterId, agentId)
      commit('FETCH_DASHBOARD_SUCCESS', result)
    } catch (e) {
      commit('FETCH_DASHBOARD_FAILURE', e.message)
    }
  },
  async fetchUserAccess(
    { commit, dispatch },
    { limit = '', page = '', search = '', masterId = '', agentId = '' }
  ) {
    try {
      commit('FETCH_USER_ACCESS_REQUEST')
      const response = await UserService.fetchUserAccess(
        limit,
        page,
        search,
        masterId,
        agentId,
      )
      commit('FETCH_USER_ACCESS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_USER_ACCESS_FAILURE', e.message)
    }
  },
  async clearBankAccountForm({ commit }) {
    commit('CLEAR_BANK_ACCOUNT_FORM')
  },
  async fetchSummary({ commit }, { search, masterId, agentId, from, to }) {
    try {
      commit('FETCH_SUMMARY_REQUEST')
      const result = await UserService.fetchSummary(search, masterId, agentId, from, to)
      commit('FETCH_SUMMARY_SUCCESS', result)
    } catch (e) {
      commit('FETCH_SUMMARY_FAILURE', e.message)
    }
  }
}

const getters = {
  users: (state) => state.users,
  userInfo: (state) => state.userInfo,
  // available roles ['MASTER', 'AGENT']
  // affects > menu
  adminRole: (state) => state.userInfo.roleName,
  adminPermissions: (state) => state.userInfo.permissions,
  debugPermissions: (state) => state.userInfo.permissions.map((perm) => perm.key),
  isOwner: (state) => state.userInfo.roleName === 'OWNER',
  isMaster: (state) => state.userInfo.roleName === 'MASTER',
  isAgent: (state) => state.userInfo.roleName === 'AGENT',
  userBankAccounts: (state) => state.userBankAccounts,
  userBankAccountDetail: (state) => state.userBankAccountDetail,
  withdrawSettings: (state) => state.withdrawSettings,
  dashboard: (state) => state.dashboard,
  userAccess: (state) => state.userAccess,
  summary: (state) => state.summary,
}

const RegisterFromEnum = {
  LINE       : 'line',
  GOOGLE     : 'google',
  FACEBOOK   : 'facebook',
  FRIEND     : 'friend',
  ADS_BANNER : 'ads_banner',
  OTHER      : 'other'
}

const PromotionTypeEnum = {
  FREE_CREDIT: 0,
  FIRST_DEPOSIT: 1,
  DAILY_DEPOSIT: 2,
  ALL_TIME: 3,
  SPECIAL_TIME: 4,
  RANDOM_WHEEL: 5,
  RANDOM_CARD: 6,
  CHECKIN: 7,
  WELCOME_BACK: 8,
  AFFILIATE: 9,
  BONUS: 10,
  CASHBACK: 11
}

const DepositStatusEnum = {
  SUCCESS: 0,
  MANUAL: 1,
  UN_MATCH: 2,
  DISCARD: 3,
  PULL_BACK: 4,
  FIRST_DEPOSIT: 'first_deposit',
}

const WithdrawStatusEnum = {
  PENDING: 0,
  HIDE: 1,
  REJECT: 2,
  SUCCESS: 3,
  MANUAL: 4
}

const state = {
  userInfo: { username: '', permissions: [], roleName: '', credit: 0, masters: [], agentDetail: {} },
  isGettingMe: false,
  isGettingMeError: false,

  DepositStatusEnum,
  WithdrawStatusEnum,
  PromotionTypeEnum,
  RegisterFromEnum,

  dashboard: {
    total: {
      register_count: 0,
      register_from: {
        [RegisterFromEnum.LINE]: 0,
        [RegisterFromEnum.GOOGLE]: 0,
        [RegisterFromEnum.FACEBOOK]: 0,
        [RegisterFromEnum.FRIEND]: 0,
        [RegisterFromEnum.ADS_BANNER]: 0,
        [RegisterFromEnum.OTHER]: 0,
      },
      promotion: {
        [PromotionTypeEnum.FREE_CREDIT]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.FIRST_DEPOSIT]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.DAILY_DEPOSIT]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.ALL_TIME]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.SPECIAL_TIME]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.RANDOM_WHEEL]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.RANDOM_CARD]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.CHECKIN]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.WELCOME_BACK]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        }
      },
      deposit: {
        [DepositStatusEnum.SUCCESS]: 0,
        [DepositStatusEnum.MANUAL]: 0,
        [DepositStatusEnum.UN_MATCH]: 0,
        [DepositStatusEnum.DISCARD]: 0,
        [DepositStatusEnum.PULL_BACK]: 0,
        [DepositStatusEnum.FIRST_DEPOSIT]: 0,
      },
      withdraw: {
        [WithdrawStatusEnum.PENDING]: 0,
        [WithdrawStatusEnum.HIDE]: 0,
        [WithdrawStatusEnum.REJECT]: 0,
        [WithdrawStatusEnum.SUCCESS]: 0,
        [WithdrawStatusEnum.MANUAL]: 0,
      },
    },
    today: {
      register_count: 0,
      register_from: {
        [RegisterFromEnum.LINE]: 0,
        [RegisterFromEnum.GOOGLE]: 0,
        [RegisterFromEnum.FACEBOOK]: 0,
        [RegisterFromEnum.FRIEND]: 0,
        [RegisterFromEnum.ADS_BANNER]: 0,
        [RegisterFromEnum.OTHER]: 0,
      },
      promotion: {
        [PromotionTypeEnum.FREE_CREDIT]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.FIRST_DEPOSIT]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.DAILY_DEPOSIT]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.ALL_TIME]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.SPECIAL_TIME]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.RANDOM_WHEEL]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.RANDOM_CARD]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.CHECKIN]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        },
        [PromotionTypeEnum.WELCOME_BACK]: {
          user_count: 0,
          bonus_amount: 0,
          deposit_amount: 0,
          withdraw_amount: 0
        }
      },
      deposit: {
        [DepositStatusEnum.SUCCESS]: 0,
        [DepositStatusEnum.MANUAL]: 0,
        [DepositStatusEnum.UN_MATCH]: 0,
        [DepositStatusEnum.DISCARD]: 0,
        [DepositStatusEnum.PULL_BACK]: 0,
        [DepositStatusEnum.FIRST_DEPOSIT]: 0,
      },
      withdraw: {
        [WithdrawStatusEnum.PENDING]: 0,
        [WithdrawStatusEnum.HIDE]: 0,
        [WithdrawStatusEnum.REJECT]: 0,
        [WithdrawStatusEnum.SUCCESS]: 0,
        [WithdrawStatusEnum.MANUAL]: 0,
      },
    },
  },
  isFetchingDashboard: false,

  users: [],
  isFetchingUsers: false,
  isFetchingUsersError: false,
  isFetchingUsersSuccess: false,

  isUpdatingProfile: false,
  isUpdatingProfileError: false,
  isUpdatingProfileSuccess: false,

  isResettingPasswordUser: false,
  isResettingPasswordUserError: '',
  isResettingPasswordUserSuccess: false,

  userBankAccounts: { data: [], total: 0, next: 0, previous: 0 },
  isFetchingBankAccounts: false,
  isFetchingBankAccountsError: false,
  isFetchingBankAccountsSuccess: false,

  userBankAccountDetail: {},
  isFetchingBankAccount: false,
  isFetchingBankAccountError: false,
  isFetchingBankAccountSuccess: false,

  isAddingBankAccount: false,
  isAddingBankAccountError: false,
  isAddingBankAccountSuccess: false,
  isUpdatingBankAccount: false,
  isUpdatingBankAccountError: false,
  isUpdatingBankAccountSuccess: false,

  withdrawSettings: [],
  isFetchingWithdrawSettings: false,
  isFetchingWithdrawSettingsError: false,
  isFetchingWithdrawSettingsSuccess: false,
  isUpdatingWithdrawSettings: false,
  isUpdatingWithdrawSettingsError: false,
  isUpdatingWithdrawSettingsSuccess: false,

  userAccess: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  isFetchingUserAccess: true,

  isDeletingBankAccount: false,
  isDeletingBankAccountError: '',

  isFetchingSummary: false,
  summary: [],
}
export default {
  state,
  mutations,
  actions,
  getters,
}
