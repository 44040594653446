<template>
  <div>
    <flat-pickr
      v-model="value"
      class="form-control"
      :config="dateConfig"
    ></flat-pickr>
  </div>
</template>

<script>
export default {
  props: {
    selectedDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: [new Date().setDate(new Date().getDate() - 7), new Date()],
      dateConfig: {
        enableTime: true,
        mode: 'range',
      },
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.$emit('update', val)
      }
    },
  },
  created() {
    if (this.selectedDate) {
      this.value = this.selectedDate
    }
  },
}
</script>