import axios from 'axios';

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/attachment`

export default {
    async upload(formData) {
        try {
            return axios.post(`${baseURL}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        } catch (e) {
            throw e
        }
    }
}