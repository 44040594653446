import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}`

const GameProviderService = {
  fetchGames() {
    try {
      return axios.get(`${baseURL}/game`)
    } catch (e) {
      throw e
    }
  },
  fetchBetDetail(gameId, id = 0) {
    return axios.get(`${baseURL}/game/${gameId}/${id}`)
  },
  fetchTransaction(
    gameId,
    {
      page = 1,
      limit = 50,
      agentId = null,
      username = null,
      roundId = null,
      date,
    }
  ) {
    try {
      return axios.get(`${baseURL}/game/${gameId}/transaction`, {
        params: {
          page,
          agentId,
          roundId,
          limit,
          date,
          username,
        },
      })
    } catch (e) {
      throw e
    }
  },
  fetchGameById(gameId) {
    try {
      return axios.get(`${baseURL}/admins/games/detail/${gameId}`)
    } catch (e) {
      throw e
    }
  },
  fetchGameSummary(startDate, endDate, agentName) {
    try {
      return axios.get(`${baseURL}/admins/games/summary`, {
        params: {
          startDate,
          endDate,
          agentName,
        },
      })
    } catch (e) {
      throw e
    }
  },
  updateGameById(gameId, data) {
    try {
      return axios.put(`${baseURL}/admins/games/${gameId}`, data)
    } catch (e) {
      throw e
    }
  },
  fetchAgentCommission({ agentName = null, startDate, endDate }) {
    try {
      return axios.get(`${baseURL}/admins/games/summary`, {
        params: {
          agentName,
          startDate,
          endDate,
        },
      })
    } catch (e) {
      throw e
    }
  },
}

export default GameProviderService
