const parse = (value, fallback) => {
  if (typeof value === 'undefined') {
    return fallback
  }

  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value)
    case 'number':
      return JSON.parse(value)
    default:
      return value
  }
}

const config = {
  features: {
    // List of all features config
    wowSlot: parse(process.env.VUE_APP_WOW_SLOT_FEATURES, false),
  },
}

const feature = (name) => config.features[name]

export { config }
export default {
  install(Vue) {
    Vue.appConfig = config
    Vue.feature = feature
    Vue.prototype.$appConfig = config
    Vue.prototype.$feature = feature
  },
}
