export const LottoBetStatusEnum = {
    BET: 'BET',
    DONE: 'DONE',
    REFUND: 'REFUND'
}

export const LottoBetTypeEnum = {
    SIX         : 'SIX',
    FOUR        : 'FOUR',
    THREE_TOP   : 'THREE_TOP',
    THREE_TOD   : 'THREE_TOD',
    THREE_FRONT : 'THREE_FRONT',
    THREE_UNDER : 'THREE_UNDER',
    TWO_TOP     : 'TWO_TOP',
    TWO_UNDER   : 'TWO_UNDER',
    RUN_TOP     : 'RUN_TOP',
    RUN_UNDER   : 'RUN_UNDER'
}

export const LottoGameRoundStatusEnum = {
    OPENING : 'OPENING',
    CLOSE : 'CLOSE',
    DONE : 'DONE',
    REFUND : 'REFUND'
}

export const LottoGameTypeEnum = {
    THAI_GOVERNMENT : 'THAI_GOVERNMENT', // หวยรัฐบาลไทย, หวย ธกส
    THAI_BANK       : 'THAI_BANK', // หวยธนาคารไทย
    THAI_STOCK      : 'THAI_STOCK', // หวยหุ้นไทย
    FOREIGN_LOTTO   : 'FOREIGN_LOTTO', // หวยต่างประเทศ
    FOREIGN_STOCK   : 'FOREIGN_STOCK', // หวยหุ้นต่างประเทศ
    YEEKEE          : 'YEEKEE', // หวยยี่กี
    SET             : 'SET' // หวยชุด
}

export const LottoPrizingTypeEnum = {
    FIRST_PRIZE : 'FIRST_PRIZE', // รางวัลที่ 1 (หวยรัฐบาล)
    LAST_SIX    : 'LAST_SIX', // 6 ตัวท้าย หวยออมสิน
    FOUR        : 'FOUR', // หวยชุด 4 ตัว (หวยลาวชุด)
    THREE_TOP   : 'THREE_TOP', // 3 ตัวบน
    THREE_FRONT : 'THREE_FRONT', // 3 ตัวหน้า 2 รางวัล (หวยรัฐบาล)
    THREE_UNDER : 'THREE_UNDER', // 3 ตัวล่าง 2 รางวัล (หวยรัฐบาล)
    TWO_UNDER   : 'TWO_UNDER', // 2 ตัวล่าง
}