import User from '../services/user'

const mutations = {
}

const actions = {
  async transferFromBankAccount({ dispatch }, { id, payload }) {
    try {
      await User.transferFromBankAccount(id, payload)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      return false
    }
  }
}
const getters = {
}

const state = {

}

export default {
  state,
  mutations,
  actions,
  getters,
}
