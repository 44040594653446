import axios from 'axios';

const baseURL = `${process.env.VUE_APP_NEW_API_URL}`

const PromotionCodeService = {
    getAll(pagination) {
        try {
            return axios.get(`${baseURL}/promotion-code`, {
                params: pagination
            })
        } catch (e) {
            throw e
        }
    },
    findById(id) {
        try {
            return axios.get(`${baseURL}/promotion-code/${id}`)
        } catch (e) {
            throw e
        }
    },
    create(payload) {
        try {
            return axios.post(`${baseURL}/promotion-code`, payload)
        } catch (e) {
            throw e
        }
    },
    update(id, payload) {
        try {
            return axios.patch(`${baseURL}/promotion-code/${id}`, payload)
        } catch (e) {
            throw e
        }
    },
    delete(id) {
        try {
            return axios.delete(`${baseURL}/promotion-code/${id}`)
        } catch (e) {
            throw e
        }
    }
}

export default PromotionCodeService