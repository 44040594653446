<template>
  <ul id="side-menu" class="metismenu">
    <li
      v-for="item in availableMenu"
      :key="`item-${item.path}`"
      :class="{ 'mm-active': isOpen(item.path) }"
      class="side-nav-title side-nav-item"
    >
      <p v-if="item.header" class="menu-title mb-0">
        {{
          $te(`routes.${item.header}`)
            ? $t(`routes.${item.header}`)
            : item.header
        }}
      </p>

      <a
        v-if="hasItems(item)"
        :class="{ 'mm-collapsed': !isOpen(item.path) }"
        class="side-nav-link"
        @click="openPath = openPath === item.path ? '' : item.path"
      >
        <feather v-if="item.icon" :type="item.icon"></feather>
        <span>{{
          $te(`routes.${item.name}`) ? $t(`routes.${item.name}`) : item.name
        }}</span>
        <span class="menu-arrow"></span>
      </a>

      <router-link
        v-if="!hasItems(item)"
        :class="{ active: isActive(item.path) }"
        :to="`${item.path}`"
        class="side-nav-link side-nav-link-ref"
      >
        <feather v-if="item.icon" :type="item.icon"></feather>
        <span>{{
          $te(`routes.${item.name}`) ? $t(`routes.${item.name}`) : item.name
        }}</span>
        <b-badge v-if="item.badge.value" class="float-right" variant="danger">
          !
        </b-badge>
      </router-link>

      <ul
        v-if="hasItems(item)"
        :class="{ 'mm-collapse': !isOpen(item.path) }"
        class="nav-second-level"
      >
        <li v-for="subitem in item.children" :key="`sub-item-${subitem.name}`">
          <router-link
            :class="{ active: isActive(subitem.path) }"
            :to="`${item.path}/${subitem.path}`"
            class="side-nav-link-ref"
          >
            <span>{{
              $te(`routes.${subitem.name}`)
                ? $t(`routes.${subitem.name}`)
                : subitem.name
            }}</span>
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  data() {
    return {
      openPath: '',
      menu: [
        {
          key: 'dashboard',
          name: 'VIEW_DASHBOARD',
          path: '/dashboard',
          icon: 'home',
          badge: {},
        },
        {
          key: 'credit',
          name: 'VIEW_CREDITS',
          icon: 'credit-card',
          path: '/credits',
          badge: {},
          children: [
            {
              key: 'agent:view.credit-log',
              name: 'VIEW_CREDIT_ALL',
              path: 'all',
            },
            {
              key: 'agent:view.credit-log-agent',
              name: 'VIEW_CREDIT_MASTER',
              path: 'agent',
            },
          ],
        },
        {
          key: 'agent:view.active-user',
          name: 'VIEW_GAME_PLAYING',
          path: '/games/playing',
          icon: 'bar-chart-2',
          badge: {},
        },
        {
          key: 'agent:view.game',
          header: 'GAMES',
          name: 'VIEW_GAME_HISTORY',
          path: '/games/history',
          icon: 'play-circle',
          badge: {},
        },
        {
          key: 'agent:view.game',
          name: 'VIEW_GAME_SETTINGS',
          path: '/games/settings',
          icon: 'sliders',
          badge: {},
        },
        {
          key: 'agent:view.summary',
          name: 'VIEW_SUMMARY',
          path: '/summary',
          icon: 'bar-chart-2',
          badge: {},
        },
        {
          key: 'owner:manage.lotto',
          name: 'MANAGE_LOTTO',
          header: 'LOTTO_TOOLS',
          icon: 'credit-card',
          badge: {},
          path: '/lotto',
          children: [
            {
              key: 'owner:manage.lotto',
              name: 'VIEW_LOTTO',
              path: 'list',
            },
            {
              key: 'owner:manage.lotto',
              name: 'LOTTO_USER_BET_LIST',
              path: 'user-bet',
            },
          ],
        },
        {
          key: 'master:view.agent',
          header: 'AGENTS',
          name: 'VIEW_AGENTS',
          path: '/agents/list',
          icon: 'smile',
          badge: {},
        },
        // {
        //   key: 'agent:view.commission',
        //   name: 'VIEW_AGENT_COMMISSION',
        //   path: '/commissions',
        //   icon: 'dollar-sign',
        //   badge: {},
        // },

        {
          key: 'agent:view.user',
          header: 'PLAYERS',
          name: 'VIEW_PLAYERS',
          path: '/players/list',
          icon: 'users',
          badge: {},
        },
        {
          key: 'agent:manage.deposit',
          name: 'VIEW_TRANSACTION_DEPOSIT',
          path: '/deposit',
          icon: 'arrow-up-circle',
          badge: { value: 0 },
        },
        {
          key: 'agent:manage.withdraw',
          name: 'VIEW_TRANSACTION_WITHDRAW',
          path: '/withdraw',
          icon: 'arrow-down-circle',
          badge: { value: 0 },
        },
        {
          key: 'agent:view.bank-account',
          header: 'BANK',
          name: 'VIEW_BANK_ACCOUNTS',
          path: '/bank-accounts',
          icon: 'book',
          badge: {},
        },
        {
          key: 'agent:view.user-agent',
          header: 'ACCESS',
          name: 'VIEW_ASSISTANTS',
          path: '/assistants',
          icon: 'key',
          badge: {},
        },
        {
          key: 'agent:view.role',
          name: 'VIEW_ROLES',
          path: '/roles',
          icon: 'user-check',
          badge: {},
        },
        {
          key: 'agent:view.login-log',
          name: 'VIEW_USER_ACCESS',
          path: '/user-access',
          icon: 'log-in',
          badge: {},
        },
        {
          key: 'agent:view.promotion',
          header: 'SETTINGS',
          name: 'VIEW_PROMOTIONS',
          path: '/promotions/list',
          icon: 'tag',
          badge: {},
        },
        {
          key: 'agent:manage.promotion-code',
          name: 'VIEW_PROMOTION_CODE',
          path: '/promotion-code',
          icon: 'code',
          badge: {},
        },
        {
          key: 'agent:view.announce',
          path: '/announce',
          name: 'VIEW_ANNOUNCE',
          icon: 'volume-2',
          badge: {},
        },
        {
          key: 'agent:view.line-notification',
          path: '/line-notifications',
          name: 'VIEW_LINE_NOTIFICATIONS',
          icon: 'bell',
          badge: {},
        },
        {
          key: 'agent:view.whitelist',
          path: '/whitelists',
          name: 'VIEW_WHITELISTS',
          icon: 'check-circle',
          badge: {},
        },
        {
          key: 'agent:view.blacklist',
          path: '/blacklists',
          name: 'VIEW_BLACKLISTS',
          icon: 'x-octagon',
          badge: {},
        },
        {
          key: 'agent:manage.cms',
          path: '/cms',
          name: 'VIEW_CMS',
          icon: 'scissors',
          badge: {},
        },
        {
          key: 'agent:view.tracking-link',
          header: 'MARKETING_TOOLS',
          name: 'TRACKING_LINK',
          path: '/tracking-link',
          icon: 'activity',
          badge: {},
        },
        // {
        //   key: 'agent:contact.setting',
        //   path: '/agent-setting?setting_type=contact',
        //   name: 'SETTING_CONTACT',
        //   icon: 'sliders',
        //   badge: {},
        // },
        // {
        //   key: 'agent:line-login.setting',
        //   path: '/agent-setting?setting_type=line-login',
        //   name: 'SETTING_LINE_LOGIN',
        //   icon: 'sliders',
        //   badge: {},
        // },
        // {
        //   key: 'agent:withdraw.setting',
        //   path: '/agent-setting?setting_type=withdraw',
        //   name: 'SETTING_WITHDRAW',
        //   icon: 'sliders',
        //   badge: {},
        // },
        // {
        //   key: 'agent:cashback.setting',
        //   path: '/agent-setting?setting_type=cashback',
        //   name: 'SETTING_CASHBACK',
        //   icon: 'sliders',
        //   badge: {},
        // },
        // {
        //   key: 'agent:af.setting',
        //   path: '/agent-setting?setting_type=af',
        //   name: 'SETTING_AF',
        //   icon: 'sliders',
        //   badge: {},
        // },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'adminPermissions',
      'withdrawRequests',
      'unMatchHistory',
      'userInfo',
      'isOwner',
      'isMaster',
    ]),
    availableMenu() {
      const newMenu = []
      this.menu.forEach((menu) => {
        if (menu.children) {
          const newChildren = []
          menu.children.forEach((child) => {
            if (!child.key || this.$allowPermission(child.key)) {
              newChildren.push(child)
            }
          })
          if (newChildren.length > 0) {
            newMenu.push({
              ...menu,
              children: newChildren,
            })
          }
        } else {
          if (!menu.key || this.$allowPermission(menu.key)) {
            newMenu.push(menu)
          }
        }
      })
      // เมนูทั้งหมด
      // return this.menu
      // ซ่อนเมนูตาม permission
      return newMenu
    },
    currentPath() {
      return this.$route.path
    },
    requestsTotal() {
      const { data } = this.withdrawRequests
      return data && data.length ? data.length : 0
    },
    unMatchTransactionTotal() {
      return this.unMatchHistory.data ? this.unMatchHistory.data.length : 0
    },
  },
  watch: {
    currentPath(val) {
      if (val) {
        if (this.openPath !== val) {
          this.openPath = val
        }
      }
    },
    adminPermissions(val) {
      if (val && val.length > 0) {
        this.fetchOnPermissions()
      }
    },
    requestsTotal(val) {
      if (val && val > 0) {
        this.setBadge('VIEW_TRANSACTION_DEPOSIT', val)
      }
    },
    unMatchTransactionTotal(val) {
      if (val && val > 0) {
        this.setBadge('VIEW_TRANSACTION_WITHDRAW', val)
      }
    },
  },
  created() {
    this.openPath = this.currentPath
    this.fetchOnPermissions()
  },
  methods: {
    ...mapActions(['fetchWithdrawRequests', 'fetchUnMatchHistory']),
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item && item.children !== undefined
        ? item.children.length > 0
        : false
    },
    fetchOnPermissions() {
      if (
        this.adminPermissions &&
        this.adminPermissions.length > 0 &&
        this.userInfo &&
        typeof this.userInfo.agentId !== 'undefined'
      ) {
        // fetch player transaction for badge
        // this.fetchWithdrawRequests({
        //   limit: 10,
        //   offset: 0,
        // })
        // this.fetchUnMatchHistory({
        //   limit: 10,
        //   offset: 0,
        //   agentId:
        //     this.isOwner || this.isMaster
        //       ? null
        //       : this.userInfo.agentId,
        // })
      }
    },
    isActive(path) {
      const isMatched = this.currentPath.match(path)
      return !!isMatched
    },
    isOpen(path) {
      const isMatched = this.openPath.match(path)
      return !!isMatched
    },
    setBadge(menu, number) {
      if (menu && number && this.availableMenu.length > 0) {
        const foundI = this.availableMenu.findIndex(
          (prop) => prop.name === menu
        )
        if (foundI > -1) {
          this.availableMenu[foundI].badge = { value: number }
        }
      }
    },
  },
}
</script>
