const mutations = {
  NOTIFY_ERROR(state, payload) {
    state.errorMessage = payload
  },
  NOTIFY_SUCCESS(state, payload) {
    state.successMessage = payload
  },
  MAKE_SOUND(state) {
    state.isMakingSound = true
  },
  MUTE_SOUND(state) {
    state.isMakingSound = false
  },
}

const actions = {
  notifyError({ commit }, error) {
    const { code, message } = error
    let msg = message?.msg ?? message ?? error ?? 'Error message'
    if (code && code === '401') {
      msg = 'User token expired'
    } else if(Array.isArray(msg)) {
      msg.forEach((m) => {
        this.$app.$bvToast.toast(m, {
          variant: 'danger',
          title: `เกิดข้อผิดพลาด ${code ? `| ${code}` : ''}`,
          autoHideDelay: 10000,
          appendToast: true
        })
      })
    } else {
      this.$app.$bvToast.toast(msg, {
        variant: 'danger',
        title: `เกิดข้อผิดพลาด ${code ? `| ${code}` : ''}`,
        autoHideDelay: 10000,
      })
    }


    commit('NOTIFY_ERROR', error)
  },
  notifySuccess({ commit }, response) {
    this.$app.$bvToast.toast(
      `Your request has been ${response || 'sent'} successfully.`,
      {
        variant: 'success',
        title: 'สำเร็จ',
        autoHideDelay: 10000,
      }
    )
    commit('NOTIFY_SUCCESS', response)
  },
  makeSound({ commit }) {
    commit('MAKE_SOUND')
  },
  muteSound({ commit }) {
    commit('MUTE_SOUND')
  },
}

const getters = {
  appVersion: (state) => state.appVersion,
  isMakingSound: (state) => state.isMakingSound,
}

const state = {
  appVersion: `${process.env.NODE_ENV} - ${process.env.VUE_APP_VERSION || ''}`,
  errorMessage: {},
  successMessage: '',

  isMakingSound: false,
}

export default {
  mutations,
  actions,
  getters,
  state,
}
