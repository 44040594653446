import axios from 'axios'
const baseURL = `${process.env.VUE_APP_NEW_API_URL}`

export default {
  fetchBlacklists(limit, page, search, masterId, agentId) {
    try {
      return axios.get(`${baseURL}/blacklist?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`)
    } catch (e) {
      throw e
    }
  },
  fetchBlacklistById(id) {
    try {
      return axios.get(`${baseURL}/blacklist/${id}`)
    } catch (e) {
      throw e
    }
  },
  createBlacklist(data) {
    try {
      return axios.post(`${baseURL}/blacklist`, data)
    } catch (e) {
      throw e
    }
  },
  updateBlacklist(id, data) {
    try {
      return axios.put(`${baseURL}/blacklist/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  deleteBlacklist(id) {
    try {
      return axios.delete(`${baseURL}/blacklist/${id}`)
    } catch (e) {
      throw e
    }
  },
}
