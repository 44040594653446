import WithdrawService from '../services/withdraw'

const mutations = {
    FETCH_WITHDRAWS_REQUEST(state) {
        state.isFetchingWithdraws = true
    },
    FETCH_WITHDRAWS_SUCCESS(state, payload) {
        state.isFetchingWithdraws = false
        state.withdraws = payload
    },
    FETCH_WITHDRAWS_FAILURE(state) {
        state.isFetchingWithdraws = false
    },
    FETCH_WITHDRAW_PROCESSING_REQUEST(state) {
        state.isFetchingWithdrawProcessing = true
    },
    FETCH_WITHDRAW_PROCESSING_SUCCESS(state, payload) {
        state.isFetchingWithdrawProcessing = false
        state.withdrawProcessing = payload
    },
    FETCH_WITHDRAW_PROCESSING_FAILURE(state) {
        state.isFetchingWithdrawProcessing = false
    },
    FETCH_WITHDRAW_DONE_REQUEST(state) {
        state.isFetchingWithdrawDone = true
    },
    FETCH_WITHDRAW_DONE_SUCCESS(state, payload) {
        state.isFetchingWithdrawDone = false
        state.withdrawDone = payload
    },
    FETCH_WITHDRAW_DONE_FAILURE(state) {
        state.isFetchingWithdrawDone = false
    },
    FETCH_WITHDRAW_HIDE_REQUEST(state) {
        state.isFetchingWithdrawHide = true
    },
    FETCH_WITHDRAW_HIDE_SUCCESS(state, payload) {
        state.isFetchingWithdrawHide = false
        state.withdrawHide = payload
    },
    FETCH_WITHDRAW_HIDE_FAILURE(state) {
        state.isFetchingWithdrawHide = false
    },
    FETCH_WITHDRAW_ACCOUNTS_REQUEST(state) {
        state.isFetchingWithdrawAccounts = true
    },
    FETCH_WITHDRAW_ACCOUNTS_SUCCESS(state, payload) {
        state.isFetchingWithdrawAccounts = false
        state.withdrawAccounts = payload
    },
    FETCH_WITHDRAW_ACCOUNTS_FAILURE(state) {
        state.isFetchingWithdrawAccounts = false
    },
    REJECT_WITHDRAW_REQUEST(state) {
        state.isRejectingWithdraw = true
    },
    REJECT_WITHDRAW_SUCCESS(state) {
        state.isRejectingWithdraw = false
    },
    REJECT_WITHDRAW_FAILURE(state) {
        state.isRejectingWithdraw = false
    },
    APPROVE_WITHDRAW_REQUEST(state) {
        state.isApprovingWithdraw = true
    },
    APPROVE_WITHDRAW_SUCCESS(state) {
        state.isApprovingWithdraw = false
    },
    APPROVE_WITHDRAW_FAILURE(state) {
        state.isApprovingWithdraw = false
    },
    CANCEL_WITHDRAW_REQUEST(state) {
        state.isCancelingWithdraw = true
    },
    CANCEL_WITHDRAW_SUCCESS(state) {
        state.isCancelingWithdraw = false
    },
    CANCEL_WITHDRAW_FAILURE(state) {
        state.isCancelingWithdraw = false
    },
    REQUEST_WITHDRAW_OTP_REQUEST(state) {
        state.isRequestingWithdrawOtp = true
        state.isRequestingWithdrawOtpSuccess = false
        state.withdrawOtpRef = ''
    },
    REQUEST_WITHDRAW_OTP_SUCCESS(state, payload) {
        state.isRequestingWithdrawOtp = false
        state.isRequestingWithdrawOtpSuccess = true
        state.withdrawOtpRef = payload
    },
    REQUEST_WITHDRAW_OTP_FAILURE(state) {
        state.isRequestingWithdrawOtp = false
    },
    HIDE_WITHDRAW_REQUEST(state) {
        state.isHidingWithdraw = true
    },
    HIDE_WITHDRAW_SUCCESS(state) {
        state.isHidingWithdraw = false
    },
    HIDE_WITHDRAW_FAILURE(state) {
        state.isHidingWithdraw = false
    },
    FETCH_WITHDRAW_BANK_ACCOUNTS_REQUEST(state) {
        state.isFetchingWithdrawBankAccounts = true
    },
    FETCH_WITHDRAW_BANK_ACCOUNTS_SUCCESS(state, payload) {
        state.isFetchingWithdrawBankAccounts = false
        state.withdrawBankAccounts = payload
    },
    FETCH_WITHDRAW_BANK_ACCOUNTS_FAILURE(state) {
        state.isFetchingWithdrawBankAccounts = false
    },
}
const actions = {
    async fetchWithdraws({ commit, dispatch }, { limit = '', page = '', search = '', masterId = '', agentId = '' }) {
        try {
            commit('FETCH_WITHDRAWS_REQUEST')
            const response = await WithdrawService.fetchWithdraws(limit, page, search, masterId, agentId)
            commit('FETCH_WITHDRAWS_SUCCESS', response)
        } catch (e) {
            dispatch('notifyError', e)
            commit('FETCH_WITHDRAWS_FAILURE', e.message)
        }
    },
    async fetchWithdrawProcessing({ commit, dispatch }, params) {
        try {
            commit('FETCH_WITHDRAW_PROCESSING_REQUEST')
            const response = await WithdrawService.fetchWithdrawProcessing(params)
            commit('FETCH_WITHDRAW_PROCESSING_SUCCESS', response)
        } catch (e) {
            dispatch('notifyError', e)
            commit('FETCH_WITHDRAW_PROCESSING_FAILURE', e.message)
        }
    },
    async fetchWithdrawDone({ commit, dispatch }, params) {
        try {
            commit('FETCH_WITHDRAW_DONE_REQUEST')
            const response = await WithdrawService.fetchWithdrawDone(params)
            commit('FETCH_WITHDRAW_DONE_SUCCESS', response)
        } catch (e) {
            dispatch('notifyError', e)
            commit('FETCH_WITHDRAW_DONE_FAILURE', e.message)
        }
    },
    async fetchWithdrawHide({ commit, dispatch }, { limit = '', page = '', search = '', masterId = '', agentId = '' }) {
        try {
            commit('FETCH_WITHDRAW_HIDE_REQUEST')
            const response = await WithdrawService.fetchWithdrawHide(limit, page, search, masterId, agentId)
            commit('FETCH_WITHDRAW_HIDE_SUCCESS', response)
        } catch (e) {
            dispatch('notifyError', e)
            commit('FETCH_WITHDRAW_HIDE_FAILURE', e.message)
        }
    },
    async fetchWithdrawAccounts({ commit, dispatch }, { masterId = '', agentId = '' }) {
        try {
            commit('FETCH_WITHDRAW_ACCOUNTS_REQUEST')
            const response = await WithdrawService.fetchWithdrawAccounts(masterId, agentId)
            commit('FETCH_WITHDRAW_ACCOUNTS_SUCCESS', response)
        } catch (e) {
            dispatch('notifyError', e)
            commit('FETCH_WITHDRAW_ACCOUNTS_FAILURE', e.message)
        }
    },
    async rejectWithdraw({ commit, dispatch }, { id, data }) {
        try {
            if (state.isRejectingWithdraw) return
            commit('REJECT_WITHDRAW_REQUEST')
            await WithdrawService.rejectWithdraw(id, data)
            commit('REJECT_WITHDRAW_SUCCESS')
            dispatch('fetchWithdraws', { limit: '', page: '', search: '', masterId: '', agentId: '' })
            dispatch('fetchWithdrawDone', { limit: '', page: '', search: '', masterId: '', agentId: '' })
        } catch (e) {
            dispatch('notifyError', e)
            commit('REJECT_WITHDRAW_FAILURE', e.message)
        }
    },
    async approveWithdraw({ commit, dispatch }, { id, data }) {
        if(state.isApprovingWithdraw) return
        try {
            commit('APPROVE_WITHDRAW_REQUEST')
            await WithdrawService.approveWithdraw(id, data)
            commit('APPROVE_WITHDRAW_SUCCESS')
            dispatch('fetchWithdraws', { limit: '', page: '', search: '', masterId: '', agentId: '' })
            dispatch('fetchWithdrawHide', { limit: '', page: '', search: '', masterId: '', agentId: '' })
            dispatch('fetchWithdrawProcessing', { limit: '', page: '', search: '', masterId: '', agentId: '' })
            dispatch('notifySuccess', 'sent')
        } catch (e) {
            dispatch('notifyError', e)
            commit('APPROVE_WITHDRAW_FAILURE', e.message)
        }
    },
    async cancelWithdraw({ commit, dispatch }, id) {
        if (state.isCancelingWithdraw) return;
        try {
            commit('CANCEL_WITHDRAW_REQUEST')
            await WithdrawService.cancelWithdraw(id)
            commit('CANCEL_WITHDRAW_SUCCESS')
            dispatch('fetchWithdraws', { limit: '', page: '', search: '', masterId: '', agentId: '' })
            dispatch('fetchWithdrawProcessing', { limit: '', page: '', search: '', masterId: '', agentId: '' })
            dispatch('notifySuccess')
        } catch (e) {
            dispatch('notifyError', e)
            commit('CANCEL_WITHDRAW_FAILURE', e.message)
        }
    },
    async requestWithdrawOtp({ commit, dispatch }, { id, agentBankAccountId }) {
        try {
            commit('REQUEST_WITHDRAW_OTP_REQUEST')
            const { ref } = await WithdrawService.requestWithdrawOtp(id, agentBankAccountId)
            commit('REQUEST_WITHDRAW_OTP_SUCCESS', ref)
            dispatch('notifySuccess')
        } catch (e) {
            dispatch('notifyError', e)
            commit('REQUEST_WITHDRAW_OTP_FAILURE', e.message)
        }
    },
    async hideWithdraw({ commit, dispatch }, id) {
        try {
            commit('HIDE_WITHDRAW_REQUEST')
            await WithdrawService.hideWithdraw(id)
            commit('HIDE_WITHDRAW_SUCCESS')
            dispatch('fetchWithdraws', { limit: '', page: '', search: '', masterId: '', agentId: '' })
            dispatch('fetchWithdrawHide', { limit: '', page: '', search: '', masterId: '', agentId: '' })
            dispatch('notifySuccess')
        } catch (e) {
            dispatch('notifyError', e)
            commit('HIDE_WITHDRAW_FAILURE', e.message)
        }
    },
    async fetchWithdrawBankAccounts({ commit, dispatch }) {
        try {
            commit('FETCH_WITHDRAW_BANK_ACCOUNTS_REQUEST')
            const response = await WithdrawService.fetchBankAccounts()
            commit('FETCH_WITHDRAW_BANK_ACCOUNTS_SUCCESS', response)
        } catch (e) {
            dispatch('notifyError', e)
            commit('FETCH_WITHDRAW_BANK_ACCOUNTS_FAILURE', e.message)
        }
    },
    async exportWithdrawTransaction({ dispatch }, params) {
        try {
            await WithdrawService.exportWithdrawTransactions(params)
            dispatch('notifySuccess')
        } catch (e) {
            dispatch('notifyError', e)
        }
    },
}
const getters = {
    withdraws: (state) => state.withdraws,
    withdrawProcessing: (state) => state.withdrawProcessing,
    withdrawDone: (state) => state.withdrawDone,
    withdrawHide: (state) => state.withdrawHide,
    withdrawBankAccounts: (state) => state.withdrawBankAccounts,
}
const state = {
    withdraws: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 } },

    isFetchingWithdraws: false,

    withdrawProcessing: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 } },
    isFetchingWithdrawProcessing: false,

    withdrawDone: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 } },
    isFetchingWithdrawDone: false,

    withdrawHide: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 } },
    isFetchingWithdrawHide: false,

    isFetchingWithdrawAccounts: false,
    withdrawAccounts: [],

    isRejectingWithdraw: false,
    isApprovingWithdraw: false,
    isCancelingWithdraw: false,
    isHidingWithdraw: false,

    withdrawBankAccounts: [],
    isFetchingWithdrawBankAccounts: false,

    isRequestingWithdrawOtp: false,
    isRequestingWithdrawOtpSuccess: false,
    withdrawOtpRef: '',
}

export default {
    state,
    mutations,
    actions,
    getters,
}