/* cSpell:disable */
export default {
  routes: {
    VIEW_PROFILE: 'โปรไฟล์ของฉัน',
    PROFILE: 'แก้ไขข้อมูล',
    VIEW_DASHBOARD: 'แดชบอร์ด',
    VIEW_SUMMARY: 'สรุปยอดเกม',
    GAMES: 'เกม',
    VIEW_GAME_HISTORY: 'ประวัติการเล่นเกม',
    VIEW_GAME_SETTINGS: 'ตั้งค่าเกม',
    VIEW_GAME_PLAYING: 'จำนวนยูสที่กำลังเล่น',
    VIEW_GAME_DETAIL: 'ข้อมูลเกม',
    CREATE_GAME: 'สร้างเกมใหม่',
    VIEW_GAME_PROVIDER_DETAIL: 'ข้อมูลเกมค่ายต่างๆ',
    AGENTS: 'เอเยนต์',
    VIEW_AGENTS: 'รายชื่อเอเยนต์',
    VIEW_AGENT_DETAIL: 'ข้อมูลเอเยนต์',
    CREATE_AGENT: 'สร้างเอเยนต์ใหม่',
    VIEW_AGENT_COMMISSION: 'คอมมิชชั่น',
    VIEW_CREDITS: 'รายงานเครดิต',
    VIEW_CREDIT_MASTER: 'รายงานเครดิตเอเยนต์',
    VIEW_CREDIT_AGENT: 'รายงานเครดิตเอเยนต์',
    VIEW_CREDIT_ALL: 'รายงานเครดิตผู้เล่น',
    VIEW_CREDIT_BOT: 'เครดิตบอทเล่นเกม',
    credits_transfer: 'เติม/ถอนเครดิต',
    VIEW_AGENT_PLAYER_TRANSACTION: 'เติม/ถอนเครดิตตรง (เอเยนต์ - ผู้เล่น)',
    PLAYERS: 'ผู้เล่น',
    VIEW_PLAYERS: 'รายชื่อผู้เล่น',
    VIEW_PLAYER_TRANSACTION_HISTORY: 'ประวัติเครดิตของผู้เล่น',
    VIEW_PLAYER_DETAIL: 'ข้อมูลผู้เล่น',
    VIEW_PLAYER_BET_DETAIL: 'ตั้งค่าการวางเดิมพัน',
    CREATE_PLAYER: 'สร้างผู้เล่นใหม่',
    TRANSACTIONS: 'รายการฝากถอน',
    VIEW_PLAYER_TRANSACTION: 'รายงานฝากถอน',
    VIEW_TRANSACTION_DEPOSIT: 'รายการฝาก',
    VIEW_TRANSACTION_WITHDRAW: 'รายการถอน',
    VIEW_TRANSACTION_REQUEST: 'รายการแจ้งฝากถอน',
    VIEW_TRANSACTION_HISTORY: 'รายงานฝากถอนย้อนหลัง',
    VIEW_TRANSACTION_UNKNOWN_LOGS: 'รายงานฝากถอน (กรณีบัญชีไม่ตรง)',
    VIEW_HIDDEN_TRANSACTION_REQUEST: 'รายการที่ซ่อนไว้',
    transactions_deposit: 'รายการแจ้งฝาก',
    transactions_withdraw: 'รายการแจ้งถอน',
    VIEW_BANK_ACCOUNTS: 'บัญชีธนาคาร',
    VIEW_BANK_MOBILE_APP: 'โมบายแบ้งค์กิ้ง',
    BANK_TRUEWALLET: 'บัญชีทรูวอลเล็ท',
    VIEW_DEPOSIT_WITHDRAW_MEMBERS: 'ฝากถอนสมาชิก',
    VIEW_TRUEWALLET_ACCOUNT: 'บัญชีทรูวอลเล็ท',
    VIEW_BANK_TRUEWALLET_ACCOUNT_HISTORY: 'ประวัติบัญชีทรูวอลเล็ท',
    VIEW_BANK_ACCOUNT_DETAIL: 'แก้ไขบัญชีธนาคาร',
    CREATE_BANK_ACCOUNT: 'สร้างบัญชีธนาคารใหม่',
    VIEW_BANK_ACCOUNT_HISTORY: 'ประวัติบัญชีธนาคาร',
    VIEW_DEPOSIT_WITHDRAW_MEMBERS_DEPOSIT: 'รายการฝาก',
    VIEW_DEPOSIT_WITHDRAW_MEMBERS_WITHDRAW: 'รายการถอน',
    VIEW_DEPOSIT_WITHDRAW_MEMBERS_ALL: 'รายการทั้งหมด',
    SETTINGS: 'ตั้งค่าทั่วไป',
    ACCESS: 'การเข้าใช้งาน',
    VIEW_BOT_SETTINGS: 'ตั้งค่าบอท',
    VIEW_WITHDRAW_SETTINGS: 'ตั้งค่าการถอนเงิน',
    VIEW_DEPOSIT_SETTINGS: 'ตั้งค่าการฝากเงิน',
    VIEW_NOTIFICATION_SETTINGS: 'ตั้งค่าแจ้งเตือน',
    assistants: 'ผู้ช่วย',
    VIEW_ASSISTANTS: 'รายชื่อผู้ช่วย',
    VIEW_ASSISTANT_DETAIL: 'ข้อมูลผู้ช่วย',
    CREATE_ASSISTANT: 'เพิ่มผู้ช่วยใหม่',
    VIEW_AFFILIATE: 'ระบบแนะนำ (AF)',
    VIEW_AFFILIATE_SETTINGS: 'ตั้งค่าระบบแนะนำ (AF)',
    PROMOTIONS: 'โปรโมชั่น',
    VIEW_PROMOTIONS: 'โปรโมชั่น',
    VIEW_PROMOTION_DETAIL: 'ข้อมูลโปรโมชั่น',
    CREATE_PROMOTION: 'เพิ่มโปรโมชั่น',
    VIEW_PROMOTION_CODE: 'โค้ดโปรโมชั่น',
    CREATE_PROMOTION_CODE: 'สร้างโค้ดโปรโมชั่น',
    EDIT_PROMOTION_CODE: 'แก้ไขโค้ดโปรโมชั่น',
    VIEW_CASHBACK: 'เงินคืน',
    EDIT_CASHBACK: 'แก้ไขโปรโมชั่นเงินคืน',
    CREATE_CASHBACK: 'เพิ่มโปรโมชั่นเงินคืน',
    VIEW_FREE_CREDIT: 'เครดิตฟรี',
    VIEW_FREE_CREDIT_LIST: 'รายการฟรีเครดิต',
    VIEW_PROMOTION_DEPOSIT: 'ยอดเงินฝากสะสม',
    VIEW_PROMOTION_CHECKIN: 'เช็คอินรายวัน',
    VIEW_REWARDS: 'รางวัล',
    VIEW_REWARD_DETAIL: 'ข้อมูลรางวัล',
    CREATE_REWARD: 'เพิ่มรางวัลใหม่',
    rewards_win_wheel: 'หมุนวงล้อชิงโชค',
    rewards_card: 'สุ่มเปิดไพ่',
    ANNOUNCEMENTS: 'ประกาศ',
    VIEW_ANNOUNCES: 'ประกาศ',
    VIEW_ANNOUNCE: 'ประกาศ',
    CREATE_ANNOUNCE: 'สร้างประกาศ',
    EDIT_ANNOUNCE: 'แก้ไขประกาศ',
    VIEW_ANNOUNCE_DETAIL: 'ข้อมูลประกาศ',
    VIEW_USER_ACCESS: 'ข้อมูลการเข้าใช้งาน',
    VIEW_ROLES: 'ตั้งค่า Role',
    BRANDS: 'ตั้งค่าแบรนด์',
    VIEW_BRANDS: 'ตั้งค่าแบรนด์',
    CREATE_BRAND: 'สร้างแบรนด์ใหม่',
    VIEW_BRAND_DETAIL: 'แก้ไขแบรนด์',
    VIEW_PERMISSIONS: 'เมนูการเข้าถึง',
    VIEW_CONTACTS: 'ช่องทางติดต่อ',
    BANK: 'บัญชีธนาคาร',
    VIEW_RAW_TXN: 'ข้อมูลดิบบัญชีธนาคาร',
    VIEW_BLACKLISTS: 'แบล็คลิสต์',
    VIEW_WHITELISTS: 'Whitelists',
    VIEW_CMS: 'ตั้งค่า CMS',
    VIEW_LINE_NOTIFICATIONS: 'แจ้งเตือนไลน์',
    SETTING_CONTACT: 'ตั้งค่าข้อมูลติดต่อ',
    SETTING_LINE_LOGIN: 'ตั้งค่าล็อคอินด้วยไลน์',
    SETTING_WITHDRAW: 'ตั้งค่าการถอนเงิน',
    SETTING_CASHBACK: 'ตั้งค่าระบบเงินคืน',
    SETTING_AF: 'ตั้งค่าระบบลูกข่าย',
    MARKETING_TOOLS: 'เครื่องมือการตลาด',
    TRACKING_LINK: 'ลิงค์การตลาด',
    VIEW_TRACKING_LINK: 'ลิงค์การตลาด',
    VIEW_TRACKING_LINK_DETAIL: 'ข้อมูลลิงค์การตลาด',
    CREATE_TRACKING_LINK: 'เพิ่มลิงค์การตลาด',
    EDIT_TRACKING_LINK: 'แก้ไขลิงค์การตลาด',
    MANAGE_LOTTO: 'จัดการหวย',
    VIEW_LOTTO: 'รายการหวย',
    LOTTO_TOOLS: 'ระบบหวย',
    LOTTO_RESULT: 'ออกผล',
    LOTTO_SETTING_ROUND: 'ตั้งค่ารอบ',
    LOTTO_CONFIG: 'ตั้งค่าหวย',
    LOTTO_USER_BET_LIST: 'รายการโพย',
    LOTTO_USER_BET_DETAILS_LIST: 'รายละเอียดโพย',
    LOTTO_GET_SUMMARY: 'สรุปยอด',
  },
  pages: {
    login: {
      forgot_password: 'ลืมรหัสผ่าน?',
    },
    forget_password: {
      title: 'ลืมรหัสผ่าน',
      subtitle: 'เราจะส่งรหัส OTP ไปที่โทรศัพท์หมายเลข (xxx xxxx xx78)',
      otp_code: 'รหัสยืนยัน',
      resend: 'ส่ง OTP อีกครั้ง',
      get_otp: 'กดรับ OTP ตอนนี้',
      verify: 'ตรวจสอบและดำเนินการต่อ',
      back_login: 'กลับไปหน้า ล็อคอิน',
    },
    dashboard: {
      overview: 'ภาพรวม',
      today: 'วันนี้',
      total_players: 'สมาชิกทั้งหมด',
      total_free_credit: 'รับเครดิตฟรีทั้งหมด',
      total_loss_free_credit: 'จำนวนเครดิตฟรีที่เสียทั้งหมด',
      total_deposit: 'ยอดฝากรวมทั้งหมด',
      total_withdraw: 'ยอดถอนรวมทั้งหมด',
      total_revenue: 'สรุปรายได้ทั้งหมด',
      today_new_register: 'สมาชิกที่สมัครวันนี้',
      today_free_credit: 'รับเครดิตฟรีวันนี้',
      today_loss_free_credit: 'จำนวนเครดิตฟรีที่เสียวันนี้',
      today_deposit: 'ยอดฝากรวมวันนี้',
      today_withdraw: 'ยอดถอนรวมวันนี้',
      today_revenue: 'สรุปรายได้วันนี้',
      total_first_deposit: 'เติมเงินครั้งแรกทั้งหมด',
      total_first_daily_deposit: 'ฝากครั้งแรกของวันทั้งหมด',
      total_all_deposit_bonus: 'รับโบนัสทุกยอดฝากทั้งหมด',
      total_schedule_deposit: 'ฝากเป็นเวลาทั้งหมด',
      total_accumulated_deposit: 'เติมสะสมทั้งหมด',
      today_first_deposit: 'เติมเงินครั้งแรกวันนี้',
      today_first_daily_deposit: 'ฝากครั้งแรกของวันวันนี้',
      today_all_deposit_bonus: 'รับโบนัสทุกยอดฝากวันนี้',
      today_schedule_deposit: 'ฝากเป็นเวลาวันนี้',
      today_accumulated_deposit: 'เติมสะสมวันนี้',
      stats: {
        total_credits: 'เครดิตทั้งหมด',
        total_agents: 'จำนวนเอเยนต์',
        total_players: 'จำนวนผู้เล่น',
        player_overview: 'ภาพรวมผู้เล่น',
        agent_overview: 'ภาพรวมเอเยนต์',
        new_players: 'ผู้เล่นใหม่',
        all_players: 'ผู้เล่นทั้งหมด',
        total_top_up: 'เติมเครดิตรวม',
      },
    },
    mini_games: {
      title: 'มินิเกม',
      reports: 'ผลมินิเกม',
      total_games: 'จำนวนการเล่นทั้งหมด',
      total_bet: 'จำนวนเดิมพันรวม',
      total_fee: 'ผลกินน้ำรวม',
    },
    provider_games: {
      title: 'เกมค่ายต่างๆ',
    },
  },
  utils: {
    lang: {
      th: 'ภาษาไทย',
      en: 'ภาษาอังกฤษ',
    },
    status: {
      hidden: 'ซ่อน',
      maintainance: 'ปิดปรับปรุง',
      bet: 'แทง',
      active: 'กำลังใช้งาน',
      inactive: 'ปิดการใช้งาน',
      active_short: 'เปิด',
      inactive_short: 'ปิด',
      in_active: 'เปิดใช้งาน',
      enable: 'เปิดใช้งาน',
      disable: 'ปิดการใช้งาน',
      blocked: 'ระงับการใช้งาน',
      pending_credit: 'รอคอนเฟิร์ม',
      success_credit: 'ดำเนินการแล้ว',
      unsuccessful: 'ไม่สำเร็จ',
      rejected: 'ปฎิเสธคำขอ',
      approve: 'อนุมัติ',
      reject: 'ปฎิเสธคำขอ',
      reject_return: 'คืนเครดิต',
      reject_not_return: 'ไม่คืนเครดิต',
      verified: 'ยืนยันแล้ว',
      no_verify: 'ยังไม่ได้ยืนยัน',
      banned: 'ถูกแบน',
      deleted: 'ลบแล้ว',
      finished: 'จบแล้ว',
      waiting: 'กำลังรอ',
      processing: 'กำลังประมวลผล',
      canceled: 'ยกเลิกแล้ว',
      uploaded: 'อัพโหลดแล้ว',
      allow_concurrent: 'อนุญาติให้ผู้เล่นสร้างและเข้าห้องได้พร้อมๆ กัน',
      not_allow_concurrent: 'ไม่อนุญาติให้ผู้เล่นสร้างและเข้าห้องได้พร้อมๆ กัน',
      released: 'ออกผลแล้ว',
      not_released: 'ยังไม่ออกผล',
    },
    periods: {
      day: 'รายวัน',
      week: 'ต่อสัปดาห์',
      month: 'ต่อเดือน',
    },
  },
  buttons: {
    login: 'เข้าสู่ระบบ',
    logout: 'ออกจากระบบ',
    signup: 'สมัครสมาชิก',
    reset_password: 'ตั้งรหัสผ่านใหม่',
    submit: 'ตกลง',
    resend: 'ส่งอีกครั้ง',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    copy: 'คัดลอก',
    copied: 'คัดลอกแล้ว!',
    add: 'เพิ่มใหม่',
    add_master: 'เพิ่มมาสเตอร์ใหม่',
    add_agent: 'เพิ่มเอเยนต์ใหม่',
    remove: 'ลบทิ้ง',
    edit: 'แก้ไข',
    add_bank_account: 'เพิ่มบัญชี',
    back: 'ย้อนกลับ',
    access_login: 'เข้าใช้งาน',
    ban: 'ระงับการใช้งาน',
    un_ban: 'ปลดแบน',
    operation: 'ทำรายการ',
    clear: 'ล้างข้อมูล',
    view: 'ดูรายละเอียด',
    change_status: 'เปลี่ยนสถานะ',
    remove_pin: 'ลบ pincode',
    detail: 'ดูข้อมูล',
    set_payout_rate: 'ตั้งค่าเรทจ่าย',
    view_lotto_user_bet: 'รายการโพย',
    view_summary: 'สรุปยอด',
    cancel_lotto: 'คืนโพย',
    calculate: 'คำนวณ',
    re_calculate: 'คำนวณใหม่',
    confirm_result: 'ยืนยันออกผล',
    open_round: 'เปิดรอบ',
    transfer: 'โอนเงิน'
  },
  fields: {
    all: 'ทั้งหมด',
    allow: 'อนุญาตให้เข้าถึง',
    not_allow: 'ปิดการเข้าถึง',
    start: 'เริ่ม',
    end: 'สิ้นสุด',
    game: 'เกม',
    baht: 'บาท',
    channel: 'ช่องทาง',
    summary: 'สรุป',
    amount: 'จำนวนเงิน',
    current_amount: 'จำนวนปัจจุบัน',
    percentage: 'อัตราร้อยละ',
    click_select: 'คลิกเพื่อเลือก',
    conditions: 'เงื่อนไข',
    remark: 'หมายเหตุ',
    upcoming: 'เร็วๆนี้',
    live_preview: 'ดูตัวอย่างสดๆ',
    reference: 'อ้างอิง',
    category: 'หมวด',
    status: 'สถานะ',
    createdAt: 'สร้างเมื่อ',
    updatedAt: 'อัพเดทล่าสุด',
    lastLogin: 'เข้าใช้ล่าสุด',
    joined_date: 'วันที่สมัคร',
    description: 'รายละเอียด',
    result: 'ผลลัพธ์',
    profile: 'โปรไฟล์',
    issued_date: 'วันที่ทำรายการ',
    date: 'วันที่',
    random_percentage: 'เปอร์เซ็นสุ่ม',
    search: 'ค้นหา',
    advance_setting: 'การตั้งค่าขั้นสูง',
    allow_concurrent: 'อนุญาติให้ผู้เล่นสร้างและเข้าห้องได้พร้อมๆ กัน',
    game_type: 'ประเภทเกม',
    type: 'ประเภท',
    order: 'ลำดับที่',
    title: 'หัวข้อ',
    yes: 'ใช่',
    no: 'ไม่',
    image: 'รูปภาพ',
    revenue: 'รายได้',
    remain_revenue: 'รายได้คงเหลือ',
    person: 'คน',
    credit: 'เครดิต',
    total_credit: 'เครดิตรวม',
    agent_username: 'ชื่อเอเยนต์',
    agent_prefix: 'Agent prefix',
    user_username: 'ชื่อผู้ใช้',
    user_phone_number: 'เบอร์โทรศัพท์',
    source: 'ที่มา',
    greater_number: 'จำนวนมากกว่าเท่ากับ',
    less_number: 'จำนวนน้อยกว่าเท่ากับ',
    select_time: 'เลือกช่วงเวลา',
    promotion_title: 'โปรโมชั่น',
    deposit_amount: 'ยอดฝาก',
    bonus: 'โบนัส',
    turn_over: 'เทริน',
    turn_required: 'ยอดเทรินที่ต้องทำ',
    direct_deposit_with_promotion: 'รับโปร',
    hold_balance: 'ยอดสะสม',
    times: 'ครั้ง'
  },
  messages: {
    enter_details: 'ใส่ข้อมูลของคุณด้านล่าง',
    nothing_here: 'ไม่มีข้อมูล',
    password_not_match: 'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
    none: 'ไม่มีข้อความ',
    complete_form: 'กรอกข้อมูลด้านล่างให้ครบถ้วน',
    invalid_form: 'กรอกข้อมูลไม่ครบถ้วน',
    example: 'ดูตัวอย่าง',

    404: '404 - ไม่พบ URL คำขอบนเซิร์ฟเวอร์นี้',
    server_error: '{value} - ระบบภายในขัดข้อง',
    success: 'คำขอของคุณเสร็จสมบูรณ์แล้ว',
    error: '{code} - เกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
    network_error: 'กรุณาตรวจสอบเครือข่ายอินเตอร์เน็ตของคุณ',
    try_again: 'เกิดปัญหาขึ้นจากระบบภายใน กรุณาลองอีกครั้งภายหลัง...',
    confirm: 'คุณแน่ใจหรือไม่ว่าจะดำเนินการต่อ?',
  },
  agent: {
    title: 'เอเยนต์',
    credit: 'เครดิต',
    credit_bonus: 'เครดิตโบนัส',
    referral: 'คนแนะนำ',
    current_income: 'รายได้ปัจจุบัน',
    total_income: 'รายได้ทั้งหมด',
    total_player: 'จำนวนผู้เล่น',
    name: 'ชื่อเอเยนต์',
    credit_players: 'เครดิตสมาชิก',
    total_players: 'จำนวนสมาชิก',
    phone: 'เบอร์โทรศัพท์',
    assistants: 'จำนวนผู้ช่วย',
    commission: 'คอมมิชชั่น (%)',
    af_commission: 'คอมมิชชั่นระบบแนะนำ (%)',
    register_link: 'ลิงค์เว็บไซต์',
    register_url: 'ลิงค์เว็บไซต์',
    no_agent: 'ไม่มีเอเยนต์',
    income: 'รายได้รวม',
    net_income: 'รายได้รวมสุทธิ',
    line_client_id: 'Line Channel ID',
    line_client_secret: 'Line Channel Secret',
    has_line_signin: 'Enable Line signin',
  },
  user: {
    prefix: 'รหัสสมาชิก',
    username: 'ยูสเซอร์เนม',
    firstname: 'ชื่อ',
    lastname: 'นามสกุล',
    password: 'รหัสผ่าน',
    change_password: 'เปลี่ยนรหัสผ่าน',
    new_password: 'รหัสผ่านใหม่',
    old_password: 'รหัสผ่านเก่า',
    confirm_password: 'ยืนยันรหัสผ่าน',
    email: 'อีเมล์',
    registered_date: 'วันที่สมัคร',
    referral: 'ผู้แนะนำ',
    link_af: 'Link AF',
    dateOfBirth: 'วันเกิด',
    phone: 'เบอร์โทรศัพท์',
    ban_user: 'แบนผู้เล่น',
    cancel_ban_user: 'ยกเลิกการแบนผู้เล่น',
    bet_report: 'ยอดแทง',
    today_bet: 'ยอดแทงวันนี้',
    bet_history: 'ยอดแทงย้อนหลัง',
    withdraw_revenue_history: 'ประวัติการรับ/ถอนรายได้',
    has_line_signin: 'ล็อกอินด้วย Line',
    note: 'โน๊ต',
    createdAt: 'สมัครเมื่อ',
  },
  bank_accounts: {
    allow_bank_code_list: 'ธนาคารที่อนุญาติให้เห็น',
    scb_verify_status: 'สถานะบัญชี SCB',
    true_wallet_verify_status: 'สถานะบัญชี True Wallet',
    agent: 'เอเย่นต์',
    limit_all_deposit: 'ลิมิตการฝาก',
    limit_all_withdraw: 'ลิมิตการถอน',
    type: 'ประเภทบัญชี',
    random_percentage: 'สุ่มเจอ',
    credits: 'เครดิต',
    deposit: 'เติมเงิน',
    withdraw: 'ถอนเงิน',
    balance: 'ยอดเงิน',
    detail: 'ข้อมูลบัญชี',
    bank: 'ธนาคาร',
    name: 'ชื่อธนาคาร',
    account_no: 'เลขบัญชี',
    agent_username: 'เอเย่นต์',
    account_name: 'ชื่อบัญชี',
    transfer_channel: 'เลือกธนาคารที่ลูกค้าโอน',
    transfer_amount: 'จำนวนเงินที่โอน',
    transfer: 'โอนเงิน',
    select_master: 'เลือกมาสเตอร์',
    select_agent: 'เลือกเอเย่นต์',
    select_bank: 'เลือกบัญชีธนาคาร',
    select_methods: 'เลือกช่องทางการโอนเงิน',
    transferred_date: 'วันที่โอน',
    transferred_time: 'เวลาที่โอน',
    debit_credit_flag: 'ธงสถานะ',
    transaction_code: 'รหัสธุรกรรม',
    withdraw_available: 'จำนวนเงินที่ถอนได้',
    withdrawal_amount: 'จำนวนเงินที่ต้องการถอน',
    automatic_status: 'สถานะระบบอัตโนมัติ',
    all_transaction: 'จำนวนการฝากถอนทั้งหมด',
    total_deposit: 'จำนวนการฝาก',
    total_withdraw: 'จำนวนการถอน',
    channel: 'ช่องทาง',
    channel_detail: 'คำอธิบายช่องทาง',
    currency: 'สกุลเงิน',
    pair_success: 'จับคู่บัญชีได้',
    pair_failed: 'จับคู่บัญชีไม่ได้',
    transfer_methods: {
      title: 'ช่องทางการโอน',
      bank: 'ผ่านธนาคาร',
      atm: 'ตู้เอทีเอ็ม',
      mobile: 'ผ่านแอปมือถือ',
      web: 'ผ่านเว็บไซต์ธนาคาร',
      counter: 'เคาน์เตอร์เซอร์วิส',
    },
    types: {
      deposit_withdraw: 'บัญชีฝากและถอน',
      deposit: 'บัญชีฝาก',
      withdraw: 'บัญชีถอน',
      hold: 'บัญชีพักเงิน',
    },
    from_account: 'จากบัญชี',
    to: 'ไปยังธนาคาร'
  },
  credits: {
    credit: 'เครดิต',
    current: 'เครดิตปัจจุบัน',
    hold_balance: 'ยอดสะสม',
    total: 'เครดิตทั้งหมด',
    credit_records: 'รายงานเครดิต',
    transfer: 'เติม/ถอนเครดิต',
    direct_transfer: 'เติม/ถอนเครดิตตรง',
    credit_history: 'รายงานเครดิตย้อนหลัง',
    credit_transaction: 'รายงานเครดิต',
    credit_direct_transaction: 'รายงานเครดิตตรง',
    credit_amount: 'จำนวนเครดิต',
    deposit: 'เติมเครดิต',
    withdraw: 'ถอนเครดิต',
    withdraw_request: 'ขอถอนเครดิต',
    deposit_amount: 'ยอดฝาก',
    withdraw_amount: 'ยอดถอน',
    bonus: 'โบนัส',
    margin: 'ส่วนต่าง',
    win: 'ชนะ',
    clear: 'ล้างข้อมูล',
    bet: 'วางเดิมพัน',
    draw: 'เสมอ',
    cancel_bet: 'ยกเลิกเดิมพัน',
    direct_deposit: 'เติมเครดิตตรง',
    direct_withdraw: 'ถอนเครดิตตรง',
    direct_withdraw_all: 'ถอนเครดิตตรงทั้งหมด',
    transaction_no: 'เลขที่ทำรายการ',
    operated_by: 'กระทำโดย',
    receiver: 'ผู้รับ',
    before_amount: 'เครดิตก่อนหน้า',
    remain_credits: 'เครดิตคงเหลือ',
    previous_credits: 'เครดิตก่อนทำรายการ',
    transaction_date: 'วัน/เวลาฝากถอน',
    type: 'ประเภทรายการ',
    approve_withdraw: 'อนุมัติรายการถอนเครดิต',
    proof: 'หลักฐานการโอนเงิน',
    upload_proof: 'อัพโหลดหลักฐานการโอนเงิน',
    has_deposit_amount: 'มียอดฝาก',
    issued_bank: 'ธนาคารแจ้งฝาก',
    receiving_bank: 'ธนาคารรับฝาก',
    transaction: {
      all_request: 'รายการแจ้งฝาก-ถอน',
      all_history: 'รายการแจ้งฝากย้อนหลัง',
      deposit_request: 'รายการแจ้งฝาก',
      deposit_history: 'รายการแจ้งฝาก-ถอนย้อนหลัง',
      withdraw_request: 'รายการแจ้งถอน',
      withdraw_history: 'รายการแจ้งถอนย้อนหลัง',
      auto: 'รายการฝากอัตโนมัติ',
    },
  },
  games: {
    type: 'ชนิดเกม',
    mini_games: 'มินิเกม',
    provider_games: 'เกมค่ายต่างๆ',
    lotto_games: 'หวย',
    head_tail: 'หัวก้อย',
    rock_paper_scissors: 'เป่ายิงฉุบ',
    hi_lo: 'สูงต่ำ',
    cards_battle: 'ตีไก่',
    sa: 'SA',
    sexy: 'Sexy',
    dg: 'Dream Gaming',
    sp: 'Simple Play',
    government: 'หวยรัฐบาล',
    yeekee: 'หวยยี่กี',
    total_income: 'รายได้สุทธิ',
    all_games: 'เกมทั้งหมด',
    name: 'ชื่อเกม',
    bet_limit: 'กำหนดวงเงินเดิมพัน',
    player_limit: 'กำหนดจำนวนผู้เล่น',
    transaction_fee: 'ค่ากินน้ำ',
    water_rate: 'อัตราค่าน้ำ',
    how_to: 'กฎกติกาและวิธีการเล่น',
    rules: 'กฎกติกา',
    how: 'วิธีการเล่น',
    new_game: 'สร้างเกมใหม่',
    bet_money: 'เลือกจำนวนเดิมพัน',
    bet_amount: 'จำนวนเดิมพัน',
    win_amount: 'เงินรางวัล',
    date: 'วันที่เล่น',
    random: 'สุ่มตอนนี้',
    set_result: 'กำหนดผล',
    playground: 'สนามเล่นเกม',
    live_games: 'ไลฟ์เกม',
    my_games: 'เกมของฉัน',
    competitor: 'คู่แข่ง',
    bettor: 'เจ้ามือ',
    rival: 'คนแทง',
    player: 'ผู้เล่น',
    winner: 'ผู้ชนะ',
    guess: 'ทาย',
    games_no: 'จำนวนเกม',
    win_rate: 'อัตราการชนะ',
    win_loss_rate: 'อัตราแพ้ชนะ',
    win_loss_result: 'ผลแพ้ชนะ',
    your_choice: 'ตัวเลือกของคุณ',
    win: 'ชนะ',
    loss: 'แพ้',
    all_win: 'ชนะทั้งหมด',
    all_loss: 'แพ้ทั้งหมด',
    room_id: 'ไอดีห้อง',
    transaction_no: 'เลขธุรกรรม',
    game_result: 'ผลที่ออก',
    result: {
      head: 'หัว',
      tail: 'ก้อย',
      rock: 'ค้อน',
      paper: 'กระดาษ',
      scissors: 'กรรไกร',
      hi: 'สูง',
      lo: 'ต่ำ',
    },
    list: {
      HI_LO: 'สูงต่ำ',
      ROCK_PAPER_SCISSORS: 'เป่ายิงฉุบ',
      HEAD_TAIL: 'หัวก้อย',
      CARD_BATTLE: 'ตีไก่',
      general: 'เกมทั่วไป',
      slot: 'เกมสล๊อต',
      fishing: 'เกมยิงปลา',
      casino: 'คาสิโน',
      sports: 'กีฬา',
      simulated_sports: 'กีฬาจำลอง',
    },
  },
  bots: {
    name: 'บอท',
    bot_credit: 'เครดิตบอท (฿)',
    max_loss_credit: 'เสียเครดิตได้มากสุด (฿)',
    max_win_credit: 'ได้เครดิตได้มากสุด (฿)',
    operate_time: 'เวลาบอททำงาน',
    join_time: 'เข้าห้องเล่นเกม',
    join_min: 'ช้าสุด',
    join_max: 'เร็วสุด',
    win_rate: 'อัตราการชนะ (%)',
    max_room: 'สร้างห้องได้มากสุด (จำนวนห้อง)',
  },
  affiliate: {
    title: 'ระบบแนะนำ',
    report: 'รายงานระบบแนะนำ (AF)',
    confirm_af: 'กดยืนยันตัวตนเพื่อรับ AF 10%',
    withdraw: 'ถอนรายได้',
    revenue: 'รายได้',
    af_link: 'ลิ้งแนะนำ',
    search_members: 'ค้นหาสมาชิก',
    min_deposit: 'ยอดฝากสะสมขั้นต่ำ',
    profit_percentage: 'ยอดเงินคืน',
    level: 'เลเวล {val}',
    percentage: 'เปอร์เซ็น AF',
    min_bet: 'ยอดแทงต่ำ',
    total_af_credit: 'ยอดเครดิต AF',
    total_bet_credit: 'ยอดแทงรวม',
    your_bet_credit: 'ยอดแทงสมาชิก',
    member_bet_credit: 'ยอดแทงลูกข่าย',
    tabs: {
      overview: 'ภาพรวม',
      members: 'สมาชิกทั้งหมด',
      credits: 'จำนวนเครดิต',
      total_credit: 'เครดิตทั้งหมด',
    },
    stats: {
      all_click: 'จำนวนคลิกทั้งหมด',
      total_bet: 'จำนวนการแทงทั้งหมด',
      members_link: 'สมาชิกที่สมัครผ่านลิ้ง',
      current_revenue: 'ยอดปัจจุบัน',
    },
  },
  contact: {
    title: 'ช่องทางการติดต่อ',
    line_id: 'Line ID',
    line_url: 'Line URL',
    line_link: 'Line Link',
    qr_code: 'Line QrCode Image url',
    telegram_id: 'Telegram ID',
    telegram_url: 'Telegram URL',
    telegram_link: 'Telegram Link',
    telegram_qrcode: 'Telegram QrCode Image url',
    message: 'ข้อความเกี่ยวกับช่องทางการติดต่อ',
    announcement: 'ประกาศ',
    verify_mode: 'เลือกโหมดการยืนยันตัวตน (สมัครสมาชิก) - OTP',
  },
  roles: {
    title: 'Role',
    name: 'ชื่อ Role',
    permissions: 'จำกัดการเข้าถึง',
  },
  brands: {
    title: 'แบรนด์',
    name: 'ชื่อแบรนด์',
    logo: 'โลโก้แบรนด์',
    image: 'รูป',
  },
  rewards: {
    random_rewards: 'สุ่มรางวัล',
    games: 'เกมสุ่มรางวัล',
    name: 'ชื่อรางวัล',
    history: 'ประวัติการรับรางวัล',
    coupon: 'แลกคูปอง',
    coupon_no: 'จำนวนคูปอง',
    coupon_settings: 'ตั้งค่าแลกคูปอง',
    coupon_history: 'ประวัติคูปอง',
    coupon_redeem_history: 'ประวัติการแลกคูปอง',
    point: 'คะแนนรางวัล',
    range_date: 'วันที่เริ่ม - สิ้นสุด',
    start_date: 'วันที่เริ่ม',
    end_date: 'วันที่สิ้นสุด',
    use_coupon: 'จำนวนคูปองที่ต้องใช้',
    use_point: 'จำนวนคะแนนที่ต้องแลก',
    type: 'ประเภทรางวัล',
    random_percentage: 'เปอร์เซ็นการสุ่ม',
    reward_amount: 'ยอดเงินรางวัล',
    collect_value: 'จำนวนการเล่น',
    point_value: 'จำนวนคะแนนที่ได้',
    win: 'เล่นเกมชนะ',
    loss: 'เล่นเกมแพ้',
    refill: 'โปรโมชั่นเติมเงิน',
  },
  cashback: {
    settings: 'ตั้งค่าระบบเงินคืน',
    history: 'ประวัติการรับเงินคืน',
    cashback_type: 'ประเภทยอดเงินคืน',
    cashback_value: 'ยอดเงินคืน',
    interval_unit: 'ระยะเวลา',
    start_date: 'วันที่จะเริ่มนับ',
    next_date: 'วันถัดไปที่จะนับ',
    turnover_point: 'ตั้งค่าทำเทิร์น',
    min_return: 'ถอนขั้นต่ำ/ครั้ง',
    max_return: 'ถอนขั้นสูงสุด/ครั้ง',
    max_cashback_per_round: 'ยอดเงินคืนสูงสุด/รอบ',
    min_cashback_per_round: 'ยอดเงินคืนต่ำสุด/รอบ',
    per_people_per_day: 'จำนวนเงินคืน คน/วัน',
    agent_credit: 'เครดิตเอเยนต์',
    game_type: 'ชนิดเกม',
    is_bet_balance: 'คิดจากยอดเดิมพัน',
    is_loss_balance: 'คิดจากยอดเสียเดิมพัน',
    is_include_promotion: 'คิดรวมโปรโมชั่นอื่น',
  },
  promotion: {
    promotion: 'โปรโมชั่น',
    max_bonus: 'โบนัสสูงสุด',
    max_turnover: 'จำนวนเทิร์นสูงสุด',
    order_position: 'ลำดับการแสดง',
    bonusType: 'ประเภทโบนัส',
    name: 'ชื่อโปรโมชั่น',
    condition: 'ประเภทเงื่อนไข',
    min_deposit: 'ยอดฝากขั้นต่ำ',
    free_credit: 'ฟรีเครดิต',
    gain_credit: 'เครดิตที่ได้รับ',
    max_gain_credit: 'เครดิตที่ได้รับสูงสุด',
    history: 'ประวัติการรับโปรโมชั่น',
    types: {
      ALL_REFILL: 'ทุกยอดฝาก',
      FIRST_TIME_REFILL: 'เติมครั้งแรก',
      DAILY_REFILL: 'เติมตังภายในวันนั้น',
      FREE_CREDIT: 'ฟรีเครดิต',
      TIME_REFILL: 'นาทีทอง',
    },
  },
  promotion_code: {
    name: 'ชื่อโปรโมชั่น',
    code: 'โค้ด',
    usage: 'การใช้งาน',
    count: 'ใช้ไปแล้ว',
    one_time: 'ครั้งเดียวต่อยูส',
    many_time: 'หลายครั้งต่อยูส',
    max_receive: 'จำกัดจำนวน',
    once_per_user: 'ใช้ได้ครั้งเดียวต่อยูส'
  }
}
